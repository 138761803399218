import { useForm } from 'react-hook-form';
import { Fragment, useEffect, useMemo } from 'react';
import { useNavigate, useParams, useLocation } from 'react-router-dom';

import {
	Icon,
	Text,
	Button,
	FormField,
	BookingPageWrapper,
} from '@/components/common';
import { useBookingStore } from '@/store';
import { CarCard } from '@components/carhire';
import {
	useBackNavigate,
	useBreakpoint,
	useFetch,
	useSearchSave,
	useUrlParams,
	useMetaDescription,
} from '@/hooks';
import { getNextStep, currencyFormatter } from '@/lib/utils';
import qs from 'qs';
import VehicleExtrasTemplate from '@/components/templates/vehicle-extras';

/**
 * @typedef {Object} CarHireExtrasProps
 * @property {import("@/routes/routes").step[]} steps
 * */

/**
 * @name CarHireExtras
 * @description A page component for car hire extras
 * @param {CarHireExtrasProps} props
 * @returns {React.JSX.Element}
 * @example
 * <CarHireExtras steps={steps} /> - Steps can be an array or an object with the category as the key
 * <CarHireExtras steps={{ carhire: steps }} />
 * */

function CarHireExtras({ steps }) {
	const MAX_PER_ITEM = 999; // number of times an item can be added (if there's no other restriction)

	useMetaDescription(['Extras', 'Car Hire', 'Canadian Affair']);

	let navigate = useNavigate();
	const urlParams = useParams();
	const { pathname, state: urlState } = useLocation();
	const { setBookingState } = useBookingStore();
	const { params } = useUrlParams('carextra-filters');
	const state = useBookingStore((state) => state['car-hire']);

	const { getSearch } = useSearchSave();

	const isSmallScreen = useBreakpoint('sm');

	const category = urlParams?.category
		? urlParams?.category
		: pathname && pathname.split('/')[2];

	const pageSteps = useMemo(() => {
		if (!steps) return null;
		const stepsIsArray = Array.isArray(steps);
		return !stepsIsArray ? steps[category] : steps;
	}, [steps]);

	const nextStep = getNextStep(pageSteps, pathname);
	const { handleBack } = useBackNavigate(pageSteps);

	const existingSearch = getSearch('carhire');
	// update pageSteps 1st step to include the existing search if it exists
	if (existingSearch) {
		pageSteps[0].search = `${existingSearch}`;
	}

	// create a new url params object
	const extrasSearchParams = useMemo(() => {
		if (!params.default) return null;

		// check if params and state are similar
		const isSameParams =
			JSON.stringify(params.default) === JSON.stringify(state?.selected);

		const selectedParams = isSameParams ? state?.selected : params.default;

		return {
			...selectedParams,
			type:
				params?.default?.carItem &&
					params?.default.carItem.toUpperCase().includes('MOTO')
					? 'motorhome'
					: 'car',
		};
	}, [state?.selected, params?.default]);

	const { data, isLoading, error } = useFetch({
		key: 'carhire-extra',
		params: extrasSearchParams,
		config: {
			enabled: !!extrasSearchParams,
			retryOnWindowFocus: false,
		},
		onData: (data) => {
			if (!data || error) return;

			// handle if no data packages or items are returned
			const hasItems =
				Array.isArray(data?.data?.items) && data?.data?.items.length > 0;
			const hasPackages =
				Array.isArray(data?.data?.packages) && data?.data?.packages.length > 0;
			if (!hasPackages && !hasItems) {
				// Get history and navigate to the next page or search page
				const nextStep = getNextStep(pageSteps, pathname);

				// get current page index from the steps
				const currentPageIndex = pageSteps.findIndex(
					(step) => step.to === pathname
				);

				// update the current page search with the url params
				pageSteps[currentPageIndex].search = qs.stringify(params.default);

				// if coming from the next page go back to the previous page else go to the next page
				if (urlState?.from && urlState?.from.includes(nextStep?.to)) {
					window.history.pushState(null, '', urlState?.from);
					handleBack();
				} else {
					const url = nextStep?.search
						? `${nextStep?.to}?${nextStep?.search}`
						: nextStep?.to;
					navigate(url, { state: { from: pathname } });
				}
			}

			// update the state with details required for the travel plan
			if (data?.data && data?.extra) {
				const isSameParams = Object.keys(params.default).reduce((acc, key) => {
					// if the key is not the same return false
					if (!state?.selected) return false;
					if (!state?.selected?.[key]) return false;

					if (params.default[key] !== state?.selected[key]) return false;
					return acc;
				}, true);

				const newState = {
					...state,
					shouldBuildParams: true,
					carExtras: {
						...state?.carExtras,
						...data?.extra,
					},
				};

				if (!isSameParams) {
					newState.selected = params.default;
					setBookingState('car-hire', newState, 'UPDATE_SELECTED');
				} else {
					setBookingState('car-hire', newState, 'UPDATE_CARHIRE_EXTRA');
				}
			}
		},
	});

	const packages = data?.data?.packages;
	const items = data?.data?.items;
	const car = data?.extra?.car;
	const pickup = data?.extra?.pickup;
	const dropoff = data?.extra?.dropoff;
	const carPax = car?.pax ? parseInt(car.pax) : null;

	const getItemLimit = (item) => {
		// use limit from api if provided
		const apiLimit = item?.limit ? parseInt(item.limit) : null;
		if (apiLimit) return apiLimit <= MAX_PER_ITEM ? apiLimit : MAX_PER_ITEM;

		// calculate limit from number of passengers
		let itemLimit = carPax && carPax <= MAX_PER_ITEM ? carPax : MAX_PER_ITEM;

		// exclude driver from seat extras
		const isSeatExtra = item.code.toUpperCase().includes('SEAT');
		if (isSeatExtra) itemLimit -= 1;

		return itemLimit;
	};

	const packageOptions = useMemo(() => {
		if (!packages?.length) return [];

		const options = [
			{
				// default package
				id: 'default-fully-inclusive',
				label: car?.type,
				value: '',
				description: 'Fully Inclusive',
				price: 'INCLUDED',
				features: ['1x Driver', 'No GPS'],
			},
		];

		packages.forEach((p) => {
			if (!p?.code) return;

			// ignore packages not lasting full rental duration
			if (p?.duration !== car?.duration) return;

			const price = p?.price ? parseFloat(p.price) : 0;
			options.push({
				label: car?.type,
				value: p?.code,
				description: p?.displayName ? p.displayName : p?.name,
				price:
					price > 0 ? `+${currencyFormatter({ amount: price })}` : 'INCLUDED',
				features: p?.features?.length ? p.features : [],
			});
		});

		return options.length > 1 ? options : [];
	}, [packages]);

	const availableItems = useMemo(() => {
		if (!items?.length) return [];

		return items.filter((item) => {
			if (!item?.code) return false;

			// check item can be added
			return getItemLimit(item) > 0;
		});
	}, [items]);

	const defaultValues = useMemo(() => {
		const selectedPackageExtra = state?.carExtras?.selectedPackageExtra;
		const selectedItemExtras = state?.carExtras?.selectedItemExtras;
		if (!selectedPackageExtra && !selectedItemExtras && !packageOptions?.length)
			return null;

		if (
			!selectedPackageExtra &&
			!selectedItemExtras &&
			packageOptions?.length
		) {
			return {
				package: packageOptions[0]?.value || '',
				itemExtras: {},
			};
		}

		// default to No / 0 selected items
		const defaultItemValues = availableItems?.length
			? availableItems.reduce((acc, item) => {
				if (!item?.code) return acc;

				// console.log('def', item);
				const itemLimit = getItemLimit(item);
				acc[item.code] =
					itemLimit === 1
						? 'No' // radio button fields
						: { value: 0, label: '0' }; // select fields

				return acc;
			}, {})
			: {};

		// format quantity selected
		const chosenItems = selectedItemExtras.reduce((acc, data) => {
			if (!data) return acc;
			const { item, quantity } = data;

			const itemLimit = getItemLimit(item);
			if (itemLimit === 1) {
				// radio button fields
				acc[item.code] = quantity === 1 ? 'Yes' : 'No';
			} else {
				// select fields
				acc[item.code] = { value: quantity, label: quantity.toString() };
			}

			return acc;
		}, {});

		return {
			package: selectedPackageExtra?.code || '',
			itemExtras: {
				...defaultItemValues,
				...chosenItems,
			},
		};
	}, [
		state?.carExtras?.selectedPackageExtra,
		state?.carExtras?.selectedItemExtras,
		packageOptions,
		availableItems,
	]);

	const {
		watch,
		reset,
		control,
		handleSubmit,
		formState: { errors },
	} = useForm({
		defaultValues,
	});

	// reset form when defaultValues change
	useEffect(() => {
		reset(defaultValues);
	}, [defaultValues]);

	useEffect(() => {
		const sub = watch((data, { type }) => {
			if (type !== 'change') return;

			// update selected package extra
			const chosenPackage =
				packages?.length && data?.package
					? packages.find((p) => p?.code === data?.package)
					: null;

			// update selected package extras
			const chosenItems = [];
			if (items?.length && typeof data?.itemExtras === 'object') {
				Object.keys(data.itemExtras).forEach((itemCode) => {
					const itemData = data.itemExtras[itemCode];

					let quantity = 0;
					switch (typeof itemData) {
						case 'string':
							// check for 'Yes' or 'No'
							quantity = itemData.toLowerCase() === 'yes' ? 1 : 0;
							break;

						case 'object':
							// check for quantity
							quantity = itemData?.value ? parseInt(itemData.value) : 0;
							break;
					}

					if (quantity < 1) return;

					const chosenItem = items.find((i) => i?.code === itemCode);
					if (!chosenItem) return;

					const price = chosenItem?.price ? parseFloat(chosenItem.price) : 0;
					chosenItems.push({
						quantity: quantity,
						cost: price * quantity,
						item: chosenItem,
					});
				});
			}

			// update BookingState instead of local state
			const newState = {
				shouldBuildParams: true,
				carExtras: {
					...state?.carExtras,
					selectedItemExtras: chosenItems,
					selectedPackageExtra: chosenPackage,
				},
			};

			setBookingState('car-hire', newState, 'SET_CARHIRE_PACKAGE_EXTRA');
		});

		return () => sub.unsubscribe();
	}, [watch, packages, items, state?.carExtras]);

	const formatQuantityOptions = (itemLimit) => [
		{ value: 0, label: '0' },
		...[...Array(itemLimit >= 1 ? itemLimit : 0)].map((_, i) => ({
			value: i + 1,
			label: `${i + 1}`,
		})),
	];

	let customError = null;
	setTimeout(() => {
		if (!(car?.code && pickup?.code && dropoff?.code))
			customError = {
				message: 'No car results found.',
			};
	}, 1000);

	const onSubmit = async () => {
		// navigate to next page
		navigate(`${nextStep?.to}`);
	};

	return (
		<VehicleExtrasTemplate
			category={category}
			isLoading={isLoading}
			title="Car Hire Extras"
			error={error || customError}
			onSubmit={handleSubmit(onSubmit)}
			continueIsDisabled={isLoading}
			vehicle={car || {}}
		>
			{/* Package enhancement */}
			<Fragment>
				{packageOptions?.length > 0 && (
					<div>
						<Text variant="bold" className="text-2xl">
							Package enhancement
						</Text>
						<Text variant="muted" className="my-1">
							Our fully inclusive package has got you covered but if you
							want to add more options choose from the below.
						</Text>

						{/* Package enhancement list */}
						<div className="p-8 mt-5 bg-white border border-lighter-grey">
							<FormField
								as="radio-group"
								control={control}
								name="package"
								options={packageOptions}
								//defaultValue="" // default to 'Fully Inclusive', which doesn't have an item code
								fieldWrapperClassName="flex items-start gap-4 md:flex-col md:items-center"
								disabled={isLoading || state?.previewLoading || state?.isLoading}
								renderOptionChildren={(option) => {
									const isFree =
										option?.price.toUpperCase().includes('FREE') ||
										option?.price.toUpperCase().includes('INCLUDED');

									const carTypes = [
										'AVI/FULLSUV',
										'AVI/REGSUV2',
										'AVI/MINI2',
									];

									const showDefaultText =
										carTypes.includes(car?.code) && isFree;

									return (
										<div className="flex flex-col items-start gap-2 md:items-center">
											<Text
												variant="bold"
												className="text-left md:text-center"
											>
												{!showDefaultText
													? option.description
													: 'Fully Inclusive - Incl. 200km/day'}
											</Text>
											{option.price && (
												<Text variant="black" className="text-3xl">
													{option.price}
												</Text>
											)}
											{option?.features?.length ? (
												<div>
													{option.features.map((feature) => (
														<Text
															variant="bold"
															key={`${option.value}-feature-${feature}`}
														>
															{feature}
														</Text>
													))}
												</div>
											) : null}
										</div>
									);
								}}
							/>
						</div>
					</div>
				)}
			</Fragment>
			<Fragment>
				{availableItems?.length > 0 && (
					<div>
						<Text variant="bold" className="text-2xl">
							Paid for on pick-up
						</Text>
						<Text variant="muted" className="my-1">
							Add a request for an infant, toddler or child seat, or a GPS.
							You will pay for these extras directly at the car rental
							supplier service desk upon pick-up.{' '}
							<strong>
								Please note that availability of these extras is not always
								guaranteed.
							</strong>
						</Text>

						{/* Package enhancement list */}
						{availableItems.map((item) => {
							const itemLimit = getItemLimit(item);
							const maxCost = item?.maxCost
								? parseFloat(item.maxCost)
								: 0;
							const pricePerDay = item?.pricePerDay
								? parseFloat(item.pricePerDay)
								: 0;

							return (
								<div
									key={item.code}
									className="@container relative rounded bg-white border border-lighter-grey p-5.2 mt-5 gap-2"
								>
									<div className="flex flex-col items-start justify-between gap-4 @xl:flex-row @xl:items-center">
										<div className="md:max-w-1/2">
											<Text variant="bold" className="text-xl">
												{item?.displayName ? item.displayName : item?.name}
											</Text>
											{item?.description && (
												<Text variant="muted">{item.description}</Text>
											)}
										</div>

										{itemLimit === 1 ? (
											<FormField
												as="toggle-group"
												control={control}
												name={`itemExtras[${item.code}]`}
												value="No"
												wrapperClassName="max-w-fit"
												defaultValue="No"
												disabled={isLoading || state?.previewLoading || state?.isLoading}
												options={[
													{ label: 'No thanks', value: 'No' },
													{ label: 'Yes please', value: 'Yes' },
												]}
											/>
										) : (
											<div className="flex flex-col items-start gap-5 md:items-center md:flex-row">
												{(pricePerDay > 0 || maxCost > 0) && (
													<div className="grow shrink">
														{pricePerDay > 0 && (
															<Text variant="bold" className="text-2xl">
																+
																{currencyFormatter({
																	amount: pricePerDay,
																	currency: 'CAD',
																})}{' '}
																per day
															</Text>
														)}
														{maxCost > 0 && (
															<Text variant="muted">
																maximum cost of{' '}
																{currencyFormatter({
																	amount: maxCost,
																	currency: 'CAD',
																})}
															</Text>
														)}
														<Text variant="muted">payable locally</Text>
													</div>
												)}
												<FormField
													name={`itemExtras[${item.code}]`}
													as="select"
													placeholder="0"
													options={formatQuantityOptions(itemLimit)}
													control={control}
													errors={errors}
													wrapperClassName="w-50"
													disabled={isLoading || state?.previewLoading || state?.isLoading}
													value={{ value: 0, label: '0' }}
													portalled
													menuPlacement={isSmallScreen ? 'auto' : 'bottom'}
												/>
											</div>
										)}
									</div>
								</div>
							);
						})}
					</div>
				)}
			</Fragment>
		</VehicleExtrasTemplate>
	);
}

export default CarHireExtras;
