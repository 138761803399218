import PropTypes from 'prop-types';
import { Controller } from 'react-hook-form';
import { ErrorMessage } from '@hookform/error-message';

import {
	Input,
	Text,
	Select,
	DateRangePicker,
	RadioGroup,
	ToggleButtonGroup,
	Checkbox,
	CheckboxGroup,
} from '@/components/common';
import { cn, composeValidators } from '@/lib/utils';

/**
 * @typedef {"input" |"select" |"date-range"| "radio-group" | "toggle-group" | "address" | "checkbox" | "checkbox-group"} AS
 */

/**
 * @typedef {Object} FormFieldProps
 * @property {string} name
 * @property {AS} as
 * @property {string} wrapperClassName
 * @property {string} fieldWrapperClassName
 * @property {string | React.ReactNode} label
 * @property {string} labelClassName
 * @property {string} placeholder
 * @property {string | number | boolean | object} value
 * @property {string | React.ReactNode} footer
 * @property {(param)=> void} onChange
 * @property {(param)=> void} setSelected
 * @property {Array<(param)=> void>} validation
 * @property {object} control
 * @property {object} errors
 * @property {boolean} hideErrorMessage
 * @property {boolean} showRequired
 * @property {boolean} hideLabel
 * @property {"default" | "withController"} variant
 **/

/**
 * @name FormField
 * @description A component that renders a form field
 * @param {FormFieldProps} props
 * @returns {React.JSX.Element}
 * @example
 * <FormField
 *   name="name"
 *   as="input"
 *   label="Name"
 *   placeholder="Enter your name"
 *   control={control}
 *   errors={errors}
 * />
 * */

function FormField({
	name,
	label,
	value,
	footer,
	errors,
	control,
	onChange,
	hideLabel,
	validation,
	placeholder,
	setSelected,
	as = 'input',
	showRequired,
	labelClassName,
	wrapperClassName,
	fieldClassName,
	hideErrorMessage,
	variant = 'withController',
	showCheckboxAsButton = false,
	showRadioAsButton = false,
	renderCheckboxButtonText,
	...props
}) {
	let Component;

	switch (as) {
		case 'select':
		case 'Select':
			Component = Select;
			break;
		case 'date-range':
			Component = DateRangePicker;
			break;
		case 'radio-group':
			Component = RadioGroup;
			break;
		case 'toggle-group':
			Component = ToggleButtonGroup;
			break;
		case 'checkbox':
			Component = Checkbox;
			break;
		case 'checkbox-group':
			Component = CheckboxGroup;
			break;
		default:
			Component = Input;
			break;
	}

	const hasValidationError = () => {
		if (errors) {
			if (name === 'when' && as === 'date-range') {
				if (
					typeof errors[name]?.['from']?.message === 'string' ||
					typeof errors[name]?.['to']?.message === 'string'
				) {
					return true;
				}
			} else if (typeof errors[name]?.message === 'string') {
				return true;
			}
		}
		return false;
	};

	const ControllerComponent = (field) => (
		<Component
			selected={
				typeof setSelected === 'function' ? setSelected(field) : field.value
			}
			placeholder={placeholder}
			isValid={!hasValidationError()}
			instance="formField"
			id={name}
			{...field}
			{...props}
			onChange={(value) => {
				if (typeof onChange === 'function') {
					onChange(field, value);
				} else {
					field.onChange(value);
				}
			}}
			{...(as === 'checkbox' && { showCheckboxAsButton: showCheckboxAsButton })}
			{...(as === 'radio-group' && { showRadioAsButton: showRadioAsButton })}
		/>
	);

	return (
		<div className={cn('flex flex-col gap-2 w-full', wrapperClassName)}>
			{label &&
				(typeof label !== 'string' ? (
					label
				) : (
					<div className="flex items-center justify-between">
						<Text
							as="label"
							htmlFor={name}
							variant={hasValidationError() ? 'error' : 'muted'}
							className={cn(
								'text-base leading-tight text-light-black',
								{ 'sr-only': hideLabel },
								labelClassName
							)}
						>
							{label}
						</Text>
						{showRequired && (
							<Text
								as="span"
								variant={hasValidationError() ? 'error' : 'muted'}
							>
								required
							</Text>
						)}
					</div>
				))}

			<div className={fieldClassName && fieldClassName}>
				{variant === 'default' ? (
					<Component
						selected={
							typeof setSelected === 'function' ? setSelected(name) : value
						}
						placeholder={placeholder}
						isValid={!hasValidationError()}
						id={name}
						{...props}
						value={value ?? (as === 'select' ? null : '')}
						onChange={(e) => {
							if (typeof onChange === 'function') {
								onChange(e);
							}
						}}
					/>
				) : (
					<Controller
						name={name}
						control={control}
						defaultValue={value ?? (as === 'select' ? null : '')}
						rules={
							validation?.length
								? { validate: composeValidators(...validation) }
								: {}
						}
						render={({ field }) => {
							return showCheckboxAsButton ? (
								<div
									className={cn(
										'flex flex-row gap-2 items-center justify-center px-5 py-3.5 transition-all duration-200 ease-in-out button-fill-standard group border-2',
										field.value
											? 'core-blue'
											: 'border-core-blue text-core-blue',
										props?.isDisabled &&
										'pointer-events-none opacity-40 border-border-color cursor-not-allowed transform-none'
									)}
								>
									<div className="flex input-checkbox">
										{ControllerComponent(field)}
									</div>
									{renderCheckboxButtonText(field.value)}
								</div>
							) : (
								ControllerComponent(field)
							);
						}}
					/>
				)}

				{footer && (
					<div className="mt-1 text-sm font-normal leading-tight text-opacity-75 text-light-black text-body">
						{footer}
					</div>
				)}

				{!hideErrorMessage &&
					(name === 'when' && as === 'date-range' ? (
						<div className="flex flex-col w-full">
							<ErrorMessage
								errors={errors}
								name={name + '.from'}
								render={({ message }) => (
									<Text as="span" variant="error" className="mt-2">
										&uarr; {message}
									</Text>
								)}
							/>
							<ErrorMessage
								errors={errors}
								name={name + '.to'}
								render={({ message }) => (
									<Text as="span" variant="error" className="mt-2">
										&uarr; {message}
									</Text>
								)}
							/>
						</div>
					) : (
						<ErrorMessage
							errors={errors}
							name={name}
							render={({ message }) => (
								<Text as="span" variant="error" className="mt-2">
									&uarr; {message}
								</Text>
							)}
						/>
					))}
			</div>
		</div>
	);
}

FormField.propTypes = {
	name: PropTypes.string.isRequired,
	as: PropTypes.oneOf([
		'input',
		'select',
		'date-range',
		'radio-group',
		'toggle-group',
		'address',
		'checkbox',
		'checkbox-group',
	]),
	wrapperClassName: PropTypes.string,
	fieldWrapperClassName: PropTypes.string,
	label: PropTypes.oneOfType([PropTypes.string, PropTypes.node]),
	labelClassName: PropTypes.string,
	placeholder: PropTypes.string,
	value: PropTypes.oneOfType([PropTypes.string, PropTypes.object]),
	footer: PropTypes.oneOfType([PropTypes.string, PropTypes.node]),
	onChange: PropTypes.func,
	setSelected: PropTypes.func,
	validation: PropTypes.array,
	control: PropTypes.object.isRequired,
	errors: PropTypes.object.isRequired,
	hideErrorMessage: PropTypes.bool,
	showRequired: PropTypes.bool,
	hideLabel: PropTypes.bool,
	showCheckboxAsButton: PropTypes.bool,
	showRadioAsButton: PropTypes.bool,
};

FormField.defaultProps = {
	labelClassName: '',
	errors: {},
	hideErrorMessage: false,
	showRequired: false,
	hideLabel: false,
};

export default FormField;
