import { withPortal } from '@/HOCs/withPortal';
import BookingWidget from '@/components/common/organisms/booking-widget';

const containerId = 'booking-motorhome-booking-widget';

function MotorHomeBookingWidget() {
	return (
		<BookingWidget
			options={[
				{
					label: 'Motorhome',
					value: 'motorhome-hire',
					icon: 'motorhome',
				},
			]}
			containerId={containerId}
			hideTrigger
		/>
	);
}
export default withPortal(MotorHomeBookingWidget, containerId);
