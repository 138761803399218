import React from 'react';

import { useUrlParams } from '@/hooks';
import { withPortal } from '@/HOCs/withPortal';
import { MotorHomeList } from '@/components/motorhomes';
import { SearchWidget, SearchResultsPage } from '@/components/common';
import {
	cn,
	buildFacetFilters,
	MOTORHOME_RESULTS_SORT_OPTIONS,
	PAGE_OPTIONS,
} from '@/lib/utils';
import useVerticalOverflow from '@/hooks/useVerticalOverflow';

/**
 * @name MotorHomeSearch Page
 * @description This page is used to display the motorhome search results - it is also a portalized page with an id of 'booking-motorhome-search-page'
 * @returns {React.JSX.Element}
 **/
function MotorHomeSearch({ portalData }) {
	const { params } = useUrlParams('motorhomehire_filters');
	const { observe, isOverflowing } = useVerticalOverflow(true);

	return (
		<>
			{!portalData?.location && (
				<SearchWidget
					ref={observe}
					category="motorhome-hire"
					className={cn(
						portalData?.withSiteHeader
							? 'fixed z-50 w-full top-15 md:top-25 drop-shadow-3xl max-h-[calc(100vh-3.75rem)] md:max-h-[calc(100vh-6.25rem)]'
							: 'max-h-screen',
						isOverflowing ? 'overflow-y-auto' : ''
					)}
				/>
			)}
			<SearchResultsPage
				paramsKey="motorhomehire_filters"
				queryKey="motorhome-search"
				sortOptions={MOTORHOME_RESULTS_SORT_OPTIONS}
				pageOptions={PAGE_OPTIONS}
				pageTitle={!portalData?.location ? 'Motorhome Search Results' : null}
				filterLabel="Filter Motorhomes By"
				buildSearchParams={(params) => {
					if (!params?.default) return null;

					return {
						...params.default,
						dropoffLocation: params.default?.dropoff
							? params.default?.dropoff.split(':')[0]
							: null,
						pickupLocation: params.default?.pickup
							? params.default?.pickup.split(':')[0]
							: null,
					};
				}}
				buildFilters={(distribution, icons, stats) => {
					const customFilters = {
						price: {},
					};
					// represent price as a budget range filter
					if (
						typeof stats?.price?.min !== 'undefined' &&
						typeof stats?.price?.max !== 'undefined' &&
						stats?.price?.max > 0
					)
						customFilters.price = {
							type: 'budget',
							min: 0,
							max: parseFloat(stats.price.max),
							name: 'budget',
							title: 'Budget',
							defaultToZero: true, // default to zero when resetting (as we don't know the future max price value)
						};
					return buildFacetFilters({
						distribution: distribution,
						icons: icons,
						customFilters: customFilters,
					});
				}}
				ListResultsComponent={MotorHomeList}
				className={portalData?.withSiteHeader && '!pt-18'}
				resultsContainerClassName="lg:flex lg:items-start lg:relative"
				filterClassName={cn(
					'lg:sticky lg:w-1/4 lg:top-25',
					portalData?.withSiteHeader && 'lg:top-50.5'
				)}
			/>
		</>
	);
}

// create a portalized version of this component
export default withPortal(MotorHomeSearch, 'booking-motorhome-search-page');
