import React from 'react';
import PropTypes from 'prop-types';
import { cn, currencyFormatter } from '@/lib/utils';
import {
	Button,
	Icon,
	Text,
	Stack,
	Heading,
	Dialog,
	LoopWrapper,
} from '@/components/common';
import pluralize from 'pluralize';

function RentalInsurance({ className, info }) {
	return (
		<Dialog
			as="modal"
			size="lg"
			hideCloseBtn
			position="center"
			contentClassName="p-0"
			renderTrigger={({ DialogTrigger, onOpen }) => (
				<DialogTrigger
					onClick={onOpen}
					className={cn(
						'underline underline-offset-2 text-left font-normal leading-tight text-neutral-800',
						className
					)}
				>
					<Text className="leading-snug tracking-tighter" as="span">
						Rental insurance information
					</Text>
				</DialogTrigger>
			)}
		>
			{({ CloseButton }) => (
				<div className="min-h-48 max-h-[85vh] overflow-y-scroll py-12">
					<CloseButton
						className="absolute top-0 right-0 w-12 h-12 text-white bg-core-blue"
						variant="square"
					/>
					<Heading as="h2" className="px-12 py-8 font-bold leading-tight">
						Rental insurance information
					</Heading>
					<div className="h-auto px-12 ">
						{info ? (
							<Text className="text-lg text-left">{info}</Text>
						) : (
							<div className="space-y-3">
								<Heading as="h3" className="font-bold leading-tight">
									Terms & Conditions
								</Heading>
								<Text className="text-lg">
									Loss Damage Waiver (LDW) or Collision Damage Waiver (CDW)
									Limited Damage Waiver (LDW) or Collision Damage Waiver (CDW)
									limits the driver&apos;s liability in the event that the
									rental car is damaged, subject to the terms of the rental
									agreement. Rather than the full cost, the driver is generally
									only responsible for the first portion, known as their
									‘excess’. This protection applies to all authorised drivers of
									the rental car.
								</Text>
								<Text className="text-lg">
									Third-Party Liability (TPL) Third-Party Liability (TPL) covers
									the driver&apos;s liability for damage to any property,
									excluding the rental car itself. TPL also covers injuries
									suffered by other people, whether or not they are in the
									rental car, subject to the terms of the rental agreement.
								</Text>
								<Text className="text-lg">
									Theft Protection (TP) Theft Protection (TP) limits the
									driver&apos;s liability for costs incurred by the theft of the
									rental car – or damage incurred by the theft or attempted
									theft – to an ‘excess’ amount, subject to the terms of the
									rental agreement. TP does not cover personal effects, which
									may be covered by the driver&apos;s own travel or household
									policies.
								</Text>
								<Text
									as="a"
									href="/travel-centre/terms-and-conditions/car-hire"
									target="_blank"
									rel="noopener noreferrer"
									className="block mt-8 text-lg font-bold underline hover:opacity-80"
								>
									For more information please read our car hire T&C's
								</Text>
							</div>
						)}
					</div>
				</div>
			)}
		</Dialog>
	);
}

function CarCard({
	car,
	hideProviderLogo,
	selected,
	className,
	onSelect,
	type,
	isMotorhome = false,
}) {
	const carSummary = isMotorhome
		? null
		: [car?.type, car?.subType, car?.aircon ? 'Air Conditioning' : null]
				.filter((info) => info)
				.join(', ');

	return (
		<div
			className={cn(
				'@container flex h-auto w-full justify-start gap-2 bg-white p-5 md:p-6 lg:p-8 xl:p-12',
				className
			)}
		>
			<div
				className={cn(
					'w-full flex flex-col items-start justify-start md:flex-col gap-1',
					type === 'extras' && 'flex-col'
				)}
			>
				<Heading
					as="h2"
					className="text-lg font-bold leading-snug tracking-tighter text-black lg:text-2xl font-body"
				>
					{car?.displayName || car?.name}
				</Heading>
				{type === 'extras' && (
					<>
						{isMotorhome ? (
							<>
								<Text className="leading-snug tracking-tighter">
									{!car?.berths ? null : `${car.berths} People`}
								</Text>
								<Text className="leading-snug tracking-tighter">
									{!car?.bedSummary ? null : `Bed Summary: ${car?.bedSummary}`}
								</Text>
							</>
						) : (
							<Text className="leading-snug tracking-tighter">
								{carSummary}
							</Text>
						)}
					</>
				)}
				<div
					className={cn(
						'flex flex-col gap-y-2.5 xl:gap-y-0 mt-1.5 xl:mt-0',
						type === 'extras' &&
							'flex-row-reverse items-center gap-5 w-auto lg:flex-col lg:w-full'
					)}
				>
					<div
						className={cn(
							'flex gap-1 flex-col self-start',
							type === 'extras' && 'flex-row'
						)}
					>
						{type !== 'extras' && (
							<div className="flex items-center justify-start gap-1">
								{isMotorhome ? (
									<>
										<Text className="leading-snug tracking-tighter">
											{!car?.berths ? null : `${car.berths} People`}
										</Text>
										<Text className="leading-snug tracking-tighter">
											{!car?.bedSummary ? null : `Bed Summary: ${car?.bedSummary}`}
										</Text>
									</>
								) : (
									<Text className="leading-snug tracking-tighter">
										{carSummary}
									</Text>
								)}
							</div>
						)}

						<div className="flex flex-wrap items-center justify-start gap-x-5 md:justify-start">
							<div className="flex items-center gap-5">
								{car?.pax && (
									<Text className="flex items-center gap-1 leading-snug tracking-tighter">
										<Icon name="person" className="shrink-0" />
										{car.pax}
									</Text>
								)}
								{car?.largeBaggage && (
									<Text className="flex items-center gap-1 leading-snug tracking-tighter">
										<Icon name="luggage" className="shrink-0" />
										{car.largeBaggage}
									</Text>
								)}
								{car?.smallBaggage && (
									<Text className="flex items-center gap-1 leading-snug tracking-tighter">
										<Icon name="small-luggage" className="shrink-0" />
										{car.smallBaggage}
									</Text>
								)}
							</div>
							{isMotorhome ? null : (
								<RentalInsurance
									className="hidden lg:block"
									info={car?.insuranceInfo}
								/>
							)}
						</div>
					</div>
					{car?.image && (
						<img
							src={car.image}
							className={cn(
								'self-start lg:self-start h-fit w-40 lg:w-[200px] object-contain xl:hidden',
								type === 'extras' && 'self-end'
							)}
						/>
					)}
				</div>
				<ul className="flex flex-col gap-1 mt-1.5 xl:mt-5 xl:mb-4">
					<LoopWrapper list={car?.features || []} itemKey="feature">
						{(feature) => (
							<li key={feature} className="list-none">
								<Text variant="muted" className="flex items-start gap-3">
									<Icon name="maple-leaf" className="shrink-0" />
									<Text as="span" className="leading-snug tracking-tighter">
										{feature}
									</Text>
								</Text>
							</li>
						)}
					</LoopWrapper>
				</ul>

				{isMotorhome ? null : (
					<RentalInsurance
						className="my-2 lg:hidden"
						info={car?.insuranceInfo}
					/>
				)}
				{!selected && (
					<>
						<Stack
							orientation="vertical"
							className="items-start justify-start gap-2.5 mt-0.5 md:mt-2.5 xl:mt-0"
						>
							{car?.duration && car?.price && (
								<Text variant="bold" className="text-2xl">
									{car.duration} {pluralize('day', car.duration)} rental{' '}
									{currencyFormatter({
										amount: car.price,
									})}
								</Text>
							)}

							<Button
								aria-label="Car Hire Book Now"
								variant="supporting-yellow"
								className="flex gap-2"
								onClick={onSelect}
								label="Book Now"
							/>
						</Stack>
						{car?.localCharge > 0 && (
							<div className="inline-flex items-start justify-start gap-2.5 pt-3">
								<div>
									<span className="text-base font-bold leading-tight text-slate-800">
										Important:{' '}
									</span>
									<span className="text-base font-normal leading-tight text-slate-800">
										One way fee/local charges{' '}
										{currencyFormatter({
											amount: car.localCharge,
											currency: 'CAD',
											currencyDisplay: 'code',
										})}{' '}
										plus tax (payable locally).
									</span>
								</div>
							</div>
						)}
					</>
				)}
			</div>
			{((!hideProviderLogo && car?.supplierLogo) || car?.image) && (
				<div className="hidden w-[300px] flex-col items-end justify-center gap-[25.89px] xl:inline-flex shrink-0">
					{!hideProviderLogo && (
						<div className="inline-flex h-[100px] w-[100px] items-center justify-center border border-border-color border-opacity-20 pl-6 pr-[23px]">
							{car?.supplierLogo && (
								<img
									className="h-full w-full object-contain scale-[1.4]"
									src={car.supplierLogo}
									alt={`${car?.supplier} logo`}
								/>
							)}
						</div>
					)}
					{car?.image && (
						<img
							className="hidden h-[200px] w-[300px] object-contain lg:block"
							src={car.image}
							alt={car?.displayName || car?.name}
						/>
					)}
				</div>
			)}
		</div>
	);
}

CarCard.propTypes = {
	car: PropTypes.object,
	hideProviderLogo: PropTypes.bool,
	selected: PropTypes.bool,
	className: PropTypes.string,
	onSelect: PropTypes.func,
};

export default CarCard;
