import { useEffect, useMemo, useState } from 'react';
import { useForm } from 'react-hook-form';
import { useLocation, useNavigate, useParams } from 'react-router-dom';

import Text from '@/components/common/atoms/text';
import Heading from '@/components/common/atoms/heading';
import FormField from '@/components/common/molecules/formfield';
import RteStyledContent from '@/components/common/atoms/rte-styled-content';
import VehicleExtrasTemplate from '@/components/templates/vehicle-extras';
import ExtraProductCard from '@/components/motorhomes/molecules/extra-product-card';
import useFetch from '@/hooks/useFetch';
import { useBookingStore } from '@/store';
import { ENDPOINTS } from '@/lib/api';
import invoke from '@/lib/invoke';

function HireExtras() {
	const navigate = useNavigate();
	const params = useParams();
	const { pathname } = useLocation();
	const category = params?.category
		? params.category
		: pathname && pathname.split('/')[2];

	const { setBookingState } = useBookingStore();
	const state = useBookingStore((state) => state?.[category]);
	const [disabledFields, setDisabledFields] = useState({});
	const [messages, setMessages] = useState({});
	const [isValid, setIsValid] = useState(true);
	const [isValidating, setIsValidating] = useState(false);

	const formatItemParams = (code, quantity, extrasResData) => {
		if (!quantity) return null;

		const bookingItem = extrasResData?.bookingParams?.[code];
		if (!bookingItem) return null;

		return {
			quantity: quantity,
			...bookingItem,
		};
	};

	const formatDropdownOptions = (min, max) => {
		const startNum = min > 0 ? min : 0;
		const totalItems = max - min > 2 ? max - min + 1 : 2;
		return [...Array(totalItems)].map((_, i) => ({
			value: startNum + i,
			label: `${startNum + i}`,
		}));
	};

	const extraSearchParams = useMemo(() => {
		if (!state?.selected?.items?.length) return null;

		const motorhomeItem = state.selected.items.find(
			(item) => item?.type === 'motorhomehire'
		);
		if (!motorhomeItem) return null;

		return {
			startDate: motorhomeItem?.startDate,
			endDate: motorhomeItem?.endDate,
			code: motorhomeItem?.code,
			pickupLocation: motorhomeItem?.pickupLocation,
			pickupDepot: motorhomeItem?.pickupDepot,
			pickupTime: motorhomeItem?.pickupTime,
			dropoffLocation: motorhomeItem?.dropoffLocation,
			dropoffDepot: motorhomeItem?.dropoffDepot,
			dropoffTime: motorhomeItem?.dropoffTime,
		};
	}, [state?.selected?.items]);

	// fetch motorhome extras
	const {
		data: extrasRes,
		isLoading,
		error,
	} = useFetch({
		key: 'motorhome-extra',
		params: extraSearchParams,
		config: {
			enabled: !!extraSearchParams,
			retryOnWindowFocus: false,
		},
		onData: (data) => {
			if (!data || error) return;

			const newExtrasState = {
				...JSON.parse(JSON.stringify(state?.motorhomeExtras || {})),
			};

			const defaultItems = [];
			const defaultDisabledFields = {};
			const defaultMessages = {};
			if (data?.data?.categories?.length) {
				data?.data?.categories.forEach((category) => {
					category?.fields?.forEach((field) => {
						if (!field?.code) return;

						// parse default values
						if (field?.defaultQuantity > 0) {
							const itemParams = formatItemParams(
								field.code,
								field.defaultQuantity,
								data?.data
							);
							if (itemParams) defaultItems.push(itemParams);
						}

						// set default disabled state
						defaultDisabledFields[field.code] = field?.defaultIsDisabled;

						// set default messages
						if (field?.defaultMessages?.length) {
							defaultMessages[field.code] = field.defaultMessages;
						}
					});
				});
			}

			// populate default values
			if (!state?.motorhomeExtras?.selectedExtras?.length) {
				newExtrasState.selectedExtras = defaultItems;
			}

			// set default disabled states
			setDisabledFields(defaultDisabledFields);

			//set default messages
			setMessages(defaultMessages);

			const newState = {
				...state,
				shouldBuildParams: true,
				motorhomeExtras: newExtrasState,
			};
			setBookingState(category, newState, 'SET_DEFAULT_MOTORHOME_EXTRAS');
		},
	});

	const defaultValues = useMemo(() => {
		if (!extrasRes?.data?.categories) return {};

		const selected = state?.motorhomeExtras?.selectedExtras || [];

		const newDefaults = {};
		extrasRes?.data?.categories.forEach((category) => {
			if (category.isRadioGroup) {
				const selectedCodes = selected.map((i) => i.code);
				const selectedRadio = category.fields.filter((field) =>
					selectedCodes.includes(field?.code)
				);

				newDefaults[`${category.id}-radiogroup`] = !!selectedRadio.length
					? selectedRadio[0]?.code
					: '';
			}

			category?.fields?.forEach((field) => {
				const selectedItem = selected.find(
					(selected) => selected?.code === field?.code
				);

				let defaultValue = null;
				switch (field?.type) {
					case 'checkbox':
						defaultValue = selectedItem?.quantity > 0 ? true : false;
						break;

					case 'dropdown':
						defaultValue = {
							value: selectedItem?.quantity > 0 ? selectedItem.quantity : 0,
							label:
								selectedItem?.quantity > 0
									? selectedItem.quantity.toString()
									: '0',
						};
						break;
				}

				newDefaults[field?.code] = defaultValue;
			});
		});

		return newDefaults;
	}, [extrasRes?.data?.categories, state?.motorhomeExtras?.selectedExtras]);

	const { watch, control, handleSubmit, reset } = useForm({
		defaultValues,
	});

	useEffect(() => {
		reset(defaultValues, { keepDirtyValues: true, keepDirty: true });
	}, [defaultValues, reset]);

	// parse field drop-down option values
	const options = useMemo(() => {
		if (!extrasRes?.data?.categories?.length) return [];

		const newOptions = [];
		extrasRes.data.categories.forEach((category) => {
			if (category?.isRadioGroup) {
				newOptions[category.id] = category?.fields?.map((field) => ({
					value: field?.code,
					label: 'Add to basket',
					disabled: disabledFields?.[field?.code],
				}));
			}
			category?.fields?.forEach((field) => {
				const code = field?.code;
				switch (field?.type) {
					case 'dropdown':
						newOptions[code] = formatDropdownOptions(
							field?.minValue,
							field?.maxValue
						);
						break;
				}
			});
		});
		return newOptions;
	}, [extrasRes?.data?.categories, disabledFields]);

	const sendValidationRequest = async (fieldState, extrasResData, onData) => {
		setIsValidating(true);

		// assume valid if we don't have any categories to display
		const categories = extrasResData?.categories;
		if (!categories?.length) {
			setIsValidating(false);
			onData(true, null);
			return;
		}

		// build params
		const fieldTypes = {};
		const items = categories?.reduce((all, category) => {
			category?.fields?.map((field) => {
				const code = field?.code;
				fieldTypes[code] = field?.type;

				let quantity = 0;
				switch (field?.type) {
					case 'radio':
						quantity = +(code === fieldState[`${category.id}-radiogroup`]) || 0;
						break;
					case 'checkbox':
						quantity = +fieldState[code] || 0;
						break;
					case 'dropdown':
						quantity = fieldState[code]?.value || 0;
						break;
				}

				all[code] = quantity;
			});

			return all;
		}, {});

		// assume valid if we don't have any items
		if (Object.keys(items).length == 0) {
			setIsValidating(false);
			onData(true, null);
			return;
		}

		// send validation request
		const { endpoint } = ENDPOINTS.find(
			(e) => e.key === 'motorhome-validate-extras'
		);
		const { res } = await invoke({
			method: 'POST',
			data: {
				code: extrasRes?.extra?.car?.code,
				items: items,
			},
			endpoint: endpoint,
		});

		// update states
		setIsValidating(false);
		setMessages(res?.data?.messages || {});

		// handle validation actions
		const newFieldState = { ...JSON.parse(JSON.stringify(fieldState)) };
		const newDisabledFields = { ...(disabledFields || {}) };
		if (res?.data?.actions) {
			Object.keys(res.data.actions).forEach((code) => {
				const actions = res.data.actions?.[code];
				if (!actions.length) return;

				actions.forEach((action) => {
					switch (action?.type) {
						case 'setDisabled':
							// toggle disabled field state
							newDisabledFields[code] = action?.isDisabled;
							break;
						case 'setQuantity':
							// set new quantity if different from current value
							const newQuantity = action?.quantity || 0;
							if (items[code] == newQuantity) return;

							const foundCate = categories.find((cate) => {
								if (cate.fields.some((cf) => cf.code === code)) {
									return cate;
								}
							});
							if (foundCate.isRadioGroup && items[code] !== newQuantity) {
								newFieldState[`${foundCate.id}-radiogroup`] =
									newQuantity > 0 ? code : '';
							}

							switch (fieldTypes[code]) {
								case 'checkbox':
									newFieldState[code] = newQuantity > 0 ? true : false;
									break;
								case 'dropdown':
									newFieldState[code] = {
										value: newQuantity,
										label: newQuantity?.toString(),
									};
									break;
							}
							break;
					}
				});
			});
		}

		// update disabled state
		setDisabledFields(newDisabledFields);

		// trigger callback
		const isValid = res?.data?.isValid || false;
		onData(isValid, newFieldState);
	};

	// trigger validation on change
	useEffect(() => {
		const subscription = watch((data, { name, type }) => {
			if (type !== 'change') return;

			sendValidationRequest(data, extrasRes?.data, (isValid, newFieldState) => {
				setIsValid(isValid);

				// push extras to booking state
				if (newFieldState) {
					const newItems = [];
					if (extrasRes?.data?.categories?.length) {
						extrasRes?.data?.categories.forEach((category) => {
							category?.fields?.forEach((field) => {
								let quantity = 0;
								switch (field?.type) {
									case 'radio':
										quantity =
											+(
												field?.code ===
												newFieldState[`${category.id}-radiogroup`]
											) || 0;
										break;
									case 'checkbox':
										quantity = +newFieldState?.[field?.code] || 0;
										break;
									case 'dropdown':
										quantity = newFieldState?.[field?.code]?.value || 0;
										break;
								}
								if (quantity < 1) return;

								const itemParams = formatItemParams(
									field?.code,
									quantity,
									extrasRes?.data
								);
								if (itemParams) newItems.push(itemParams);
							});
						});
					}

					// update state
					const newState = {
						...state,
						shouldBuildParams: true,
						motorhomeExtras: {
							// ...(state?.motorhomeExtras || {}),
							selectedExtras: newItems,
						},
					};
					setBookingState(category, newState, 'SET_MOTORHOME_EXTRAS');

					// trigger field re-render
					reset((prev) => ({ ...prev }));
				}
			});
		});

		return () => subscription.unsubscribe();
	}, [watch, extrasRes?.data]);

	useEffect(() => {
		if (!extrasRes?.data?.categories) return;

		// trigger validation messages when re-entering the page
		if (state?.motorhomeExtras?.selectedExtras) {
			setTimeout(() => {
				sendValidationRequest(defaultValues, extrasRes?.data, (isValid) => {
					setIsValid(isValid);
				});
			}, 250);
		}
	}, [extrasRes?.data]);

	const onSubmit = (data) => {
		sendValidationRequest(data, extrasRes?.data, (isValid) => {
			setIsValid(isValid);

			// navigate to next step
			if (isValid) {
				navigate(`/booking/${category}/driver-details`);
			}
		});
	};

	const isAllFieldsDisabled =
		state?.isLoading || state?.previewLoading || isLoading || isValidating;

	return (
		<VehicleExtrasTemplate
			category={category}
			isLoading={isLoading}
			title="Motorhome Hire Extras"
			error={error}
			onSubmit={handleSubmit(onSubmit)}
			continueIsDisabled={isAllFieldsDisabled || !isValid}
			vehicle={extrasRes?.extra?.car}
		>
			<div className="flex flex-col gap-2.5">
				<Text className="leading-snug tracking-tighter text-dark-grey">
					Lorem ipsum dolor sit amet, consectetur adipiscing elit. Aenean
					vulputate ac massa ac egestas. Curabitur accumsan velit non porta
					lobortis. Fusce magna justo, ullamcorper in ipsum vitae, consectetur
					convallis libero.
				</Text>
				<Text className="leading-snug tracking-tighter text-dark-grey">
					Morbi ultrices sapien in lobortis finibus. Morbi porttitor leo quis
					lectus porta consequat. Sed gravida mi justo, sed sagittis augue
					ornare et. Suspendisse tempus sit amet arcu quis pharetra. Duis nec
					risus pretium dolor fermentum sagittis eu id mi.
				</Text>
			</div>

			<div className="flex flex-col gap-8">
				{extrasRes?.data?.categories?.map((category) => (
					<div key={category?.id} className="flex flex-col gap-8">
						<div className="flex flex-col gap-2">
							<Heading
								as="h2"
								className="text-3.5xl font-bold font-body leading-extra-tight tracking-tight text-dark-grey"
							>
								{category?.name}
							</Heading>
							{!!category?.description && (
								<RteStyledContent
									variant="dark"
									dangerouslySetInnerHTML={{ __html: category?.description }}
								/>
							)}
						</div>

						{category?.isRadioGroup ? (
							options?.[category.id] && (
								<FormField
									as="radio-group"
									control={control}
									name={`${category.id}-radiogroup`}
									options={options?.[category.id]?.map((opt) => ({
										...opt,
										disabled:
											isAllFieldsDisabled || disabledFields[opt?.value]
												? true
												: false,
									}))}
									showRadioAsButton={true}
									className="flex flex-col gap-2.5"
									itemLabelClassName="flex-none"
									renderOptions={(opts) =>
										category?.fields?.map((field) => (
											<ExtraProductCard
												key={field?.id}
												item={field}
												radioOptComponent={
													opts.filter((opt) => opt.key === field?.code)[0]
												}
												itemType={field.type}
												itemCode={field?.code}
												control={control}
												isFeatured={category?.isFeatured}
												messages={messages[field?.code]}
												isDisabled={
													isAllFieldsDisabled || disabledFields[field?.code]
														? true
														: false
												}
											/>
										))
									}
								/>
							)
						) : (
							<div className="flex flex-col gap-2.5">
								{category?.fields?.map((field) => (
									<ExtraProductCard
										key={field?.id}
										item={field}
										control={control}
										isFeatured={category?.isFeatured}
										options={options[field?.code]}
										messages={messages[field?.code]}
										isDisabled={
											isAllFieldsDisabled || disabledFields[field?.code]
												? true
												: false
										}
									/>
								))}
							</div>
						)}
					</div>
				))}
			</div>
		</VehicleExtrasTemplate>
	);
}

export default HireExtras;
