import React from 'react';
import PropTypes from 'prop-types';

import Text from '@/components/common/atoms/text';
import VehicleSummaryCard from '@/components/common/molecules/vehicle-summary-card';

/**
 * @typedef {Object} MotorHomeSummaryProps
 * @property {Object | null} motorhome
 * @property {string} className
 */

/**
 * @name MotorHomeSummary
 * @description A summary of the motorhome details
 * @param {MotorHomeSummaryProps} props
 * @returns {React.JSX.Element}
 * @example
 * <MotorHomeSummary motorhome={motorhome} />
 */

function MotorHomeSummary({ motorhome, className }) {
	return (
		<VehicleSummaryCard
			vehicle={motorhome}
			className={className}
			iconName="motorhome-right"
			extrasTitle="Extras:"
		>
			{motorhome?.berths ? (
				<Text>Sleeping Summary: <strong>{motorhome.berths} People</strong></Text>
			) : null}

			{motorhome?.bedSummary ? (
				<Text>Bed Summary: <strong>{motorhome.bedSummary}</strong></Text>
			) : null}
		</VehicleSummaryCard>
	);
}

MotorHomeSummary.propTypes = {
	motorhome: PropTypes.object,
	className: PropTypes.string,
};

MotorHomeSummary.defaultProps = {
	className: '',
	motorhome: null,
};

export default MotorHomeSummary;
