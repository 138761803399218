import { WHO_OPTIONS } from '@/lib/utils'

/**
 * Add option plus booking params to a flight booking item.
 *
 * @param {Object} optionsPlus
 * @param {Object} item
 * @returns Object
 */
const addOptionPlusParams = (optionsPlus, item) => {
    const newItem = { ...item };
    Object.keys(optionsPlus).forEach((key) => {
        if (key === 'roundTrip') {
            newItem.outbound = {
                ...newItem.outbound,
                optionPlus: {
                    code: optionsPlus[key]?.code,
                    set: optionsPlus[key]?.set,
                    startDate: optionsPlus[key]?.startDate,
                }
            }
        } else {
            if (!newItem[key]) return;
            newItem[key] = {
                ...newItem[key],
                optionPlus: {
                    code: optionsPlus[key]?.code,
                    set: optionsPlus[key]?.set,
                    startDate: optionsPlus[key]?.startDate,
                }
            }
        }

    });
    return newItem;
}

/**
 * Add baggage booking params to flight booking items.
 *
 * @param {Object} state
 * @param {Object} item
 * @returns Object
 */
const addBaggageParams = (baggage, item) => {
    const newItem = { ...item };
    if (baggage?.outbound) {
        newItem.outbound = {
            ...newItem.outbound,
            baggage: baggage?.outbound,
        };
    }

    if (baggage?.inbound) {
        newItem.inbound = {
            ...newItem.inbound,
            baggage: baggage?.inbound,
        };
    }

    return newItem;
}

/**
 * Add seat selection booking params to flight booking items.
 *
 * @param {Object} state
 * @param {Object} item
 * @returns Object
 */
const addSeatSelectionParams = (selectedSeats, item) => {
    const newItem = { ...item };

    ['outbound', 'inbound'].forEach((flightType) => {
        const selection = selectedSeats?.[flightType] ?? null;
        const sectors = newItem?.[flightType]?.sectors ?? [];
        const hasSeatsAssigned = sectors.some((sector) => sector?.seats?.length > 0);

        if (!selection) {
            if (hasSeatsAssigned) {
                newItem[flightType] = {
                    ...newItem[flightType],
                    sectors: sectors.map((sector) => {
                        sector.seats = undefined;
                        sector.gateway = undefined;
                        return sector;
                    }),
                };
            }
        } else {
            newItem[flightType] = {
                ...newItem[flightType],
                sectors: sectors.map((sector) => {
                    const flightNumber = sector?.flightNumber;
                    const seats = selection?.[flightNumber]?.seats ?? [];
                    const gateway = selection?.[flightNumber]?.gateway ?? [];

                    return {
                        ...sector,
                        gateway: gateway,
                        seats: seats.map((seat) => ({
                            paxRef: seat?.paxRef,
                            col: seat?.col,
                            row: seat?.row,
                        })),
                    }
                }),
            };
        }
    })

    return newItem;
}

/**
 * Add upselling parameters to all booking items.
 *
 * @param {Object} state
 * @param {Array<object>} items
 * @returns Array<object>
 */
const addUpsellingToBookingItems = (state, items) => {
    return items.map((item) => {
        switch (item?.type) {
            case 'flight':
                let newFlights = { ...item };

                // option plus
                if (state?.optionPlusOptions) {
                    newFlights = addOptionPlusParams(state.optionPlusOptions, newFlights)
                }

                // baggage
                if (state?.baggage) {
                    newFlights = addBaggageParams(state.baggage, newFlights);
                }

                // seat selection
                if (state?.selectedSeats) {
                    newFlights = addSeatSelectionParams(state.selectedSeats, newFlights);
                }

                return newFlights;
            case 'holidays':
                return {
                    ...item,
                    items: addUpsellingToBookingItems(state, item?.items || []),
                };

            default:
                // all other items (hotels, cars, etc)
                return item;
        }
    });
}

export const carhireParams = (state) => {
    const selected = state?.selected ?? null;

    if (!selected) return null;

    const selectedPackage = state?.carExtras?.selectedPackageExtra ?? null;
    const selectedItemExtras = state?.carExtras?.selectedItemExtras ?? [];

    selected.extras = []
    if (selectedPackage) {
        const extrasFormat = {
            type: 'item',
            code: selectedPackage?.code ?? '',
            set: selectedPackage?.set ?? '',
            startDate: selected?.startDate ?? '',
            endDate: selected?.endDate ?? '',
            quantity: 1,
            pickupTime: selected?.pickupTime ?? '',
            dropoffTime: selected?.dropoffTime ?? ''
        }
        selected.extras = [extrasFormat]
    }

    if (selectedItemExtras?.length > 0) {
        selectedItemExtras.forEach((extra) => {
            selected.extras.push({
                type: 'item',
                code: extra?.item?.code ?? '',
                set: extra?.item?.set ?? '',
                startDate: selected?.startDate ?? '',
                endDate: selected?.endDate ?? '',
                quantity: extra?.quantity ?? 1,
                pickupTime: selected?.pickupTime ?? '',
                dropoffTime: selected?.dropoffTime ?? ''
            })
        })
    }

    const people = WHO_OPTIONS.reduce((acc, key) => {
        acc[key.value] = key.value === 'adults' ? 1 : 0;
        return acc;
    }, {});


    let params = {
        category: 'car-hire',
        items: [selected],
        rooms: [people]
    }

    // include booking details to the preview as well
    if (state?.bookingDetails) {
        params = {
            ...params,
            ...state?.bookingDetails,
            rooms: state?.bookingDetails?.pax ? [] : [people],
            category: 'car-hire',
            pageUrl: window.location.href,
        }
    }

    return params
}

export const flightsParams = (state) => {
    if (!state) return null;
    if (!state.selected) return null;

    // make sure selected outbound is available
    if (!state?.selected?.outbound) return null;

    // if it's a return flight make sure the inbound is available
    if (!state?.isOneWay && !state?.selected?.inbound) return null;

    const items = state?.selected?.items ?? [];

    if (!Array.isArray(items)) return null;
    if (items.length === 0) return null;

    // make sure items[0] outbound is available
    if (!items[0]?.outbound) return null;

    // if it's a return flight make sure the inbound is available
    if (!state?.isOneWay && !items[0]?.inbound) return null;


    // default params to be updated
    let initialParams = {
        category: 'flights',
        items: addUpsellingToBookingItems(state, state?.selected?.items),
        rooms: !state?.bookingDetails?.pax || state?.usePeople
            ? [state?.selected?.people]
            : [],
    };


    // get the pax details from the booking state
    if (state?.bookingDetails && !state?.usePeople) {
        initialParams = {
            ...initialParams,
            rooms: [],
            ...state?.bookingDetails,
            category: 'flights',
            pageUrl: window.location.href,
        };
    }

    return initialParams;
}

export const hotelsParams = (state) => {
    if (!state) return null;


    const selected = state?.selected ?? null;
    if (!selected?.items?.length) return null;

    // include booking details to the preview as well
    let params = {
        category: 'hotels',
        items: state?.selected?.items,
    }

    if (state?.bookingDetails) {
        params = {
            ...params,
            ...state?.bookingDetails,
            category: 'hotels',
            pageUrl: window.location.href,
        }
    }

    return params

}

export const holidaysParams = (state) => {
    if (!state) return null;
    if (!state?.selected) return null;

    const items = state?.selected?.items ?? [];

    if (!Array.isArray(items)) return null;
    if (items.length === 0) return null;

    let initialParams = {
        category: 'holidays',
        includeHolidayUpgrades: state?.includeHolidayUpgrades ? true : false,
        items: addUpsellingToBookingItems(state, items),
        rooms: items?.[0].rooms,
    };

    if (state?.bookingDetails && !state?.usePeople) {
        initialParams = {
            ...initialParams,
            // rooms: [],
            ...state?.bookingDetails,
            category: 'holidays',
        };
    }

    return initialParams;
}

export const motorhomehireParams = (state) => {
    if (!state) return null;
    if (!state?.selected) return null;
    if (!Array.isArray(state?.selected?.items)) return null;

    const items = state?.selected?.items.map((item) => {
        // add motorhome extras
        if (item?.type === 'motorhomehire') {
            item.extras = state?.motorhomeExtras?.selectedExtras || [];
        }

        return item;
    });
    if (items.length === 0) return null;

    // motorhomes are always booked with one passenger (the lead driver)
    const people = WHO_OPTIONS.reduce((acc, key) => {
        acc[key.value] = key.value === 'adults' ? 1 : 0;
        return acc;
    }, {});

    let params = {
        category: 'motorhome-hire',
        items: items,
        rooms: [people],
    };

    // include booking details to the preview as well
    if (state?.bookingDetails && !state?.usePeople) {
        params = {
            ...params,
            ...state?.bookingDetails,
            rooms: state?.bookingDetails?.pax ? [] : [people],
        };
    }

    return params;
}

/**
 * @name buildParams
 * @description Builds the params for the given category from the state values
 * @param {unknown} state
 * @param {"flights" | "hotels" | "motorhome-hire" | "car-hire" | "holidays"} category
 * @returns {Object | null}
 * @example
 * buildParams(state, 'car-hire');
 */
export const buildParams = (state, category) => {
    if (!state || !category) return null;

    switch (category) {
        case 'car-hire':
            return carhireParams(state)
        case 'motorhome-hire':
            return motorhomehireParams(state)
        case 'flights':
            return flightsParams(state)
        case 'hotels':
            return hotelsParams(state)
        case 'holidays':
            return holidaysParams(state)
        default:
            return null
    }
}

/**
 * @name formatErrata
 * @description Format item errata from an array of additional params.
 * @param {Array} additionalParams
 * @returns {Array}
 */
const formatErrata = (additionalParams) => {
    if (!additionalParams?.length) return [];

    return additionalParams.reduce(
        (all, additional) => additional?.errata?.length > 0 ?
            [...all, ...additional.errata] :
            all,
        []
    );
}

/**
 * @name addErrataParams
 * @description Adds errata to formatted booking params
 * @param {Object} bookingParams
 * @param {Object} preview
 * @returns {Object}
 * @example
 * buildParams(params, state.preview);
 */
export const addErrataParams = (bookingParams, preview) => {
    if (!bookingParams?.items?.length) return bookingParams;

    const additionalParams = preview?.additionalParams ?? [];
    if (!additionalParams?.length) return bookingParams;

    bookingParams.items = bookingParams.items.map((item) => {
        // add errata to each booking item
        item.errata = formatErrata(
            additionalParams.filter((params) => params?.type === item?.type)
        );

        // add errata to extras
        if (item?.extras?.length && item?.type === 'motorhomehire') {
            item.extras = item.extras.map((extra) => {
                extra.errata = formatErrata(
                    additionalParams.filter((params) => (
                        params?.type === 'item' &&
                        params?.code === extra?.code
                    ))
                );
                return extra;
            });
        }

        return item;
    });

    return bookingParams;
}
