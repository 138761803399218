import PropTypes from 'prop-types';
import { useWatch } from 'react-hook-form';

import { FormField, LoopWrapper, SearchFormButton } from '@/components/common';
import {
	cn,
	Required,
	ONE_TO_NINE,
	HOTEL_WHO_OPTIONS,
	ZERO_TO_NINE,
	capitalizeString,
} from '@/lib/utils';

function RoomChoiceFields({
	errors,
	control,
	roomCount,
	className,
	handleSubmit,
	showSaveAndSearchBtn,
	validateMaxPeopleOfType,
}) {
	const values = useWatch({ control });

	const getValidation = (type) => {
		if (type === 'adults') return [Required, validateMaxPeopleOfType(type)];
		return [validateMaxPeopleOfType(type)];
	};
	return (
		<div
			className={cn(
				'flex flex-col gap-2.5 bg-light-grey p-5 md:px-7.5 lg:px-14 md:py-8',
				className
			)}
		>
			<h2 className="text-base font-normal leading-tight tracking-normal text-light-black font-body">
				You are searching for:{' '}
				<strong>
					{roomCount.value} Room{roomCount.value > 1 && 's'}
				</strong>
			</h2>

			{[...Array(parseInt(roomCount.value))].map((e, i) => (
				<div className="flex flex-col gap-2.5" key={`choose-room-${i}`}>
					<h3 className="text-base font-normal leading-tight tracking-normal font-body">
						<strong>Room {i + 1}</strong>
					</h3>

					<div className="h-full w-full flex items-start justify-start gap-2 md:gap-2.5 lg:gap-5">
						<LoopWrapper list={HOTEL_WHO_OPTIONS} itemKey="value">
							{({ value, label }) => (
								<FormField
									as="select"
									errors={errors}
									control={control}
									placeholder={capitalizeString(value)}
									name={`rooms[${i}][${value}]`}
									validation={getValidation(value)}
									wrapperClassName="w-full lg:min-w-[91px]"
									options={value === 'adults' ? ONE_TO_NINE : ZERO_TO_NINE}
									footer={label}
								/>
							)}
						</LoopWrapper>
					</div>
				</div>
			))}

			{showSaveAndSearchBtn && (
				<SearchFormButton
					hideCancel
					label="Save & Search"
					onCancel={() => null}
					handleSubmit={handleSubmit}
					className="w-[212px] self-start"
					hasYouth={values?.who?.youth?.value > 0}
				/>
			)}
		</div>
	);
}

RoomChoiceFields.propTypes = {
	className: PropTypes.string,
	control: PropTypes.object.isRequired,
	errors: PropTypes.object.isRequired,
	roomCount: PropTypes.object.isRequired,
	handleSubmit: PropTypes.func.isRequired,
	validateMaxPeopleOfType: PropTypes.func.isRequired,
	showSaveAndSearchBtn: PropTypes.bool,
};

RoomChoiceFields.defaultProps = {
	className: '',
	showSaveAndSearchBtn: false,
};

export default RoomChoiceFields;
