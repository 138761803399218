import { useRef, useMemo, useState, useEffect } from 'react';
import PropTypes from 'prop-types';
import MapListItem from '../MapListItem';
import { Text, Map, MapMarker, MapListToggle } from '@/components/common';
import { cn } from '@/lib/utils';
import { useBreakpoint, useMapToggle } from '@/hooks';

function MapList({ showOnMap, isOneWay, defaultCenter, results, fetching, carTypeFilter, handleCarSelect }) {
	const [map, setMap] = useState(null);
	const { view, toggle } = useMapToggle('default');
	const [selected, setSelected] = useState(null);
	const resultListRef = useRef(null);
	const isLg = useBreakpoint('lg');

	// force map to recalculate size after changing to map view
	useEffect(() => {
		if (!map?.target) return;
		if (view !== 'map') return;

		map.target.invalidateSize();
	}, [map, view]);

	const getDepot = (item) => item && item[showOnMap] ? item[showOnMap] : null;

	const selectedDepot = useMemo(() => getDepot(selected), [selected]);

	// function to change the map center when a new location is selected or a marker is clicked
	const changeSelected = (item, isMapClick) => {
		if (!item) {
			setSelected(null);
			return;
		}

		// deselect item if already selected
		const itemDepot = getDepot(item);
		if (
			selected &&
			selectedDepot &&
			itemDepot?.lat === selectedDepot?.lat &&
			itemDepot?.lng === selectedDepot?.lng
		) {
			setSelected(null);
			return;
		}

		// zoom to selected item
		if (itemDepot?.lat && itemDepot?.lng && map?.target) {
			const currentZoom = map.target.getZoom();
			const newZoom = currentZoom >= 12 ? currentZoom : 12;
			map.target.flyTo([itemDepot?.lat, itemDepot?.lng], newZoom);
		}

		if (isMapClick) {
			// show list view on mobile
			toggle('default');

			// scroll selected item into view
			if (resultListRef?.current) {
				const optionEl = resultListRef.current.querySelector(`[id="${item.value}"]`);
				if (optionEl) setTimeout(() => {
					// scroll entire page to element on mobile
					if (isLg) {
						const headerOffset = -128;
						window.scrollTo({
							top: optionEl.getBoundingClientRect().top + window.scrollY + headerOffset,
							behavior: 'smooth',
						});
						return;
					}

					// scroll container div to element on desktop
					const scrollEl = optionEl.closest('#mapListContainer');
					if (scrollEl) {
						scrollEl.scrollTop = optionEl.offsetTop;
					}
				}, 100);
			}
		}

		setSelected(item);
	}

	// convert results to array of objects in format {value: 'code', ...rest}
	const options = useMemo(() => {
		if (!results) return [];
		return results.map((item) => ({
			value: item?.pickup?.code +
				(item?.dropoff?.code ? `---${item.dropoff.code}` : ''),
			...item,
		}));
	}, [results]);

	const calculatedMapCenter = useMemo(() => {
		// use center if provided by the api
		if (defaultCenter?.lat && defaultCenter?.lng) {
			return [defaultCenter.lat, defaultCenter.lng];
		}

		// otherwise, default to the last depot lat/lng in the list
		if (!results?.length) return undefined;

		const lastPos = results.length - 1;
		const lastItemDepot = getDepot(results[lastPos]);
		return lastItemDepot?.lat && lastItemDepot?.lng ?
			[lastItemDepot.lat, lastItemDepot.lng] :
			undefined
	}, [results, defaultCenter]);

	return (
		<div>
			<div className="px-5 md:px-0">
				<Text className="text-xl w-full lg:text-3xl" variant="bold">
					Choose Car & Location
				</Text>
				<MapListToggle
					view={view}
					onChange={toggle}
					className="my-2 lg:hidden"
				/>
			</div>
			<div className="h-auto my-4 grid grid-cols-1 lg:grid-cols-2 lg:h-[800px]">
				<Map
					className={cn('w-full h-[500px] lg:h-full')}
					wrapperClassName={cn(
						'border border-lighter-grey',
						view === 'default' && 'hidden lg:block'
					)}
					defaultCenter={calculatedMapCenter}
					zoomLevel={11}
					setMap={setMap}
				>
					{options.length > 0 &&
						options
							.filter((item) => {
								// hide options without lat/lng positions
								const markerDepot = getDepot(item);
								return markerDepot?.lat && markerDepot?.lng ? true : false;
							})
							.map((item, i) => {
								const markerDepot = getDepot(item);
								return (
									<MapMarker
										key={`car-map-${i}-${item?.pickup?.code}-${item?.dropoff?.code}`}
										position={[markerDepot.lat, markerDepot.lng]}
										onClick={() => changeSelected(item, true)}
										iconUrl={item?.cars[0]?.supplierMapIcon}
										iconWidth={item?.cars[0]?.supplierMapIconWidth}
										iconHeight={item?.cars[0]?.supplierMapIconHeight}
										iconAnchorPosX={item?.cars[0]?.supplierMapIconAnchorPosX}
										iconAnchorPosY={item?.cars[0]?.supplierMapIconAnchorPosY}
										popup={markerDepot?.name}
										isActive={
											markerDepot.lat === selectedDepot?.lat &&
											markerDepot?.lng === selectedDepot?.lng
										}
									/>
								);
							})}
				</Map>
				<div
					id="mapListContainer"
					className={cn(
						'flex flex-col w-full h-full gap-5 py-5 bg-white border border-lighter-grey overflow-hidden lg:overflow-y-scroll scroll-smooth',
						view === 'map' && 'hidden lg:block'
					)}
				>
					<Text className="text-xl font-bold mx-5">
						{isOneWay ?
							`Select ${showOnMap === 'pickup' ? 'Pick Up' : 'Drop Off'} Location` :
							'Select Location'
						}
					</Text>
					<MapListItem
						ref={resultListRef}
						results={options}
						fetching={fetching}
						selectedOption={selected}
						handleOptionSelect={changeSelected}
						carTypeFilter={carTypeFilter}
						handleCarSelect={handleCarSelect}
						showOnMap={showOnMap}
					/>
				</div>
			</div>
		</div>
	);
}

MapList.propTypes = {
	/**
	 * Controls whether we show pick-up or drop-off locations on the map.
	 */
	showOnMap: PropTypes.oneOf(['pickup', 'dropoff']).isRequired,

	/**
	 * The car search results.
	 */
	results: PropTypes.array.isRequired,

	/**
	 * Indicator for whether data is being fetched.
	 */
	fetching: PropTypes.bool.isRequired,

	/**
	 * The selected car type filter.
	 */
	carTypeFilter: PropTypes.array,

	/**
	 * Indictor for whether the search is a one-way search.
	 */
	isOneWay: PropTypes.bool,

	/**
	 * The default map center.
	 */
	defaultCenter: PropTypes.object,
};

MapList.defaultProps = {
	showOnMap: 'pickup',
	results: [],
	fetching: false,
	isOneWay: false,
};

export default MapList;
