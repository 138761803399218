import PropTypes from 'prop-types';
import { useLocation } from 'react-router-dom';

import {
	Icon,
	Navbar,
	PageHeader,
	PageWrapper,
	SearchWidget,
	TravelPlanWidget,
	Button,
} from '@/components/common';
import { cn } from '@/lib/utils';
import { StepBreadcrumb } from '@/components/flights';
import { useBackNavigate, useCalculateMargin } from '@/hooks';
import useVerticalOverflow from '@/hooks/useVerticalOverflow';

/**
 * @typedef {Object} BookingPageWrapperProps
 * @property {string} title
 * @property {boolean} loading
 * @property {Error | null} error
 * @property {string} loadingText
 * @property {string} previousPage
 * @property {boolean} hideTravelPlan
 * @property {boolean} disablePreviewFetch
 * @property {boolean} disableBreadcrumb
 * @property {boolean} disableBackBtn
 * @property {React.ReactNode} children
 * @property {string} contentClassName
 * @property {string} titleWrapperClassName
 * @property {string} childrenClassName
 * @property {string} pageHeaderClassName
 * @property {boolean} continueIsDisabled
 * @property {()=>void} handleCancelRequest
 * @property {()=>React.ReactNode} renderTravelPlan
 * @property {()=>void | null | undefined} onContinue
 * @property {()=>React.ReactNode} renderFooterContent
 * @property {import("@/hooks/useBreadcrumbs").Crumb[]} steps
 * @property {"flights" | "holidays" | "hotels" | "motorhome-hire" | "car-hire"} category
 * */

/**
 * @name BookingPageWrapper
 * @description A wrapper component for booking pages - provides the column layout and travel plan widget for booking pages
 * @param {BookingPageWrapperProps} props
 * @returns {React.JSX.Element}
 * @example
 * <BookingPageWrapper
 * 	 steps={steps}
 * 	 title={title}
 * 	 error={error}
 * 	 loading={loading}
 * 	 category="flights"
 * 	 onContinue={onContinue}
 * >
 * 	 {children}
 * </BookingPageWrapper>
 * */

function BookingPageWrapper({
	steps,
	title,
	error,
	loading,
	children,
	category,
	onContinue,
	loadingText,
	previousPage,
	hideTravelPlan,
	disablePreviewFetch,
	disableBreadcrumb,
	disableBackBtn,
	continueIsDisabled,
	handleCancelRequest,
	contentClassName,
	titleWrapperClassName,
	childrenClassName,
	pageHeaderClassName,
	renderFooterContent,
	renderBackButton,
	renderTravelPlan = () => null,
	portalData,
}) {
	const { pathname } = useLocation();
	const { observe, containerRef } = useCalculateMargin();

	const isSearchPage = pathname.includes('search');
	const { handleBack } = useBackNavigate(steps, category);

	const { observe: dimObserve, isOverflowing } = useVerticalOverflow(true);

	return (
		<div className="relative bg-light-grey">
			{isSearchPage ? (
				<SearchWidget
					ref={dimObserve}
					category={category}
					className={cn(
						portalData?.withSiteHeader
							? 'fixed z-50 w-full top-15 md:top-25 drop-shadow-3xl max-h-[calc(100vh-3.75rem)] md:max-h-[calc(100vh-6.25rem)]'
							: 'max-h-screen',
						isOverflowing ? 'overflow-y-auto' : ''
					)}
				/>
			) : (
				<Navbar />
			)}

			<PageWrapper
				error={error}
				loading={loading}
				loadingText={loadingText}
				loaderClassName="h-[calc(100vh-4.5rem)]"
				onCancelRequest={handleCancelRequest}
				className={cn(
					'bg-light-grey min-h-screen md:min-h-[calc(100vh-4.5rem)] relative',
					isSearchPage && !loading && portalData?.withSiteHeader ? 'pt-18' : ''
				)}
			>
				<div
					className={cn(
						'w-full max-w-screen-2xl mx-auto flex flex-col py-5 sm:py-7.5 md:py-10 lg:py-15 justify-between items-start px-5 md:flex-row md:gap-12 3xl:px-0',
						contentClassName
					)}
				>
					<div className="w-full h-fit md:w-1/2 xl:w-3/4">
						<div className={titleWrapperClassName}>
							<PageHeader
								title={title}
								className="px-0 pb-3 md:px-0 lg:px-0 xl:pb-8"
								titleClassName={cn(pageHeaderClassName)}
								renderCustomBreadcrumb={() => (
									<StepBreadcrumb
										steps={steps}
										category={category}
										disabled={disableBreadcrumb}
									/>
								)}
							/>

							<span
								className={cn('xl:hidden', {
									hidden: !previousPage || pathname === steps[0].to, // hide if no previous step or if on first step
								})}
							>
								{renderBackButton ? (
									renderBackButton(previousPage)
								) : (
									<Button
										variant="unstyled"
										onClick={handleBack}
										className="flex items-center gap-2 mb-4 text-base"
										disabled={disableBackBtn}
									>
										<Icon name="arrow-left" className="w-5 h-5" />
										<span className="font-normal">
											Back to {previousPage?.name}
										</span>
									</Button>
								)}
							</span>
						</div>
						<div
							className={cn(
								'flex flex-col gap-6 lg:max-w-5xl',
								childrenClassName
							)}
						>
							{children}
						</div>
					</div>
					<div
						className={cn(
							'hidden sticky top-28 right-0 w-full md:w-1/2 md:block xl:w-1/3',
							hideTravelPlan ? 'hidden' : '',
							isSearchPage && !loading && portalData?.withSiteHeader
								? 'top-48'
								: ''
						)}
					>
						<div
							ref={containerRef}
							className={cn('w-full', {
								'hidden md:block': hideTravelPlan,
							})}
						>
							<TravelPlanWidget
								ref={observe}
								onContinue={onContinue}
								hideMobileDialog={hideTravelPlan}
								category={category ?? 'flight-fill'}
								continueIsDisabled={continueIsDisabled}
								disablePreviewFetch={disablePreviewFetch}
								isLoading={loading}
							>
								{renderTravelPlan()}
							</TravelPlanWidget>
						</div>
					</div>
				</div>

				{renderFooterContent && (
					<div className="w-full bg-white border border-b-0 border-lighter-grey border-x-0">
						<div className="px-5 mx-auto max-w-screen-2xl 3xl:px-0">
							{renderFooterContent()}
						</div>
					</div>
				)}
			</PageWrapper>
			<div
				className={cn('sticky bottom-0 left-0 z-30 w-full md:hidden', {
					hidden: hideTravelPlan,
				})}
			>
				<TravelPlanWidget
					onContinue={onContinue}
					hideMobileDialog={hideTravelPlan}
					category={category ?? 'flight-fill'}
					continueIsDisabled={continueIsDisabled}
					disablePreviewFetch={disablePreviewFetch}
					isLoading={loading}
				>
					{renderTravelPlan()}
				</TravelPlanWidget>
			</div>
		</div>
	);
}

BookingPageWrapper.propTypes = {
	title: PropTypes.string.isRequired,
	error: PropTypes.object,
	loading: PropTypes.bool,
	onContinue: PropTypes.func,
	loadingText: PropTypes.string,
	hideTravelPlan: PropTypes.bool,
	disablePreviewFetch: PropTypes.bool,
	previousPage: PropTypes.shape({
		to: PropTypes.string,
		name: PropTypes.string,
	}),
	children: PropTypes.node.isRequired,
	steps: PropTypes.arrayOf(
		PropTypes.shape({
			to: PropTypes.string,
			name: PropTypes.string,
			search: PropTypes.string,
		})
	),
	category: PropTypes.oneOf([
		'flights',
		'holidays',
		'hotels',
		'motorhome-hire',
		'car-hire',
	]).isRequired,
	contentClassName: PropTypes.string,
	titleWrapperClassName: PropTypes.string,
	childrenClassName: PropTypes.string,
	pageHeaderClassName: PropTypes.string,
	renderFooterContent: PropTypes.func,
	renderTravelPlan: PropTypes.func,
	handleCancelRequest: PropTypes.func,
	portalData: PropTypes.object,
};

BookingPageWrapper.defaultProps = {
	error: null,
	loading: false,
	loadingText: '',
	hideTravelPlan: false,
	disablePreviewFetch: false,
	steps: [],
	portalData: {},
};

export default BookingPageWrapper;
