import { Fragment, Suspense, lazy, useEffect, useMemo, useRef } from 'react';

import {
	Text,
	Alert,
	Dialog,
	Button,
	Skeleton,
	LoopWrapper,
	NullTerniaryWrapper,
} from '@components/common';
import { cn } from '@/lib/utils';
import { useBreakpoint, useSeatAssign } from '@/hooks';
import { useBookingStore } from '@/store';
import {
	SmallFlightCard,
	AssignedSeats,
	AssignedSeatsPreview,
} from '@/components/flights';
import { useSeatSelection } from '@/context';

const SeatSelection = lazy(() =>
	import('@/components/flights/organisms/seat-selection')
);

function SeatsFlightCard(props) {
	const {
		type,
		flight,
		category,
		flightIndex,
		totalFlights,
		useParentFlight = true
	} = props;

	/**
	 * @type {React.Ref<import('@/components/common/atoms/dialog').refProps>}
	 */
	const dialogRef = useRef(null);
	const isMobile = useBreakpoint('md');

	const {
		error,
		setError,
		passengers,
		getTemplate,
		setSelectedFlight,
		selectedFlight: selected,
	} = useSeatSelection();

	const value = `${flight?.flightNumber}-${type}-${flight?.aircraft}`;

	const sector = flight?.flightNumber;

	// get the template
	const template = getTemplate(flight?.template);

	const { setBookingState } = useBookingStore();
	const bookingState = useBookingStore((state) => state[category]);

	// open the card if the flight is selected
	const openSector = () => {
		const newState = {
			bookingDisabled: true,
		};
		setSelectedFlight(value);
		setBookingState(category, newState, 'DISABLE_BOOKING_FLOW');
	};

	// close card
	const closeSector = () => {
		if (keepSectorOpen) return;

		setSelectedFlight(null);
		const newState = {
			bookingDisabled: false,
		};
		setBookingState(category, newState, 'ENABLE_BOOKING_FLOW');
	};

	const toggleSector = () => {
		if (selected === value) {
			closeSector();
		} else {
			openSector();
		}
	};

	// check if the sector is open
	const isOpen = useMemo(() => {
		// if the screen is small return false
		if (!selected || isMobile) return false;

		return selected === value;
	}, [selected, isMobile, value]);

	//selected seats
	const confirmedSeats =
		bookingState?.selectedSeats?.[type]?.[sector]?.seats || [];

	// assigned seats
	const { assignedSeats, assignSeat, unassignSeat, unassignAll } =
		useSeatAssign(passengers, confirmedSeats);

	const hasOptionPlus = useMemo(() => {
		const items =
			category === 'holidays'
				? bookingState?.selected?.items?.[0].items
				: bookingState?.selected?.items;
		const flights = items?.filter((item) => item?.type === 'flight');

		if (!flights) return false;

		// reduce to get the optionPlus for each type
		const optionPlus = flights.reduce((acc, item) => {
			if (item[type]) {
				acc = item[type]?.hasOptionPlus || false;
			}
			return acc;
		}, false);

		return optionPlus;
	}, [bookingState?.selected?.items, type]);

	// update booking state with selected seats
	const updateBookingState = (seats) => {
		if (!seats?.length) return;

		const newState = {
			shouldBuildParams: true,
			bookingDisabled: false,
			selectedSeats: {
				...bookingState?.selectedSeats,
				[type]: {
					...bookingState?.selectedSeats?.[type],
					[sector]: {
						...bookingState?.selectedSeats?.[type]?.[sector],
						seats,
						gateway: flight?.gateway,
					},
				},
			},
		};

		setBookingState(category, newState, 'SET_SELECTED_SEATS');
	};

	// check if seat is selected
	const seatSelected = (stNum) => {
		const seat = assignedSeats.find((st) => st?.seat === stNum);
		if (!seat) return false;
		return true;
	};

	// unassign seat & update state
	const unassignAndUpdate = (seatNo) => {
		if (!seatNo) return;

		// check if the seat is confirmed
		const isBooked = confirmedSeats.find((st) => st?.seat === seatNo);

		// if the seat is booked remove it from the confirmed seats and update the sector
		if (isBooked) {
			const remainingSeats = assignedSeats.filter((st) => st?.seat !== seatNo);
			let newSector = {
				...bookingState?.selectedSeats?.[type]?.[sector],
			};

			if (remainingSeats.length === 0) {
				newSector = undefined;
			} else {
				newSector = {
					...newSector,
					seats: remainingSeats,
					gateway: flight?.gateway,
				};
			}

			// new seat state
			const newState = {
				shouldBuildParams: true,
				bookingDisabled: false,
				selectedSeats: {
					...bookingState?.selectedSeats,
					[type]: {
						...bookingState?.selectedSeats?.[type],
						[sector]: newSector,
					},
				},
			};

			setBookingState(category, newState, 'UNASSIGN_SEAT');
		}

		unassignSeat(seatNo);
	};

	// handle seat selection
	const onSelected = ({ seat, cells }) => {
		if (!seat) return;

		// current seat index
		const currentIndex = cells.findIndex(
			(cell) => cell?.seatNumber === seat?.seatNumber
		);

		// get next and prev seat
		const nextSeat = cells[currentIndex + 1];
		const next2seat = cells[currentIndex + 2];
		const prevSeat = cells[currentIndex - 1];
		const prev2seat = cells[currentIndex - 2];

		// check availability
		const nextAvailable = nextSeat?.available;
		const prevAvailable = prevSeat?.available;

		// check prev and next seat selected
		const prevSelected = seatSelected(prevSeat?.seatNumber);
		const nextSelected = seatSelected(nextSeat?.seatNumber);

		const isAssigned = assignedSeats.find(
			(item) => item?.seat === seat?.seatNumber
		);

		if (isAssigned) {
			// check current seat is a middleSeat && if next and prev seat a selected
			if (seat?.isMiddleSeat) {
				if (prevSelected && nextSelected) {
					setError(
						'You can not unselect the middle seat leaving the other two seats selected'
					);
					return;
				}
			}

			// unassign seat
			unassignAndUpdate(seat?.seatNumber);
			return;
		}

		if (assignedSeats.length >= passengers.length) {
			setError('You can not select more seats than passengers');
			return;
		}

		// You can not book the middle seat leaving the other two seats empty
		if (seat?.isMiddleSeat && nextAvailable && prevAvailable) {
			if (!prevSelected && !nextSelected) {
				const error =
					'You can not book the middle seat leaving the other two seats empty';
				setError(error);
				return;
			}
		}

		const error =
			'You cannot select seats leaving a single seat in-between unselected';

		// seat window on the right
		if (seat?.isWindowSeat && !nextSeat) {
			// check prev2Seats
			if (prevSeat?.isMiddleSeat && !seatSelected(prevSeat?.seatNumber)) {
				if (seatSelected(prev2seat?.seatNumber)) {
					setError(error);
					return;
				}
			}
		}

		// seat window on the left
		if (seat?.isWindowSeat && !prevSeat) {
			// check next2Seats
			if (nextSeat?.isMiddleSeat && !seatSelected(nextSeat?.seatNumber)) {
				if (seatSelected(next2seat?.seatNumber)) {
					setError(error);
					return;
				}
			}
		}

		// check aisle seat
		if (prevSeat?.type === 'aisle') {
			if (nextSeat?.isMiddleSeat && !seatSelected(nextSeat?.seatNumber)) {
				if (seatSelected(next2seat?.seatNumber)) {
					setError(error);
					return;
				}
			}
		}

		// check aisle seat
		if (nextSeat?.type === 'aisle') {
			if (prevSeat?.isMiddleSeat && !seatSelected(prevSeat?.seatNumber)) {
				if (seatSelected(prev2seat?.seatNumber)) {
					setError(error);
					return;
				}
			}
		}
		// assign seat
		assignSeat(seat);
	};

	// clear booking state seats
	const clearSeatState = (key, sector) => {
		if (key && sector) {
			const newState = {
				shouldBuildParams: true,
				bookingDisabled: false,
				selectedSeats: {
					...bookingState?.selectedSeats,
					[key]: {
						...bookingState?.selectedSeats?.[key],
						[sector]: undefined,
					},
				},
			};

			setBookingState(category, newState, 'CLEARED_FLIGHT_SEATS');
		} else {
			const newState = {
				shouldBuildParams: true,
				bookingDisabled: false,
				selectedSeats: undefined,
			};
			setBookingState(category, newState, 'CLEARED_ALL_SEATS');
		}
	};

	const handleContinueWithoutSeats = () => {
		// check if passenger has optionPlus. If so setError to inform user that seat selection is required
		if (hasOptionPlus && !assignedSeats?.length) {
			setError('Seat selection is required');
			return;
		}

		// clear seat state - this will update the preview params
		if (assignedSeats?.length > 0) {
			unassignAll();
		}

		if (confirmedSeats?.length > 0) {
			clearSeatState(type, sector);
		}

		// close sector
		// setKeepOpen(false);

		if (isMobile) {
			dialogRef.current?.onClose();
		} else {
			closeSector();
		}
	};

	const confirmSeats = () => {
		if (!assignedSeats?.length) return;

		if (hasOptionPlus && assignedSeats.length < passengers.length) {
			setError('Seat selection is required for all passengers');
			return;
		}

		// update bookingState
		updateBookingState(assignedSeats);

		if (isMobile) {
			dialogRef.current?.onClose();
		} else {
			closeSector();
		}
	};

	useEffect(() => {
		if (error) {
			const timer = setTimeout(() => {
				setError(null);
			}, 6000);
			return () => clearTimeout(timer);
		}
	}, [error]);

	const { keepSectorOpen, confirmDisabled } = useManageSector({
		passengers,
		hasOptionPlus,
		assignedSeats,
		sector,
		type,
		selected,
		category,
	});

	const seatsAreRequired = useMemo(() => {
		const required =
			flight?.isSeatResPossible && hasOptionPlus && !confirmedSeats?.length;
		return required;
	}, [hasOptionPlus, confirmedSeats?.length, flight?.isSeatResPossible]);

	useEffect(() => {
		const newState = { bookingDisabled: seatsAreRequired };
		const text = seatsAreRequired
			? 'DISABLE_BOOKING_FLOW'
			: 'ENABLE_BOOKING_FLOW';
		setBookingState(category, newState, text);
	}, [seatsAreRequired, bookingState?.bookingDisabled]);

	// validate
	const seatsConfirmed = useMemo(() => {
		if (!assignedSeats?.length) return false;

		// check that seats are confirmed
		const confirmed = assignedSeats.every((seat) =>
			confirmedSeats.find((st) => st?.seat === seat?.seat)
		);

		return confirmed;
	}, [assignedSeats, confirmedSeats]);

	const disabled = !flight?.isSeatResPossible || !template;

	const selectedItems =
		category === 'holidays'
			? bookingState?.selected?.items?.[0].items
			: bookingState?.selected?.items;
	const parentFlight = selectedItems?.find((item) => item?.[type])?.[type];

	if (!flight) return null;

	return (
		<div
			key={isOpen ? 'open' : 'closed'}
			data-state={isOpen ? 'open' : 'closed'}
			className="border-none group aria-disabled:pointer-events-none"
		>
			<SmallFlightCard
				aria-disabled={disabled}
				flight={useParentFlight ? parentFlight : flight}
				className="group-data-[state=open]:border-t-4 group-data-[state=open]:border-t-core-light-blue group-data-[state=open]:border-b-0 relative z-10"
				renderTitle={() => {
					return (
						<Text className="flex items-center gap-1 text-lg font-bold capitalize">
							<span>{type === 'inbound' ? 'Return' : 'Outbound'} Flight</span>
							<span className="lowercase">
								({flightIndex + 1} of {totalFlights})
							</span>
						</Text>
					);
				}}
			>
				<div
					className={cn('flex flex-col gap-3 w-full items-start xl:items-end', {
						'xl:items-end': disabled,
					})}
				>
					{disabled && <Text>Not Available</Text>}

					{!disabled && assignedSeats?.length > 0 && (
						<AssignedSeatsPreview
							assignedSeats={assignedSeats}
							keepSectorOpen={keepSectorOpen}
							changeHandler={() => {
								if (!seatsConfirmed && isOpen) return;

								if (isMobile) {
									dialogRef.current?.onOpen();
								} else {
									toggleSector();
								}
							}}
						/>
					)}
					{!disabled && !assignedSeats?.length && !isMobile && (
						<Button
							hideIcon
							onClick={() => {
								if (!seatsConfirmed && isOpen) return;
								toggleSector();
							}}
							disabled={keepSectorOpen}
							variant={isOpen ? 'unstyled' : 'outline'}
							label={isOpen ? 'No selected seats' : 'Select Seats'}
						/>
					)}
					<Dialog
						as="drawer"
						hideCloseBtn
						ref={dialogRef}
						position="bottom"
						contentClassName="min-h-[80vh] max-h-[100dvh] h-full w-full p-0"
						renderTrigger={({ DialogTrigger, onOpen }) => (
							<DialogTrigger asChild>
								<Button
									hideIcon
									label="Select Seats"
									variant="outline"
									onClick={onOpen}
									className={cn('mt-2 w-full md:hidden', {
										hidden: disabled,
									})}
								/>
							</DialogTrigger>
						)}
					>
						{({ CloseButton, onClose }) => (
							<div className="relative h-full max-h-screen overflow-y-auto">
								<div className="sticky top-0 left-0 z-10 flex items-center justify-between w-full px-4 py-2 bg-white border-b border-b-lighter-grey">
									<Text className="text-3xl font-bold text-core-blue">
										Select Seats
									</Text>

									<CloseButton
										aria-label="Close"
										variant="square"
										className="relative top-0 right-0 w-12 h-12"
									/>
								</div>
								{error && (
									<div className="px-4">
										<Alert
											variant="destructive"
											title="Houston, we have a problem!"
											subtitle="Apologies, we have encountered an error with your request. Please <a href='mailto:enquiries@canadianaffair.com?subject=Website%20Error%20Query' target='_top' class='underline underline-offset-2'>email us</a> for assistance."
											showHtmlSubtitle={true}
											className="text-white bg-core-red"
										>
											{error}
										</Alert>
									</div>
								)}
								<div className="flex flex-col w-full p-5 min-h-max">
									<Suspense
										fallback={
											<Skeleton className="w-full min-h-[50vh] rounded" />
										}
									>
										<SeatSelection
											isMobile={isMobile}
											template={template}
											className="flex-grow"
											handleSelect={onSelected}
											selectedSeats={assignedSeats}
											availability={flight?.availability}
											disabled={bookingState?.previewLoading}
										/>
									</Suspense>
									<SelectedContent
										error={error}
										passengers={passengers}
										confirmSeats={confirmSeats}
										unassignSeat={unassignAndUpdate}
										assignedSeats={assignedSeats}
										hasOptionPlus={hasOptionPlus}
										availability={flight?.availability}
										confirmDisabled={confirmDisabled}
										handleContinueWithoutSeats={handleContinueWithoutSeats}
										category={category}
									/>
								</div>
								<div className="sticky bottom-0 z-10 flex w-full gap-4 p-3 bg-white border-t border-t-lighter-grey">
									<Button
										onClick={() => {
											confirmSeats();
											onClose();
										}}
										className="w-full text-sm md:text-base"
										variant="core-blue"
										disabled={confirmDisabled}
									>
										Confirm Seats
									</Button>
									<Button
										onClick={handleContinueWithoutSeats}
										className={cn(
											'w-full border-none text-sm text-right underline underline-offset-4 md:text-base',
											{
												hidden: hasOptionPlus,
											}
										)}
										variant="unstyled"
										disabled={hasOptionPlus}
									>
										Continue without seat selection
									</Button>
								</div>
							</div>
						)}
					</Dialog>
				</div>
			</SmallFlightCard>

			<NullTerniaryWrapper animate condition={isOpen}>
				<div className="hidden bg-lighter-grey/20 min-h-[70vh] border border-t-0 border-lighter-grey data-[state=open]:p-5 md:block xl:data-[state=open]:p-10 ">
					<div className="hidden w-full grid-cols-1 gap-3 md:grid lg:grid-cols-1 xl:gap-2 xl:grid-cols-5">
						<div className="w-full col-span-3 space-y-3">
							{error && (
								<Alert
									variant="destructive"
									title="Houston, we have a problem!"
									showHtmlSubtitle={true}
									subtitle="Apologies, we have encountered an error with your request. Please <a href='mailto:enquiries@canadianaffair.com?subject=Website%20Error%20Query' target='_top' class='underline underline-offset-2'>email us</a> for assistance."
									className="my-2 text-white bg-core-red xl:hidden"
								>
									{error}
								</Alert>
							)}
							<Suspense fallback={<div className="w-full h-full" />}>
								<SeatSelection
									template={template}
									className="h-full w-fit"
									handleSelect={onSelected}
									selectedSeats={assignedSeats}
									availability={flight?.availability}
									disabled={bookingState?.previewLoading}
								/>
							</Suspense>
						</div>
						<div className="w-full col-span-2 lg:p-10">
							<SelectedContent
								type={type}
								sector={sector}
								unassignSeat={unassignAndUpdate}
								assignedSeats={assignedSeats}
								confirmSeats={confirmSeats}
								hasOptionPlus={hasOptionPlus}
								confirmedSeats={confirmedSeats}
								handleContinueWithoutSeats={handleContinueWithoutSeats}
								confirmDisabled={confirmDisabled}
								availability={flight?.availability}
								category={category}
							/>
						</div>
					</div>
				</div>
			</NullTerniaryWrapper>
		</div>
	);
}

function SelectedContent({
	confirmSeats,
	unassignSeat,
	availability,
	assignedSeats,
	hasOptionPlus,
	confirmedSeats,
	confirmDisabled,
	handleContinueWithoutSeats,
	category,
}) {
	const { error, passengers } = useSeatSelection();
	const state = useBookingStore((state) => state[category]);

	const handleUnassignSeat = (seat) => {
		if (!seat) return;

		// find the cell where the seat is located and click it as it is a button
		const cell = document.getElementById(seat?.seat);
		if (!cell) return;

		// clicking will trigger the unassign action in the handle select function
		cell.click();

		// check if the seat is in the booked seats in state and trigger a new preview without the seat
		const isBooked = confirmedSeats.find((st) => st?.seat === seat?.seat);
		if (isBooked) {
			unassignSeat(seat?.seat);
		}
	};
	return (
		<Fragment>
			{error && (
				<Alert
					variant="destructive"
					title="Houston, we have a problem!"
					subtitle="Apologies, we have encountered an error with your request. Please <a href='mailto:enquiries@canadianaffair.com?subject=Website%20Error%20Query' target='_top' class='underline underline-offset-2'>email us</a> for assistance."
					showHtmlSubtitle={true}
					className="hidden my-2 text-white bg-core-red xl:block"
				>
					{error}
				</Alert>
			)}
			<div>
				<LoopWrapper list={passengers} itemKey="firstName">
					{(pax) => (
						<AssignedSeats
							pax={pax}
							disableClear={state?.previewLoading}
							unassignSeat={handleUnassignSeat}
							assignedSeats={assignedSeats}
							selectionRequired={hasOptionPlus}
							availability={availability}
						/>
					)}
				</LoopWrapper>

				<div className="my-2">
					<Text variant="error" className="font-bold">
						Important
					</Text>
					<Text>
						You can not select ONLY ONE seat in the middle of the aisle
					</Text>
				</div>
			</div>
			<div className="p-3 bg-supporting-yellow/40 text-dark-grey">
				<Text>
					You have selected:{' '}
					<strong>
						{assignedSeats?.length || 0} of {passengers?.length || 0} seats
					</strong>
				</Text>
			</div>
			<div className="items-center justify-between hidden w-full gap-2 my-4 md:flex">
				<Button
					hideIcon
					variant="core-blue"
					label="Confirm Seats"
					onClick={confirmSeats}
					className="w-fit min-w-[50%] px-2"
					disabled={confirmDisabled}
				/>
				<Button
					hideIcon
					variant="unstyled"
					disableAnimation
					disabled={hasOptionPlus}
					labelClassName="text-right text-sm"
					onClick={handleContinueWithoutSeats}
					label="Continue without seat selection"
					className={cn('underline underline-offset-4 w-1/3', {
						hidden: hasOptionPlus,
					})}
				/>
			</div>
		</Fragment>
	);
}

// custom hook to manage the sector (disable confirm button, keep sector open)
function useManageSector({
	type,
	sector,
	selected,
	hasOptionPlus,
	assignedSeats,
	passengers,
	category,
}) {
	// keep open when - we have unconfirmed seats, keep open if seats are required ( hasOptionPlus )
	const state = useBookingStore((state) => state[category]);
	const confirmedSeats = state?.selectedSeats?.[type]?.[sector]?.seats || [];

	const seatsChanged = useMemo(() => {
		// if no selected seats return false
		if (!assignedSeats?.length || !confirmedSeats?.length) return false;

		// check if the seats are the same
		const confirmed = confirmedSeats.every((seat) =>
			assignedSeats.find((st) => st?.seat === seat?.seat)
		);
		return confirmed ? false : true;
	}, [assignedSeats, confirmedSeats]);

	const keepSectorOpen = useMemo(() => {
		// keep open if seats are required
		if (selected && hasOptionPlus && !assignedSeats?.length) return true;

		// keep open if seats have changed and are not confirmed
		if (seatsChanged) return true;
		return false;
	}, [hasOptionPlus, assignedSeats?.length, selected, seatsChanged]);

	const confirmDisabled = useMemo(() => {
		// if no assigned seats return true
		if (!assignedSeats?.length && !confirmedSeats?.length) return true;

		// if the seats are already confirmed and seats have not changed
		if (confirmedSeats?.length && !seatsChanged) return true;

		// if hasOptionPlus and not all passengers have seats assigned
		if (hasOptionPlus && assignedSeats.length < passengers.length) return true;

		return false;
	}, [
		assignedSeats?.length,
		seatsChanged,
		confirmedSeats?.length,
		hasOptionPlus,
		passengers?.length,
	]);

	return {
		keepSectorOpen,
		confirmDisabled,
	};
}

export default SeatsFlightCard;
