import { useNavigate, useLocation } from 'react-router-dom';
import { getPreviousStep, navigate as goTo } from '@/lib/utils';
import { useBookingStore } from '@/store';
import useSearchSave from './useSearchSave';
import qs from 'qs';

/**
 * @typedef Returns
 * @property {()=> void} handleBack
 * @property {import('@/lib/steps').step} previousStep
 */

/**
 * @name useBackNavigate
 * @description custom hook to handle back navigation
 * @param {import('@/lib/steps').step[]} steps
 * @param {"flights" | "hotels" | "carhire" | "motorhome"} [category]
 * @returns {Returns} Returns
 * @example
 * const { handleBack, previousStep } = useBackNavigate(steps, 'flights');
 */

function useBackNavigate(steps = [], category) {
	const { pathname } = useLocation();
	const sessionSteps = JSON.parse(sessionStorage.getItem('breadcrumbs'));
	const state = useBookingStore((store) => store[category]);

	const { getSearch } = useSearchSave();
	const savedSearch = getSearch(category);

	// use session steps if available else use steps
	let processSteps = sessionSteps ? sessionSteps : steps;

	const previousStep = getPreviousStep(processSteps, pathname);
	const isSearch = previousStep?.to.includes('/search');

	const navigate = useNavigate();

	const handleBack = () => {
		// check if navigation step includes /search
		if (!previousStep) {
			goTo('/');
			return;
		}

		// check if search string starts with ? and remove it
		let url = previousStep?.to;

		// handle back navigation for hotel page
		if (category === 'hotels' && previousStep?.to.includes('/search')) {
			// check if the current previous page is the search page is
			let hotelPage = '';

			//get the selected hotel url from the state
			const accommodationItem = state?.preview?.breakdown.filter(
				(i) => i.type === 'accommodation'
			)[0];
			if (accommodationItem?.url) {
				const sortedSavedSearch = qs.stringify({
					...qs.parse(savedSearch),
					tab: 'hotels',
				});
				hotelPage =
					savedSearch && savedSearch !== 'undefined'
						? `${accommodationItem?.url}?${sortedSavedSearch}`
						: `${accommodationItem?.url}?tab=hotels`;
			}

			if (hotelPage) {
				goTo(hotelPage);
			} else {
				if (savedSearch) {
					const url = `${previousStep?.to}?${savedSearch}`;
					goTo(url);
				}
			}

			return;
		}

		if (category === 'motorhome-hire' && previousStep?.to.includes('/search')) {
			if (savedSearch) {
				const url = `${previousStep?.to}?${savedSearch}`;
				goTo(url);
			}
			return;
		}

		if (previousStep?.search) {
			url = previousStep?.search.startsWith('?')
				? `${previousStep?.to}${previousStep?.search}`
				: `${previousStep?.to}?${previousStep?.search}`;
		}

		if (isSearch) {
			goTo(url, { from: pathname });
		} else {
			navigate(url, { state: { from: pathname } });
		}
	};
	return { handleBack, previousStep };
}

export default useBackNavigate;
