import qs from 'qs';
import { Heading, Icon, Text, Badge, Button } from '@/components/common';
import pluralize from 'pluralize';
import { cn, currencyFormatter } from '@/lib/utils';
import { useMemo } from 'react';
import { useUrlParams } from '@/hooks';

function MotorHomeCard({ result, className, variant }) {
	const { params } = useUrlParams('motorhomehire_filters');

	const bookingUrl = useMemo(() => {
		if (!result?.url) return null;

		const {
			startDate,
			endDate,
			dropoff,
			pickup,
			category,
		} = params.default || {};

		const bookingParams = {
			tab: 'motorhome-hire',
			category: category === 'motorhome-hire' ? 'motorhome-hire' : undefined,
			startDate,
			endDate,
			pickup: pickup,
			dropoff: dropoff,
			pickupLocation: pickup ? pickup.split(':')[0] : null,
			dropoffLocation: dropoff ? dropoff.split(':')[0] : null,
			priceFrom: result.price || result.pricePerPerson,
			code: result?.code,
			typeCode: result?.typeCode,
			subTypeCode: result?.subTypeCode,
			pickupDepot: result?.pickup?.code,
			dropoffDepot: result?.dropoff?.code,
		};

		return `${result.url}?${qs.stringify(bookingParams)}`;
	}, [result?.url, params?.default]);

	return (
		<div
			className={cn(
				'h-auto w-full grid grid-cols-1 gap-4 bg-white rounded-lg shadow-md overflow-hidden lg:grid-cols-3 lg:shadow-none lg:rounded-none border-b pb-5 lg:gap-6',
				className
			)}
		>
			<div className="w-full col-span-1 h-full max-h-[30vh] md:max-h-[40vh] lg:max-h-full lg:h-full">
				<img
					className="self-stretch object-contain w-full h-full"
					src={result.image}
					alt={result?.displayName || result?.name}
				/>
			</div>
			<div
				className={cn(
					'w-full grid-cols-1 px-6 pb-8 bg-white flex flex-col gap-2 justify-center items-start lg:col-span-2 lg:pb-0 lg:my-10'
				)}
			>
				<div className={cn('flex flex-col-reverse gap-2 lg:flex-col')}>
					{result?.berths ? (
						<Badge
							text={`Sleeps ${result.berths}`}
							variant="light-grey"
							textClassName="font-bold"
						/>
					) : null}
					<Heading as="h3" className="self-stretch font-bold text-core-blue ">
						{result.displayName}
					</Heading>
				</div>
				{result?.highlights?.length > 0 && (
					<div className="flex flex-wrap items-center w-full gap-2 px-4 py-2 rounded-md lg:gap-x-4 bg-light-grey md:w-fit">
						{result.highlights.map((highlight) =>
							!highlight?.title ? null : (
								<div
									className="flex items-center justify-start w-full gap-1 lg:w-fit"
									key={highlight?.id}
								>
									{highlight?.image && (
										<img
											className="w-5"
											src={highlight.image}
											alt={`${highlight?.title} icon`}
										/>
									)}
									<Text className="text-sm leading-tight opacity-75 text-grey-dark">
										{highlight.title}
									</Text>
								</div>
							)
						)}
					</div>
				)}
				{result?.introtext && (
					<Text className="self-stretch text-base font-normal leading-tight opacity-75 text-dark-grey line-clamp-4">
						{result.introtext}
					</Text>
				)}
				<div className="flex flex-col items-start self-stretch justify-start h-auto gap-2 pb-2.5">
					{(result?.price > 0 ||
						result?.pricePerPerson > 0 ||
						result?.duration) && (
						<>
							<div className="flex flex-col items-start justify-start gap-1">
								{(result?.price > 0 || result?.pricePerPerson > 0) && (
									<Text
										variant="muted"
										className="text-xs font-bold uppercase leading-loose-snug tracking-less-tight"
									>
										{result?.price > 0 ? 'Total' : ''} From
									</Text>
								)}
								<div className="flex items-start justify-start gap-10">
									{(result?.price > 0 || result?.pricePerPerson > 0) && (
										<div className="flex items-center gap-1">
											<Text
												variant="bold"
												className="text-2.5xl leading-extra-tight font-bold tracking-tight"
											>
												{currencyFormatter({
													amount: result.price || result.pricePerPerson,
												})}
											</Text>

											{!result.price && result.pricePerPerson > 0 && (
												<div>
													<Text
														as="small"
														variant="muted"
														className="hidden text-base font-medium leading-none"
													>
														per person
													</Text>
													<Text
														as="small"
														variant="bold"
														className="text-base font-medium leading-none"
													>
														pp
													</Text>
												</div>
											)}
										</div>
									)}
									{result.duration > 0 && (
										<div className="flex items-center gap-2">
											<Icon name="moon" />
											<Text
												variant="bold"
												className="text-2.5xl leading-extra-tight font-bold tracking-tight"
											>
												{result.duration} {pluralize('night', result.duration)}
											</Text>
										</div>
									)}
								</div>
							</div>
							{result.priceNotice && (
								<Text
									as="small"
									variant="muted"
									className="leading-snug tracking-tighter text-lightest-grey/50"
								>
									{result.priceNotice}
								</Text>
							)}
						</>
					)}
					<div className="flex flex-col">
						{result?.berths ? (
							<Text
								as="small"
								variant="muted"
								className="leading-snug tracking-tighter text-lightest-grey/50"
							>
								Sleeping Summary: {result.berths} People
							</Text>
						) : null}
						{result.bedSummary && (
							<Text
								as="small"
								variant="muted"
								className="leading-snug tracking-tighter text-lightest-grey/50"
							>
								Bed Summary: {result.bedSummary}
							</Text>
						)}
					</div>
				</div>
				{bookingUrl ? (
					<Button
						label="View & Book"
						variant="core-blue"
						tagName="a"
						href={bookingUrl}
						className="flex items-center gap-4"
					/>
				) : null}
				{result?.localCharge > 0 && (
					<div className="inline-flex items-start justify-start gap-2.5 pt-3">
						<div>
							<span className="text-base font-bold leading-tight text-slate-800">
								Important:{' '}
							</span>
							<span className="text-base font-normal leading-tight text-slate-800">
								One way fee/local charges{' '}
								{currencyFormatter({
									amount: result.localCharge,
									currency: 'CAD',
									currencyDisplay: 'code',
								})}{' '}
								plus tax (payable locally).
							</span>
						</div>
					</div>
				)}
			</div>
		</div>
	);
}
export default MotorHomeCard;
