import { useFormContext, useFormState } from 'react-hook-form';

import { cn } from '@/lib/utils';
import { FormField, Heading } from '@components/common';

function PreferredContactForm({ className }) {
	const { control } = useFormContext();
	const { errors } = useFormState({ control });
	return (
		<div className={cn('w-full flex flex-col gap-3', className)}>
			<Heading
				as="h3"
				className="text-base font-bold leading-snug tracking-tighter md:text-lg font-body"
			>
				Preferred Contact Method
			</Heading>
			<FormField
				name="marketing.email"
				label="Email"
				as="toggle-group"
				control={control}
				options={[
					{
						label: 'No thanks',
						value: 'no',
					},
					{
						label: 'Yes please',
						value: 'yes',
					},
				]}
				onChange={(field, value) => {
					if (!value || value === '') return;
					field.onChange(value);
				}}
				errors={errors}
			/>
			<FormField
				name="marketing.phone"
				label="Phone"
				as="toggle-group"
				control={control}
				options={[
					{
						label: 'No thanks',
						value: 'no',
					},
					{
						label: 'Yes please',
						value: 'yes',
					},
				]}
				onChange={(field, value) => {
					if (!value || value === '') return;
					field.onChange(value);
				}}
				errors={errors}
			/>
		</div>
	);
}

export default PreferredContactForm;
