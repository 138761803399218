import { useMemo, useEffect } from 'react';
import { ErrorBoundary } from 'react-error-boundary';

import useBookingStore from '@/store/useBookingStore';
import useValueCallback from '@/hooks/useValueCallback';
import Text from '@/components/common/atoms/text';
import ErrorHandler from '@/components/common/molecules/error-handler';
import CarExtrasDetails from '@components/carhire/molecules/extras-details';
import { currencyFormatter } from '@/lib/utils/currency';
import { formatLocalTime } from '@/lib/utils/date';

/**
 * @name TravelPlanDetails
 * @description A component that shows the travel plan details for motorhome hire booking process
 * @returns {React.JSX.Element}
 */

function TravelPlanDetails({
    isLoading,
    handleTotalPrice,
    handleShowSaveForLater,
}) {
    const state = useBookingStore((state) => state['motorhome-hire']);

    const totalCost = useMemo(() => {
        if (!state?.preview?.overview?.total) return 0;

        return state?.preview?.overview?.total;
    }, [state?.preview?.overview?.total]);

    useEffect(() => {
        if (handleTotalPrice) handleTotalPrice(totalCost);
    }, [totalCost, handleTotalPrice]);

    const itemExtras = useMemo(() => {
        if (!state?.preview?.breakdown) return [];

        // hide while loading
        if (state?.previewLoading) return [];

        return state.preview.breakdown.filter((breakdown) => (
            breakdown?.type === 'item' &&
            breakdown?.parentProductType === 'motorhomehire'
        ));
    }, [state?.preview?.breakdown, state?.previewLoading]);

    const totalItemCost = useMemo(() => {
        if (!itemExtras?.length) return 0;

        return itemExtras.reduce(
            (total, extra) => total + (extra?.total || 0),
            0
        );
    }, [itemExtras]);

    // determine whether to show save for later
    useValueCallback(
        typeof handleShowSaveForLater === 'function'
            ? (newPreview) => {
                if (typeof newPreview?.quotable !== 'undefined') {
                    handleShowSaveForLater(newPreview.quotable === true);
                }
            }
            : null,
        state?.preview
    );

    const { vehicle, pickup, dropoff } = useMemo(() => {
        if (!state?.preview?.breakdown) return {};

        const motorhome = state.preview.breakdown.find(
            (item) => item?.type === 'motorhomehire'
        );
        return {
            vehicle: motorhome,
            pickup: {
                date: motorhome?.startDate,
                time: motorhome?.pickupTime ?
                    formatLocalTime(`T${motorhome?.pickupTime}`) :
                    null,
                name: motorhome?.pickupName,
            },
            dropoff: {
                date: motorhome?.endDate,
                time: motorhome?.dropoffTime ?
                    formatLocalTime(`T${motorhome?.dropoffTime}`) :
                    null,
                name: motorhome?.dropoffName,
            },
        }
    }, [state?.preview?.breakdown]);

    return (
        <ErrorBoundary FallbackComponent={ErrorHandler}>
            <CarExtrasDetails
                productType="Motorhome"
                totalCost={totalCost}
                car={vehicle}
                isLoading={isLoading || state?.previewLoading}
                pickup={pickup}
                dropoff={dropoff}
                itemExtras={itemExtras}
                error={state?.error}
                hideInsuranceType
                showItemExtraPrice
                renderVehicleName={() => vehicle?.displayName || vehicle?.name || '-'}
                renderExtrasTitle={() => (
                    <>
                        <Text as="span" className="font-semibold tracking-normal">
                            Extras
                        </Text>

                        {totalItemCost > 0 ? (
                            <Text as="span" className="font-semibold tracking-normal">
                                {currencyFormatter({
                                    amount: totalItemCost,
                                })}
                            </Text>
                        ) : null}
                    </>
                )}
            />
        </ErrorBoundary>
    );
}
export default TravelPlanDetails;
