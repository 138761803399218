import React, { Suspense, useEffect, useState, useRef } from 'react';
import PropTypes from 'prop-types';
import {
	Accordion,
	AccordionTrigger,
	AccordionContent,
	AccordionItem,
	RadioGroup,
} from '@components/common';
import MapListCard from '../../molecules/MapListCard';
import Card from '../../molecules/card';
import MapListCardSkeleton from '../../molecules/MapListSkeleton';
import FilterCard from '../../molecules/filter-card';

const MapListItem = React.forwardRef(
	(
		{
			results,
			fetching,
			selectedOption,
			carTypeFilter,
			handleOptionSelect,
			handleCarSelect,
			showOnMap,
		},
		ref
	) => {
		const cardRef = useRef(null);
		// scroll to selected item when selected changes
		const [selectedCar, setSelectedCar] = useState(null);

		useEffect(() => {
			// reset selected car if it's excluded by the car type filter
			if (!(selectedCar && carTypeFilter?.length)) return;
			if (carTypeFilter.includes(selectedCar?.car?.typeCode)) return;

			setSelectedCar(null);
			handleOptionSelect(null);
		}, [carTypeFilter]);

		useEffect(() => {
			// deselect car when the selected depot changes
			setSelectedCar(null);
		}, [selectedOption?.value]);

		useEffect(() => {
			// scroll to selected item when selected changes
			if (selectedCar && cardRef.current) {
				cardRef.current?.scrollIntoView(false, {
					behavior: 'smooth',
					block: 'start',
					inline: 'start',
				});
			}
		}, [selectedCar]);

		return (
			<Accordion
				ref={ref}
				collapsible
				type="single"
				className="w-full relative"
				value={selectedOption?.value || null}
				onValueChange={(value) => {
					// deselect chosen car
					setSelectedCar(null);

					// handle reset
					if (!value) {
						handleOptionSelect(null);
						return;
					}

					// handle item selection
					const item = results.find((result) => result?.value === value);
					handleOptionSelect(item || null);
				}}
			>
				{results.map((item, i) => {
					if (!item?.value) return;

					// format the cars into an array of objects in format {value: 'code', ...rest}
					const cars =
						item?.cars?.map((car) => ({
							value: car?.code,
							...car,
						})) || [];

					const allCarsHaveUnlimitedMileage = cars.every(
						(car) => car?.maxMileage === 'unlimited'
					);

					return (
						<Suspense
							key={`map-list-item-${i}-${item.value}`}
							fallback={<MapListCardSkeleton />}
						>
							<AccordionItem
								value={item.value}
								className="border-none gap-3"
								id={item.value}
							>
								<AccordionTrigger
									asChild
									hideChevron
									className="w-full px-4 tracking-normal data-[state=open]:shadow-sm"
								>
									<MapListCard
										noOfCars={cars.length}
										pickup={item?.pickup || {}}
										dropoff={item?.dropoff || {}}
										logo={item?.cars[0]?.supplierLogo}
										unlimitedMileage={allCarsHaveUnlimitedMileage}
										isSelected={selectedOption?.value === item.value}
										showOnMap={showOnMap}
									/>
								</AccordionTrigger>
								<AccordionContent className="overflow-y-scroll max-h-[600px] w-full hide-scrollbar px-4 pt-5">
									<RadioGroup
										options={cars}
										showCarousel={cars && cars.length > 1}
										value={selectedCar?.car?.code}
										onChange={(value) =>
											setSelectedCar({
												car: cars.find((car) => car.code === value),
												pickup: item.pickup,
												dropoff: item.dropoff,
											})
										}
										className="flex gap-4 overflow-x-scroll hide-scrollbar"
										fieldWrapperClassName="flex min-w-[150px] max-w-[200px] mx-auto flex-col gap-4"
										renderOptionChildren={(option) => (
											<FilterCard car={option} />
										)}
									/>

									{selectedCar ? (
										<div ref={cardRef} className="my-4">
											<Card
												hideProviderLogo
												car={selectedCar?.car}
												key={selectedCar?.car?.code}
												className="bg-gray-50 mb-4 rounded-none"
												onSelect={() => handleCarSelect(selectedCar)}
											/>
										</div>
									) : null}
								</AccordionContent>
							</AccordionItem>
						</Suspense>
					);
				})}
				{fetching &&
					[...Array(6)].map((_, index) => (
						<MapListCardSkeleton key={`map-card-${index}`} />
					))}
			</Accordion>
		);
	}
);

MapListItem.propTypes = {
	results: PropTypes.array.isRequired,
	fetching: PropTypes.bool.isRequired,
	selectedOption: PropTypes.object,
	handleOptionSelect: PropTypes.func.isRequired,
	handleCarSelect: PropTypes.func.isRequired,
	carTypeFilter: PropTypes.array,
	showOnMap: PropTypes.oneOf(['pickup', 'dropoff']).isRequired,
};

MapListItem.defaultProps = {
	results: [],
	fetching: false,
	selectedOption: {},
	handleOptionSelect: () => {},
	handleCarSelect: () => {},
	showOnMap: 'pickup',
};

export default MapListItem;
