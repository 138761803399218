import pluralize from 'pluralize';
import PropTypes from 'prop-types';

import {
	Text,
	Stack,
	Accordion,
	AccordionItem,
	AccordionContent,
	AccordionTrigger,
	Skeleton,
	NullTerniaryWrapper,
	Alert,
} from '@/components/common';
import {
	currencyFormatter,
	formatLocalDate,
	getInsuranceType,
} from '@/lib/utils';

function CarExtrasDetails({
	car,
	pickup,
	dropoff,
	totalCost,
	isLoading,
	itemExtras,
	packageExtra,
	itemExtrasCost,
	error,
	hideInsuranceType,
	renderVehicleName,
	renderExtrasTitle,
	showItemExtraPrice,
	productType,
}) {
	const packageName = packageExtra?.displayName
		? packageExtra.displayName
		: packageExtra?.name;

	// parse pickup/dropoff date
	const formattedPickupDate = pickup?.date
		? formatLocalDate(pickup?.date, 'dd/MM/yyyy')
		: null;
	const formattedDropoffDate = dropoff?.date
		? formatLocalDate(dropoff?.date, 'dd/MM/yyyy')
		: null;

	const { defaultText } = getInsuranceType(car?.code, !packageExtra);

	return (
		<Stack orientation="vertical">
			<NullTerniaryWrapper condition={!!error} animate>
				<Alert
					className="mb-2"
					variant="destructive"
					title="Houston, we have a problem!"
					subtitle="Apologies, we have encountered an error with your request. Please <a href='mailto:enquiries@canadianaffair.com?subject=Website%20Error%20Query' target='_top' class='underline underline-offset-2'>email us</a> for assistance."
					showHtmlSubtitle={true}
				>
					{error?.message}
				</Alert>
			</NullTerniaryWrapper>

			<div className="flex flex-col gap-2">
				<div className="flex flex-col justify-start  text-dark-grey">
					<Text as="span" className="font-semibold capitalize">
						{productType}:
					</Text>
					<Text as="span" className="">
						{typeof renderVehicleName === 'function' ? renderVehicleName(car) : null}
					</Text>
				</div>
				{!hideInsuranceType ? (
					<div className="flex flex-col justify-start text-dark-grey">
						<Text as="span" className="font-semibold">
							Insurance Type:
						</Text>
						<Text as="span">
							{typeof car !== 'undefined' ? packageName || defaultText : '-'}
						</Text>
					</div>
				) : null}
				<div className="flex flex-col justify-start  text-dark-grey">
					<Text as="span" className="font-semibold">
						Pickup:
					</Text>
					{pickup?.name ? (
						<Text as="span">
							{pickup?.name}

							{formattedPickupDate ? (
								<Text as="span">
									{' '}
									(<Text as="span">{formattedPickupDate}</Text>
									{pickup?.time ? (
										<Text as="span"> at {pickup?.time}</Text>
									) : null}
									)
								</Text>
							) : null}
						</Text>
					) : (
						'-'
					)}
				</div>
				<div className="flex flex-col justify-start  text-dark-grey">
					<Text as="span" className="font-semibold">
						Drop off:
					</Text>
					{dropoff?.name ? (
						<Text as="span">
							{dropoff?.name}

							{formattedDropoffDate ? (
								<Text as="span">
									{' '}
									(<Text as="span">{formattedDropoffDate}</Text>
									{dropoff?.time ? (
										<Text as="span"> at {dropoff?.time}</Text>
									) : null}
									)
								</Text>
							) : null}
						</Text>
					) : (
						'-'
					)}
				</div>
				<div className="flex flex-col justify-start  text-dark-grey">
					<Text as="span" className="font-semibold">
						Duration:
					</Text>
					<Text as="span" className="font-normal">
						{car?.duration ? (
							<Text as="span">
								{car.duration} {pluralize('day', car?.duration)} rental
							</Text>
						) : (
							'-'
						)}
					</Text>
				</div>
			</div>
			{(packageExtra?.code || itemExtras?.length > 0) && (
				<Accordion
					type="single"
					collapsible
					className="flex flex-col w-full gap-4"
				>
					{packageExtra?.code && (
						<AccordionItem className="w-full" value="extras">
							<AccordionTrigger
								asChild
								className="flex-row-reverse items-start justify-end gap-2 transition-all duration-200 ease-in-out cursor-pointer "
							>
								<span className="flex items-center justify-between w-full gap-2">
									<Text
										as="span"
										variant="bold"
										className="font-semibold tracking-normal"
									>
										Extras
									</Text>
									<Text as="span" className="font-semibold tracking-normal">
										{currencyFormatter({ amount: packageExtra.price || 0 })}
									</Text>
								</span>
							</AccordionTrigger>
							<AccordionContent
								value="extras"
								childClassName="flex items-center justify-between gap-2 pb-0 pt-2"
							>
								<Text variant="bold" className="leading-tight text-neutral-800">
									{packageExtra.displayName
										? packageExtra.displayName
										: packageExtra.name}
								</Text>
								{packageExtra.price && (
									<Text className="leading-tight text-neutral-800">
										{currencyFormatter({ amount: packageExtra.price })}
									</Text>
								)}
							</AccordionContent>
						</AccordionItem>
					)}
					{itemExtras?.length > 0 && (
						<AccordionItem value="extras-collection">
							<AccordionTrigger
								asChild
								className="flex-row-reverse items-start justify-end gap-2 transition-all duration-200 ease-in-out text-dark-grey"
							>
								<div className="flex items-center justify-between w-full">
									{typeof renderExtrasTitle === 'function' ? renderExtrasTitle() : null}
								</div>
							</AccordionTrigger>
							<AccordionContent
								value="extras-collection"
								childClassName="flex flex-col gap-4 pb-0 pt-2"
							>
								{itemExtras.map((info) => (
									<div className="flex justify-between" key={info.item?.code || info.code}>
										<Text
											variant="bold"
											className="leading-tight text-neutral-800"
										>
											{info.quantity > 1 && <span>{info.quantity}x </span>}

											{(info.item?.displayName || info.displayName)
												? (info.item?.displayName || info.displayName)
												: (info.item?.name || info.name)}
										</Text>

										<Text className="leading-tight text-right text-neutral-800">
											{showItemExtraPrice ? currencyFormatter({
												amount: info?.total || 0
											}) : 'Payable Locally'}
										</Text>
									</div>
								))}
							</AccordionContent>
						</AccordionItem>
					)}
				</Accordion>
			)}

			<div className="w-full">
				<Stack className="flex-row">
					<Text as="span" className="text-xl font-bold">
						Total
					</Text>
					{isLoading ? (
						<Skeleton className="w-20 h-6" />
					) : (
						<Text as="span" className="text-xl font-bold">
							{totalCost > 0
								? currencyFormatter({ amount: totalCost })
								: 'INCLUDED'}
						</Text>
					)}
				</Stack>
				{itemExtrasCost > 0 && (
					<Stack className="flex-row mt-2">
						<Text as="span" className="font-normal">
							Payable on collection
						</Text>
						<Text as="span" className="font-normal">
							{currencyFormatter({
								amount: itemExtrasCost,
								currency: 'CAD',
							})}
						</Text>
					</Stack>
				)}
			</div>
		</Stack>
	);
}

CarExtrasDetails.propTypes = {
	car: PropTypes.object,
	pickup: PropTypes.object,
	dropoff: PropTypes.object,
	packageExtra: PropTypes.object,
	itemExtras: PropTypes.arrayOf(
		PropTypes.shape({
			quantity: PropTypes.number,
			cost: PropTypes.oneOfType([PropTypes.number, PropTypes.string]),
			item: PropTypes.object,
		})
	),
	itemExtrasCost: PropTypes.number,
	totalCost: PropTypes.number,
	hideInsuranceType: PropTypes.bool,
	renderVehicleName: PropTypes.func,
	renderExtrasTitle: PropTypes.func,
	showItemExtraPrice: PropTypes.bool,
	productType: PropTypes.string,
};

CarExtrasDetails.defaultProps = {
	hideInsuranceType: false,
	showItemExtraPrice: false,
};

export default CarExtrasDetails;
