import PropTypes from 'prop-types';
import React, { useState, useMemo, useEffect } from 'react';
import { yupResolver } from '@hookform/resolvers/yup';
import { FormProvider, useForm, useFormState } from 'react-hook-form';
import { useLocation, useNavigate, useParams } from 'react-router-dom';

import {
	Text,
	Button,
	Heading,
	FormField,
	// ContactForm,
	// DriverDetailsForm,
	BookingPageWrapper,
	// PreferredContactForm,
	ToggleButtonGroup,
	LoopWrapper,
	RteStyledContent,
} from '@/components/common';
import ContactForm from '@/components/common/forms/ContactForm';
import DriverDetailsForm from '@/components/common/forms/DriverDetailsForm';
import PreferredContactForm from '@/components/common/forms/PreferredContactForm';
import { useBookingStore } from '@/store';
import { formatYmd, getNextStep } from '@/lib/utils';
import { driverDetailsSchema } from '@/validationSchemas';
import { getSteps } from '@/lib/steps';
import { useBackNavigate, useMetaDescription, useErrata } from '@/hooks';

/**
 * @typedef {Object} DriverDetailsProps
 */

/**
 * @name DriverDetails
 * @description Renders the DriverDetails page with the driver details form
 * @param {DriverDetailsProps} props Props containing the steps and the category
 * @returns  {React.JSX.Element}
 * @example
 * <DriverDetails steps={steps} />
 */

function DriverDetails() {
	const params = useParams();
	const navigate = useNavigate();
	const { pathname } = useLocation();
	const { setBookingState, submitBooking } = useBookingStore();
	const category = params?.category ? params?.category : pathname.split('/')[2];
	const bookingState = useBookingStore((state) => state[category]);

	let metaKeyword = '';
	switch (category) {
		case 'car-hire':
			metaKeyword = 'Car Hire';
			break;
		case 'motorhome-hire':
			metaKeyword = 'Motorhome Hire';
			break;
	}
	useMetaDescription(['Driver Details', metaKeyword, 'Canadian Affair']);

	const questions = bookingState?.questions || [];

	const [answers, setAnswers] = useState(() => {
		if (!questions?.length) return [];

		if (bookingState?.bookingDetails?.questions?.length) {
			return bookingState?.bookingDetails?.questions;
		}

		return questions.map((question) => ({
			id: question?.id,
			question: question?.question,
			optional: question?.optional,
			answer: {
				id: question?.answers[0]?.id,
				answer: question?.answers[0]?.answer,
			},
		}));
	});

	// run only once on mount
	useEffect(() => {
		// reset terms and conditions & read information
		const checkedConditions =
			bookingState?.driverDetails?.terms_conditions &&
			bookingState?.driverDetails?.read_information;

		if (checkedConditions) {
			const newBookingState = {
				driverDetails: {
					...bookingState.driverDetails,
					terms_conditions: false,
					read_information: false,
				},
				paymentDetails: undefined,
			};
			setBookingState(category, newBookingState);
		}
	}, [category]);

	// get session data if present else return default values
	const defaultValues = useMemo(() => {
		if (!bookingState?.driverDetails) return defaultState;
		return bookingState?.driverDetails;
	}, [bookingState?.driverDetails]);

	const methods = useForm({
		defaultValues,
		mode: 'onBlur',
		resolver: yupResolver(driverDetailsSchema),
	});

	const {
		reset,
		control,
		handleSubmit,
		formState: { errors },
	} = methods;

	useEffect(() => {
		reset(defaultValues);
	}, [defaultValues, reset]);

	const pageSteps = useMemo(() => getSteps(category), [category]);

	const onSubmit = (values) => {
		if (!values) return;

		const dob = formatYmd({
			day: values?.driver?.dob?.day,
			month: values?.driver?.dob?.month,
			year: values?.driver?.dob?.year,
		});
		const title = values?.driver?.title?.value;
		const numberOfPassengers = values?.driver?.number_of_passengers?.value;
		const gender = values?.driver?.gender?.value;

		// get driver details
		const paxDetails = {
			...values?.driver,
			ref: 1, // lead driver is the only passenger (ref #1)
			dob,
			title,
			numberOfPassengers,
			gender,
		};
		// structure our booking data
		const bookingDetails = {
			pax: [paxDetails],
			questions: answers,
			contactDetails: {
				email: values?.contact?.email,
				phone: values?.contact?.phone,
				address1: values?.contact?.address?.address1,
				address2: values?.contact?.address?.address2,
				county: values?.contact?.address?.county,
				postcode: values?.contact?.address?.postcode,
				town: values?.contact?.address?.town,
			},
			marketing: {
				mail: 'false',
				tel: values?.marketing?.phone === 'yes' ? 'true' : 'false',
				email: values?.marketing?.email === 'yes' ? 'true' : 'false',
			},
		};

		setBookingState(category, {
			shouldBuildParams: true,
			driverDetails: values,
			bookingDetails,
			paymentDetails: undefined, // clear previous payment details
		});

		// submit booking data
		submitBooking(category);

		// get next step
		const nextStep = getNextStep(pageSteps, pathname);

		// update booking state with data
		navigate(`${nextStep.to}`);
	};

	const { handleBack, previousStep } = useBackNavigate(pageSteps);

	return (
		<BookingPageWrapper
			steps={pageSteps}
			category={category}
			title="Driver Details"
			previousPage={previousStep}
			onContinue={handleSubmit(onSubmit)}
			continueIsDisabled={
				bookingState?.previewLoading ||
				bookingState?.isLoading ||
				Object.keys(errors).length > 0
			}
			renderFooterContent={() => (
				<FooterContent
					control={control}
					category={category}
					handleBack={handleBack}
					backLabel={previousStep?.name}
					handleSubmit={handleSubmit(onSubmit)}
					isDisabled={
						bookingState?.previewLoading ||
						bookingState?.isLoading ||
						Object.keys(errors).length > 0
					}
				/>
			)}
		>
			<FormProvider {...methods}>
				<form onSubmit={handleSubmit(onSubmit)} className="px-0 md:px-0">
					<div className="flex flex-col gap-5 md:gap-6.5 lg:gap-8">
						<div className="flex flex-col lg:p-8 border border-core-blue sm:gap-y-2 -mx-2.5 p-5 md:p-6.5 md:mx-0">
							<Text className="font-bold leading-snug tracking-tighter text-dark-grey/75">
								Lead Driver
							</Text>
							<Text className="font-normal leading-snug tracking-tighter text-dark-grey/75">
								It is imperative that your car hire booking is booked under the
								name of the lead (main) driver.
							</Text>
							<Text
								variant="error"
								className="font-bold leading-snug tracking-tighter"
							>
								Please note: Canadian and US driving licenses are not accepted,
								except for any additional drivers* (Further information below.)
							</Text>
						</div>
						<div className="max-w-3xl">
							<Heading
								as="h2"
								className="mb-4 text-xl font-bold md:text-3xl font-body tracking-extra-tight leading-extra-tight text-dark-grey"
							>
								Car Hire Collection
							</Heading>
							<Text className="leading-snug tracking-tighter text-dark-grey/65">
								The named lead driver must present the following three pieces of
								information at time of car hire collection (failure to do so
								will result in you being unable to collect your car hire and
								full loss of monies paid):
							</Text>
							<ol className="mt-2.5 ml-5 list-decimal">
								<li className="text-base leading-snug tracking-tighter text-dark-grey/65">
									Car voucher - provided at time of booking confirmation.
								</li>
								<li className="text-base leading-snug tracking-tighter text-dark-grey/65">
									Valid credit card in the lead driver's name - must have enough
									credit limit to cover the security deposit.
								</li>
								<li className="text-base leading-snug tracking-tighter text-dark-grey/65">
									A valid driver's license - All driving licenses must be
									written in English or French. If not, you will need an
									International Driver's Permit. Please supply both the original
									and the International Driver's Permit together. Canadian and
									US licenses are not accepted except for any additional
									drivers*.
									<br />
									Your licence must include:
									<ol className="list-[lower-alpha] ml-5">
										<li>The driver(s) details.</li>
										<li>The class of vehicle you are authorized to drive.</li>
										<li>Any restrictions imposed.</li>
									</ol>
								</li>
							</ol>
						</div>
						<div className="max-w-3xl">
							<Heading
								as="h2"
								className="mb-4 text-xl font-bold md:text-3xl font-body tracking-extra-tight leading-extra-tight text-dark-grey"
							>
								Additional Drivers
							</Heading>
							<Text className="leading-snug tracking-tighter text-dark-grey/65">
								Any additional drivers will also be required to present a valid
								driver&apos;s license and credit card (if the additional driver
								is a spouse or common law partner living at the same address
								then a credit card is not required). Failure to do so will
								result in the supplier no longer being liable to provide this
								service.
							</Text>
						</div>
						<div className="max-w-3xl">
							<Heading
								as="h2"
								className="mb-4 text-xl font-bold md:text-3xl font-body tracking-extra-tight leading-extra-tight text-dark-grey"
							>
								One-Way Fees
							</Heading>
							<Text className="leading-snug tracking-tighter text-dark-grey/65 mb-2.5">
								If your drop off location is different to the pickup location
								you may be required to pay a one-way fee, this is payable
								locally in $CAD.
							</Text>
							<Text className="font-bold leading-snug tracking-tighter text-dark-grey/65">
								If your one-way fee is showing as $99999.00CAD this may mean
								this one-way route is not allowed by Avis. In the event it is
								allowed we will contact you to advise the correct one-way fee.
							</Text>
						</div>
						<div className="flex flex-col lg:p-8 border border-core-blue sm:gap-y-2 -mx-2.5 p-5 md:p-6.5 md:mx-0">
							<Text className="font-bold leading-snug tracking-tighter text-dark-grey/75">
								Please Note:
							</Text>
							<Text className="leading-snug tracking-tighter text-dark-grey/75">
								<strong className="font-bold text-dark-grey">
									Debit cards will not be accepted
								</strong>
								. A refundable security deposit will be held by the supplier,
								which is payable by credit card only, until the safe return of
								the vehicle. Please ensure you have available credit for this
								holding fee.
							</Text>
							<Text className="leading-snug tracking-tighter text-dark-grey/75">
								<strong className="font-bold text-dark-grey">
									Drivers under the age of 25 years old{' '}
								</strong>
								must call
								<a href="tel:02076169184" className="font-bold text-dark-grey">
									{' '}
									020 7616 9184
								</a>{' '}
								to speak with one of our expert team to assist you with your
								booking.
							</Text>
							<Text
								variant="error"
								className="font-bold leading-snug tracking-tighter"
							>
								Your car hire is on request until you receive the Avis car
								voucher from us.
							</Text>
							<Text className="leading-snug tracking-tighter text-dark-grey/65">
								When you make your booking with us you will receive an automatic
								confirmation, but we still need to verify the availability of
								your car hire. This may mean you receive the final confirmation
								and car voucher between 1-3 days after your booking. In the rare
								instance there is no availability for your selected vehicle we
								will either promptly issue a full refund or discuss alternative
								options with you.
							</Text>
						</div>
						<div className="flex flex-col max-w-3xl">
							<div className="flex flex-col gap-6 mb-5 md:mb-6.5 lg:mb-8">
								<Heading
									as="h2"
									className="text-xl font-bold md:text-3xl font-body tracking-extra-tight leading-extra-tight text-dark-grey"
								>
									Lead Driver Details
								</Heading>
								<DriverDetailsForm category={category} />
							</div>

							<div className="flex flex-col gap-6 mb-4">
								<Heading
									as="h2"
									className="text-xl font-bold md:text-3xl font-body tracking-extra-tight leading-extra-tight text-dark-grey"
								>
									Contact Details
								</Heading>
								<ContactForm />
							</div>

							<PreferredContactForm className="mb-4 md:mb-6" />

							{Array.isArray(questions) && questions.length > 0 && (
								<div className="flex flex-col w-full gap-3 md:w-1/2">
									<Heading
										as="h3"
										className="text-base font-bold leading-snug tracking-tighter md:text-lg font-body"
									>
										Special offers
									</Heading>
									<div className="flex flex-col gap-3">
										{questions.map((question) => (
											<div key={question?.id} className="flex flex-col gap-2">
												<Text
													as="label"
													className="leading-snug tracking-tighter text-light-black"
												>
													{question?.question}?
												</Text>
												<ToggleButtonGroup
													key={question?.id}
													instance="formField"
													value={
														answers.find(
															(answer) => answer?.id === question?.id
														)?.answer?.id
													}
													onChange={(value) => {
														// find the question and update the answer and answer id
														if (!value || value === '') return;

														const questionIndex = answers.findIndex(
															(answer) => answer?.id === question?.id
														);
														const newAnswers = [...answers];

														newAnswers[questionIndex].answer.id = value;
														newAnswers[questionIndex].answer.answer =
															question?.answers.find(
																(answer) => answer?.id === value
															)?.answer;

														setAnswers(newAnswers);
													}}
													label={`${question?.question}?`}
													options={question?.answers?.map((answer) => ({
														label: answer?.answer,
														value: answer?.id,
													}))}
												/>
											</div>
										))}
									</div>
								</div>
							)}

							<Text className="mt-4 text-sm font-normal leading-snug tracking-tighter md:mt-6 lg:mt-8 text-dark-grey/50">
								By providing the details below, we will be able to make your
								emails more personalised and relevant to you. Rest assured that
								your privacy is important to us and your details will not be
								shared with any 3rd party marketing companies. For more
								information, please read our{' '}
								<a
									className="text-sm font-normal leading-snug tracking-tighter underline underline-offset-4 text-dark-grey/50"
									href="/travel-centre/booking-with-confidence/privacy-policy"
									target="_blank"
								>
									Privacy Policy
								</a>
								.
							</Text>
						</div>
					</div>
				</form>
			</FormProvider>
		</BookingPageWrapper>
	);
}

const defaultState = {
	driver: {
		title: null,
		firstName: '',
		middleName: '',
		lastName: '',
		gender: null,
		dob: {
			day: null,
			month: null,
			year: null,
		},
	},
	contact: {
		phone: '',
		email: '',
		address: {
			address1: '',
			address2: '',
			town: '',
			county: '',
			postcode: '',
		},
	},
	marketing: {
		email: 'no',
		phone: 'no',
	},
	terms_conditions: false,
	read_information: false,
};

function FooterContent({
	control,
	category,
	backLabel,
	isDisabled,
	handleBack,
	handleSubmit,
}) {
	const { errors } = useFormState({ control });

	const errata = useErrata(category);
	const renderErrata = useMemo(() => {
		if (!errata) return null;

		if (category === 'motorhome-hire') {
			return (
				<LoopWrapper list={errata}>
					{(errata) => (
						<RteStyledContent
							variant="atcom-rte"
							dangerouslySetInnerHTML={{
								__html: errata.safeHtml,
							}}
						/>
					)}
				</LoopWrapper>
			);
		}
		return null;
	}, [errata, category]);

	return (
		<div className="w-full space-y-4 py-5 sm:py-7.5 md:py-10 lg:py-15 lg:max-w-3xl">
			<Heading
				as="h3"
				className="text-xl font-bold md:text-3xl font-body tracking-extra-tight leading-extra-tight text-dark-grey"
			>
				Important Information
			</Heading>
			{!!renderErrata && (
				<div className="flex flex-col gap-4">{renderErrata}</div>
			)}
			<Text className="leading-snug tracking-tighter text-dark-grey/65">
				All information collected is intended for Canadian Affair, the data
				controller, for the purposes of processing your bookings or
				subscriptions to our newsletters and/or programs. It may also be used
				for the conduct of marketing and statistical studies, to customise and
				continuously improve your customer experience, to provide you with the
				most suitable offers and to send you targeted advertising. If you have
				consented, it may be used to send you promotional offers. Some of this
				information may also be communicated to third parties, namely to our
				subcontractors, who may be located outside the European Union. In
				accordance with applicable regulation, you have various rights regarding
				the use and protection of your personal information. When the basis for
				processing is your consent, you may withdraw that consent at any time.
				To learn more about your rights, the use and protection of your personal
				information, please refer to our{' '}
				<a
					className="leading-snug tracking-tighter underline underline-offset-4"
					href="/travel-centre/booking-with-confidence/privacy-policy"
					target="_blank"
				>
					Privacy Policy
				</a>
				. If you wish to exercise one of these rights or contact our Data
				Protection Officer, please{' '}
				<a
					className="leading-snug tracking-tighter underline underline-offset-4"
					href="/about-us/contact-us"
				>
					Contact Us
				</a>
				.
			</Text>
			<div className="flex flex-col gap-4">
				{errors?.terms_conditions?.message ||
				errors?.read_information?.message ? (
					<Text as="p" className="leading-snug tracking-tighter text-core-red">
						* Please confirm that you have read the information above and the
						terms & conditions
					</Text>
				) : null}
				<div className="flex items-start justify-start gap-2">
					<FormField
						as="checkbox"
						control={control}
						id="terms_conditions"
						name="terms_conditions"
						fieldClassName="flex"
						wrapperClassName="w-fit"
					/>
					<Text
						as="label"
						htmlFor="terms_conditions"
						className="leading-[1.25] tracking-tighter text-dark-grey"
					>
						Please check this box to confirm that you have read and accepted the{' '}
						<a
							className="leading-[1.25] tracking-tighter text-dark-grey underline underline-offset-4"
							href="/travel-centre/terms-and-conditions"
							target="_blank"
							rel="noreferrer noopener"
						>
							Canadian Affair terms & conditions
						</a>{' '}
						and{' '}
						<a
							className="leading-[1.25] tracking-tighter text-dark-grey underline underline-offset-4"
							href="/travel-centre/terms-and-conditions/car-hire"
							target="_blank"
							rel="noreferrer noopener"
						>
							Avis terms and conditions
						</a>
					</Text>
				</div>
				<div className="flex items-start justify-start gap-2">
					<FormField
						as="checkbox"
						control={control}
						id="read_information"
						name="read_information"
						fieldClassName="flex"
						wrapperClassName="w-fit"
					/>
					<Text
						as="label"
						htmlFor="read_information"
						className="leading-[1.25] tracking-tighter text-dark-grey"
					>
						Please confirm that you have read the important information above.
					</Text>
				</div>
			</div>
			<div className="flex flex-col items-start justify-between gap-4 lg:flex-row lg:items-center">
				<Button
					type="submit"
					label="Agree & Continue"
					onClick={handleSubmit}
					variant="supporting-yellow"
					disabled={isDisabled}
					className="justify-between hidden md:flex w-fit"
				/>
				<Button
					type="button"
					disableAnimation
					variant="unstyled"
					onClick={handleBack}
					iconName="arrow-left"
					className="flex-row-reverse"
					label={`Back to ${backLabel}`}
					labelClassName="font-normal"
				/>
			</div>
		</div>
	);
}

FooterContent.propTypes = {
	errors: PropTypes.object,
	control: PropTypes.object,
	backLabel: PropTypes.string,
	handleBack: PropTypes.func,
	handleSubmit: PropTypes.func,
};

export default DriverDetails;
