import PropTypes from 'prop-types';
import { useQueryClient } from '@tanstack/react-query';
import { useEffect, useMemo, useState } from 'react';

import {
	Text,
	NotFound,
	PageHeader,
	PageWrapper,
	SearchCancelledButton,
} from '@/components/common';
import { cn } from '@/lib/utils';
import {
	useFetch,
	useUrlParams,
	useValueCallback,
	useMetaDescription,
} from '@/hooks';
import { CarFilterList, CarSearchList } from '@/components/carhire';
import { useBookingStore } from '@/store';

function CarHireSearch({
	filterKey,
	className,
	pageTitle,
	filterTitle,
	onDataCallback,
	buildSearchParams,
	withSiteHeader,
}) {
	useMetaDescription(['Search Results', 'Car Hire', 'Canadian Affair']);
	const queryClient = useQueryClient();
	const { setBookingState } = useBookingStore();
	const state = useBookingStore((state) => state['car-hire']);
	const [carTypeFilter, setCarTypeFilter] = useState([]);
	const { params, updateParams } = useUrlParams(filterKey);

	const newSearchParams = useMemo(() => {
		if (!buildSearchParams) return null;
		return buildSearchParams();
	}, [buildSearchParams]);

	const { data, error, isLoading, isCancelled } = useFetch({
		key: 'carhire-search',
		params: newSearchParams,
		config: {
			enabled: !!newSearchParams,
			retryOnWindowFocus: false,
		},

		onData: () => {
			// reset loading states for carhire store
			if (state?.isLoading || state?.previewLoading) {
				const newState = {
					isLoading: false,
					previewLoading: false,
				};
				setBookingState('car-hire', newState, 'RESET_LOADING_STATES');
			}
		},
	});

	useValueCallback(onDataCallback, data);

	const cancelRequest = (e) => {
		e.preventDefault();
		queryClient.cancelQueries({ queryKey: ['carhire-search'] });
	};

	// set the internal filter value based on the qs
	useEffect(() => {
		let selectedTypes = [];
		if (typeof data?.extra?.filters === 'object') {
			const qsTypes = params ? params['carhire-filters']?.carType : null;
			if (qsTypes && typeof qsTypes === 'object') {
				const carTypes = Object.keys(data.extra.filters);
				selectedTypes = qsTypes.filter((type) => carTypes.includes(type));
			}
		}

		setCarTypeFilter(selectedTypes);
	}, [params, data?.extra?.filters]);

	// convert data extra filters to array
	const filters = useMemo(() => {
		if (!data?.extra?.filters) return [];

		return Object.keys(data?.extra?.filters).map((key) => ({
			[key]: data?.extra?.filters[key],
		}));
	}, [data?.extra?.filters]);

	const filteredData = useMemo(() => {
		if (!data) return null;

		// show all if no filter is active
		if (!carTypeFilter?.length) return data;

		// filter results to locations with the chosen car type
		let items = [];
		if (data.data?.length > 0) {
			items = data.data
				.map((item) => {
					let cars = [];
					if (item.cars?.length > 0) {
						cars = item.cars
							.map((car) =>
								carTypeFilter.includes(car?.typeCode) ? car : null
							)
							.filter((car) => car);
					}
					return { ...item, cars: cars };
				})
				.filter((item) => item?.cars?.length > 0);
		}

		return {
			data: items,
			extra: data.extra,
		};
	}, [data, carTypeFilter]);

	const handleFilterChange = (name, value) => {
		const existingParams = params['carhire-filters'] || {};
		updateParams({
			...existingParams,
			[name]: value,
		});
	};

	if (!data && !isLoading) return <NotFound className="min-h-62.5" />;
	return (
		<PageWrapper
			error={error}
			loading={isLoading}
			onCancelRequest={cancelRequest}
			className={cn(
				'bg-lightish-grey min-h-screen md:min-h-[calc(100vh-4.5rem)] h-auto relative',
				!isLoading && 'pb-10',
				withSiteHeader && !isLoading ? 'pt-18' : '',
				className
			)}
			loaderClassName="min-h-[calc(100dvh-4rem)] md:min-h-[calc(100vh-4.5rem)]"
		>
			<div className="container mx-auto max-w-screen-3xl">
				{pageTitle ? (
					<PageHeader title={pageTitle} className="p-5 mx-auto lg:pb-6" />
				) : null}

				{isCancelled ? (
					<SearchCancelledButton paramsKey="carhire-search" />
				) : (
					<div className="mx-auto md:px-6 2xl:px-0 h-fit">
						{filterTitle ? (
							<Text className="hidden w-full my-4 text-base font-bold leading-tight text-center text-neutral-800 md:block">
								{filterTitle}
							</Text>
						) : null}
						<div className="mb-8 lg:mb-16">
							<CarFilterList
								filters={filters}
								values={carTypeFilter}
								onChange={(selected) => handleFilterChange('carType', selected)}
							/>
						</div>
						{filteredData?.data?.length > 0 ? (
							<CarSearchList
								list={filteredData}
								carTypeFilter={carTypeFilter}
								fetching={isLoading}
							/>
						) : (
							<NotFound className="min-h-62.5" />
						)}
					</div>
				)}
			</div>
		</PageWrapper>
	);
}

CarHireSearch.propTypes = {
	className: PropTypes.string,
	pageTitle: PropTypes.string,
	filterTitle: PropTypes.string,
	onDataCallback: PropTypes.func,
	filterKey: PropTypes.string.isRequired,
	buildSearchParams: PropTypes.func.isRequired,
};

CarHireSearch.defaultProps = {
	className: null,
	pageTitle: null,
	filterTitle: null,
	onDataCallback: null,
};

export default CarHireSearch;
