import { makeQuote } from './makeQuote';
import { makeBooking } from './makeBooking';
import { buildParams, addErrataParams } from './buildParams';
import { mergeArrayOfObjects } from '@/lib/utils';

/**
 * @name carhireBooking
 * @description Car hire booking endpoint call
 * @param {import('zustand').StoreApi<T>['getState']} get
 * @param {boolean} createQuote
 * @returns
 */

export const carhireBooking = async (get, createQuote = false) => {
    // construct the booking endpoint params from state
    const state = get()['car-hire'];
    const { setBookingState } = get();

    if (!state) return;
    const params = buildParams(state, 'car-hire');

    // quote booking
    if (createQuote) {
        params.quoteReceiver = state?.quoteReceiver || {};
        makeQuote(setBookingState, params, 'car-hire');
        return;
    }

    // standard booking
    makeBooking(setBookingState, params, 'car-hire');
};

/**
 * @name motorhomeHireBooking
 * @description Motorhome hire booking endpoint call
 * @param {import('zustand').StoreApi<T>['getState']} get
 * @param {boolean} createQuote
 * @returns
 */

export const motorhomeHireBooking = async (get, createQuote = false) => {
    // construct the booking endpoint params from state
    const state = get()['motorhome-hire'];
    const { setBookingState } = get();

    if (!state) return;
    if (!state.selected) return;

    const newState = {
        ...state,
        shouldBuildParams: true,
    }

    // merge errata into the selected object - for motorhomes we merge in the booking function as the function is called in the passenger details page.
    const params = addErrataParams(
        buildParams(newState, 'motorhome-hire'),
        state?.preview
    );

    // quote booking
    if (createQuote) {
        params.quoteReceiver = state?.quoteReceiver || {};
        makeQuote(setBookingState, params, 'motorhome-hire');
        return;
    }

    // standard booking
    makeBooking(setBookingState, params, 'motorhome-hire');
};

/**
 * @name flightsBooking
 * @description Flights booking endpoint call
 * @param {import('zustand').StoreApi<T>['getState']} get
 * @param {boolean} createQuote
 * @returns
 */

export const flightsBooking = async (get, createQuote = false) => {
    // construct the booking endpoint params from state
    const state = get()['flights'];
    const { setBookingState } = get();

    if (!state) return;

    if (!createQuote && !state?.bookingDetails) return;

    if (
        !state.selected?.items ||
        !state?.preview?.additionalParams
    )
        return;

    // construct the booking endpoint params from state
    // get everything in the preview
    const items = state?.selected?.items;
    const additionalParams = state?.preview?.additionalParams;

    // remove errata from the additional params
    const newAdditionalParams = additionalParams?.map((item) => {
        Object.keys(item).forEach((key) => {
            if (item[key]?.errata) {
                delete item[key]?.errata;
            }
        });

        return item;
    });

    // merge the additional params into the items before building the params
    const mergedState = {
        ...state,
        selected: {
            ...state.selected,
            items: mergeArrayOfObjects({
                toMerge: newAdditionalParams,
                toBeMergedInto: items,
            }),
        },
    };

    const params = buildParams(mergedState, 'flights');

    // quote booking
    if (createQuote) {
        params.quoteReceiver = state?.quoteReceiver || {};
        makeQuote(setBookingState, params, 'flights');
        return;
    }

    // standard booking
    makeBooking(setBookingState, params, 'flights');
};

/**
 * @name hotelsBooking
 * @description Hotels booking endpoint call
 * @param {import('zustand').StoreApi<T>['getState']} get
 * @param {boolean} createQuote
 * @returns
 */

export const hotelsBooking = async (get, createQuote = false) => {
    // construct the booking endpoint params from state
    const state = get()['hotels'];
    const { setBookingState } = get();
    if (!state) return;

    if (!state.selected) return;
    if (!createQuote && !state?.bookingDetails) return;

    const newState = {
        ...state,
        shouldBuildParams: true,
    }

    // // remove pageUrl from the selected object
    // if (newState?.selected?.pageUrl) {
    //     delete newState?.selected?.pageUrl;
    // }

    // merge errata into the selected object - for hotels we merge in the booking function as the function is called in the passenger details page.
    const additionalParams = state?.preview?.additionalParams ?? [];

    if (newState?.selected?.items?.length && additionalParams?.length > 0) {
        const hotelErrata = additionalParams
            .filter((params) => params?.type === 'accommodation')
            .reduce(
                (all, additional) => additional?.errata?.length > 0 ? [...all, ...additional.errata] : all,
                []
            );

        newState.selected.items = newState.selected.items.map((item) => {
            const newItem = { ...item };
            if (item?.type === 'accommodation') {
                newItem.errata = hotelErrata;
            }

            return newItem;
        });
    }

    const params = buildParams(newState, 'hotels');

    // quote booking
    if (createQuote) {
        params.quoteReceiver = state?.quoteReceiver || {};
        makeQuote(setBookingState, params, 'hotels');
        return;
    }

    // standard booking
    makeBooking(setBookingState, params, 'hotels');
    return null;
};

/**
 * @name holidayBooking
 * @description Holiday booking endpoint call
 * @param {import('zustand').StoreApi<T>['getState']} get
 * @param {boolean} createQuote
 * @returns
 */

export const holidayBooking = async (get, createQuote = false) => {
    // construct the booking endpoint params from state
    const state = get()['holidays'];
    const { setBookingState } = get();
    if (!state) return;

    if (!state.selected) return;
    if (!createQuote && !state?.bookingDetails) return;

    const newState = {
        ...state,
        shouldBuildParams: true,
    }

    // // remove pageUrl from the selected object
    // if (newState?.selected?.pageUrl) {
    //     delete newState?.selected?.pageUrl;
    // }

    // merge errata into the selected object - for hotels we merge in the booking function as the function is called in the passenger details page.
    const additionalParams = state?.preview?.additionalParams ?? [];

    if (additionalParams?.length > 0) {
        const hotelErrata = additionalParams
            .filter((params) => params?.type === 'accommodation')
            .reduce(
                (all, additional) => additional?.errata?.length > 0 ? [...all, ...additional.errata] : all,
                []
            );

        const selectedItems = newState?.selected.items?.[0]?.items;
        const accommodation = selectedItems.filter((item) => item?.type === 'accommodation')[0];
        accommodation.errata = hotelErrata;
    }

    const params = buildParams(newState, 'holidays');

    // quote booking
    if (createQuote) {
        params.quoteReceiver = state?.quoteReceiver || {};
        makeQuote(setBookingState, params, 'holidays');
        return;
    }

    // standard booking
    makeBooking(setBookingState, params, 'holidays');
};
