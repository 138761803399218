/**
 * @name getInsuranceType
 * @description check if car code is in the specified array and if a package is selected. If so return a default string as Fully Inclusive - Incl. 200km/day, else return Fully Inclusive - Incl. 200km/day else return Fully Inclusive
 * @param {string} carCode
 * @param {boolean} hasPackage
 * @returns {{defaultText: string, showDefaultText: boolean}} Returns defaultText and showDefaultText
 */
export const getInsuranceType = (carCode, hasPackage) => {
    const carTypes = ['AVI/FULLSUV', 'AVI/REGSUV2', 'AVI/MINI2'];
    const showDefaultText =
        carTypes.includes(carCode) && hasPackage;

    const defaultText = showDefaultText
        ? 'Fully Inclusive - Incl. 200km/day'
        : 'Fully Inclusive';


    return { defaultText, showDefaultText, carTypes }

}
