import { useEffect } from 'react';
import { useFormContext, useFormState } from 'react-hook-form';

import { FormField, FieldGroup } from '@/components/common';
import { GENDER_OPTIONS, TITLE_OPTIONS, calculateAge } from '@/lib/utils';

function DriverDetailsForm({ category }) {
	const { control, watch, setError, clearErrors, setValue } = useFormContext();
	const { errors } = useFormState({ control });
	const minimumAge = category === 'motorhome-hire' ? 21 : 18;

	useEffect(() => {
		const sub = watch((data, { name }) => {
			if (name?.includes('driver.dob')) {
				const dob = data?.driver?.dob;
				if (!dob?.month || !dob?.day || !dob?.year) return;
				if (dob?.year.length !== 4) return;
				const age = calculateAge(dob);

				if (age >= minimumAge) {
					clearErrors('driver.dob');
					return;
				}

				setError('driver.dob', {
					message: `Driver must be at least ${minimumAge} years old`,
				});
			}
		});

		return () => sub.unsubscribe();
	}, [watch]);

	return (
		<div className="flex flex-col space-y-4">
			<FormField
				as="select"
				name="driver.title"
				label="Title"
				control={control}
				wrapperClassName="w-1/2"
				errors={errors}
				showRequired
				options={TITLE_OPTIONS}
			/>
			<FormField
				as="input"
				name="driver.firstName"
				label="First name"
				errors={errors}
				control={control}
				showRequired
			/>
			<FormField
				as="input"
				name="driver.middleName"
				label="Middle name"
				control={control}
				errors={errors}
			/>
			<FormField
				as="input"
				label="Last name"
				name="driver.lastName"
				control={control}
				errors={errors}
				showRequired
			/>
			<FormField
				as="select"
				name="driver.gender"
				label="Gender"
				showRequired
				options={GENDER_OPTIONS}
				control={control}
				wrapperClassName="w-1/2"
				errors={errors}
			/>
			<FieldGroup
				name="driver.dob"
				className="grid grid-cols-3 gap-3"
				label="Date of birth"
				htmlFor="driver.dob.day"
				showRequired
				errors={errors}
			>
				<FormField
					as="input"
					type="number"
					name="driver.dob.day"
					placeholder="DD"
					label="Day"
					hideLabel
					control={control}
					onKeyPress={(e) => !/[0-9]/.test(e.key) && e.preventDefault()}
					onChange={(field, e) => {
						const val = e.target.value;
						const dayRegex = /^(0?[1-9]|[12]\d|3[01])$/;
						if (val.length > 3 && !dayRegex.test(val)) {
							e.preventDefault();
							return;
						}
						setValue('driver.dob.day', val);
					}}
					min={1}
					max={31}
				/>
				<FormField
					as="input"
					type="number"
					name="driver.dob.month"
					label="Month"
					hideLabel
					placeholder="MM"
					control={control}
					onKeyPress={(e) => !/[0-9]/.test(e.key) && e.preventDefault()}
					onChange={(field, e) => {
						const val = e.target.value;
						const monthRegex = /^(0?[1-9]|1[012])$/;
						if (val.length > 2 && !monthRegex.test(val)) {
							e.preventDefault();
							return;
						}
						setValue('driver.dob.month', val);
					}}
					min={1}
					max={12}
				/>
				<FormField
					as="input"
					type="number"
					name="driver.dob.year"
					label="Year"
					hideLabel
					placeholder="YYYY"
					control={control}
					max={new Date().getFullYear()}
				/>
			</FieldGroup>
		</div>
	);
}

export default DriverDetailsForm;
