/**
 * @module room
 * @desc Format a room option for a form field.
 * @category Utils
 */
export const formatRoomOption = (response, room, key) => {
    return {
        label:
            room.name +
            (room.boardBasis
                ? ` - ${room.boardBasisDisplayName || room.boardBasis}`
                : ''),
        value: room.roomCode,
        type: room.type,
        key: key,
        name: room.name,
        hotelName: response?.extra?.hotel?.name,
        price: room.price,
        pricePerPerson: room.pricePerPerson,
        pricePerPersonPerNight: room.pricePerPersonPerNight,
        image: room.image,
    };
};

/**
 * @module room
 * @desc Format available room options from room variant response data.
 * @category Utils
 */
export const getAvailableRooms = (response, selectedRoomKeys, level) => {
    if (!response) return [];
    if (!response?.data) return [];

    const data = response.data;

    const clone = (obj) => JSON.parse(JSON.stringify(obj));

    const rooms = [];
    if (level === 0) {
        Object.keys(data).forEach((kk) => {
            rooms.push(formatRoomOption(response, data[kk], kk));
        });
    } else {
        // recursively get the next level rooms
        let parent = clone(data);

        selectedRoomKeys.forEach((roomKey, _idx) => {
            // prevent searching for parents over maximum number of fields (3)
            if (_idx >= 2) return;

            let room = null;
            if (level === 0 || _idx === 0) {
                room = parent[roomKey];
            } else if (level === 1) {
                if (parent[roomKey]) {
                    room = parent[roomKey];
                }
            } else {
                if (
                    parent &&
                    parent.rooms &&
                    typeof parent.rooms[roomKey] !== 'undefined'
                ) {
                    room = parent.rooms[roomKey];
                }
            }

            if (room && room?.rooms) {
                parent = room;
            }
        });

        if (parent && parent.rooms && Object.keys(parent.rooms).length) {
            Object.keys(parent.rooms).forEach((kk) => {
                rooms.push(formatRoomOption(response, parent.rooms[kk], kk));
            });
        }
    }

    return rooms;
};
