import qs from 'qs';
import invoke from '@/lib/invoke';

/**
 * @typedef {import('../useBookingStore').category} category
 */

/**
 * @name makeQuote
 * @description Makes a quote for the given category
 * @param {(key: category, value: any, label: string) => void} setBookingState
 * @param {unknown} params
 * @param {category} category
 * @returns {void}
 * @example
 * makeQuote(setBookingState, params, 'flights');
 */

export const makeQuote = async (setBookingState, params, category) => {
    // if none of the params are available, return
    if (!params || !category || !setBookingState) return;
    setBookingState(category, {
        error: null, // clear previous error
        isLoading: true,
        isSubmittingQuote: true,
    });

    // reset loading state on refresh
    const handleBeforeUnload = () => {
        setBookingState(category, { isLoading: false, isSubmittingQuote: false });
    }
    window.addEventListener('beforeunload', handleBeforeUnload);

    // add category url segment to the booking
    params.category = category;

    // remove unused pageUrl
    if (params.pageUrl) delete params.pageUrl;

    const { res, error } = await invoke({
        method: 'POST',
        endpoint: 'booking/quote',
        data: params,
    });

    // request finished - remove listener
    window.removeEventListener('beforeunload', handleBeforeUnload);

    if (error) {
        // ignore network axios errors (only thrown in Safari)
        if (error?.originalError?.code === 'ERR_NETWORK') return;

        // ignore abort axios errors (only thrown in Firefox)
        if (error?.originalError?.code === 'ECONNABORTED') return;

        setBookingState(category, {
            error: error,
            isLoading: false,
            isSubmittingQuote: false,
        });
        return;
    }

    if (!res?.bookingId) {
        setBookingState(category, {
            error: {
                message: 'Please try again later.',
            },
            isLoading: false,
            isSubmittingQuote: false,
        });
        return;
    }

    setBookingState(category, {
        isLoading: false,
        isSubmittingQuote: false,
    });

    // navigate to quote confirmation page
    window.location.href = `/booking/${category}/quote-saved?${qs.stringify({
        id: res.bookingId,
    })}`;
};
