import React from 'react';

import Text from '@/components/common/atoms/text';
import MotorHomeSummary from '@/components/motorhomes/molecules/motorhome-summary';
import cn from '@/lib/utils/tailwind';
import { formatLocalDate, dateRangeFormatter } from '@/lib/utils/date';

/**
 * @name BookingSummary
 * @description A summary of the booking details
 * @returns {React.JSX.Element}
 * @example
 * <BookingSummary />
 * */

function BookingSummary({ item, className, contentClassName }) {
	return (
		<div>
			<Text className={cn('flex gap-2 items-center justify-between w-full mb-2', className)}>
				<Text as="span" className="font-bold text-2xl leading-tight capitalize">
					Motorhome
				</Text>
				{(item?.startDate || item?.endDate) && (
					<Text as="span" className="text-right text-lg tracking-tighter">
						{dateRangeFormatter({
							start: item?.startDate,
							end: item?.endDate,
						})}
					</Text>
				)}
			</Text>
			<MotorHomeSummary motorhome={item} className={contentClassName} />
		</div >
	);
}
export default BookingSummary;
