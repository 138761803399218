import React, { useMemo } from 'react';

import Icon from '@/components/common/atoms/icon';
import Text from '@/components/common/atoms/text';
import Button from '@/components/common/atoms/button';
import BookingPageWrapper from '@/components/common/organisms/booking-pagewrapper';
import useBookingStore from '@/store/useBookingStore';
import CarCard from '@components/carhire/molecules/card';
import useBackNavigate from '@/hooks/useBackNavigate';
import useMetaDescription from '@/hooks/useMetaDescription';
import useSearchSave from '@/hooks/useSearchSave';
import { getSteps } from '@/lib/steps';

/**
 * @typedef {Object} VehicleExtrasTemplateProps
 * @property {"car-hire" | "motorhome-hire"} category
 * @property {boolean} isLoading
 * @property {string} title
 * @property {Object} error
 * @property {()=>void} onSubmit
 * @property {boolean} continueIsDisabled
 * @property {Object} vehicle
 * @property {React.ReactNode} children
 **/

/**
 * @name VehicleExtrasTemplate
 * @description This component is used to render the vehicle extras page layout.
 * @param {VehicleExtrasTemplateProps} props
 * @returns {React.JSX.Element}
 **/

function VehicleExtrasTemplate({
	category,
	isLoading,
	title,
	error,
	onSubmit,
	continueIsDisabled,
	vehicle,
	children,
}) {
	useMetaDescription([
		'Extras',
		`${category == 'car-hire' ? 'Car' : 'Motorhome'} Hire`,
		'Canadian Affair',
	]);

	const state = useBookingStore((state) => state?.[category]);
	const { getSearch } = useSearchSave();
	const existingSearch = getSearch(
		category == 'car-hire' ? 'carhire' : 'motorhome-hire'
	);

	const pageSteps = useMemo(() => {
		const steps = getSteps(category);

		// update pageSteps 1st step to include the existing search if it exists
		if (existingSearch) {
			steps[0].search = `${existingSearch}`;
		}

		return steps;
	}, [category, existingSearch]);

	const { handleBack } = useBackNavigate(pageSteps, category);

	const disableContinue =
		continueIsDisabled ||
		state?.previewLoading ||
		state?.isLoading ||
		state?.error;

	return (
		<BookingPageWrapper
			steps={pageSteps}
			category={category}
			loading={isLoading}
			title={title}
			previousPage={pageSteps[0]}
			error={error}
			onContinue={onSubmit}
			disablePreviewFetch={isLoading}
			continueIsDisabled={disableContinue}
			renderBackButton={(step) => (
				<Button
					type="button"
					variant="unstyled"
					onClick={handleBack}
					className="flex items-center gap-2 mb-4 text-base"
					disabled={isLoading || state?.previewLoading || state?.isLoading}
				>
					<Icon name="arrow-left" className="w-5 h-5" />
					<span className="font-normal">Back to {step?.name}</span>
				</Button>
			)}
		>
			<form
				onSubmit={onSubmit}
				id="car-hire-extras-form"
				className="w-full min-h-screen bg-light-grey"
			>
				<div className="flex flex-col col-span-1 gap-8 lg:col-span-2">
					<div className="flex flex-col gap-2.5 xl:gap-5 p-5 -mx-5 bg-white lg:p-8 xl:p-10 md:mx-0">
						{category == 'car-hire' && (
							<Text
								variant="bold"
								className="text-lg leading-snug tracking-tighter lg:text-2xl text-dark-grey"
							>
								Your selected vehicle
							</Text>
						)}
						<CarCard
							selected
							type="extras"
							car={vehicle || {}}
							isMotorhome={category == 'motorhome-hire'}
							hideProviderLogo
							className="p-0 bg-transparent md:p-0 lg:p-0 xl:p-0"
						/>
					</div>

					{children}

					<div className="items-center justify-between hidden md:flex">
						<Button
							label="Continue"
							variant="supporting-yellow"
							disabled={disableContinue}
						/>
						<Button
							type="button"
							disableAnimation
							variant="unstyled"
							onClick={handleBack}
							iconName="arrow-left"
							className="flex-row-reverse"
							label="Back to Search"
							labelClassName="font-normal"
							disabled={isLoading || state?.previewLoading || state?.isLoading}
						/>
					</div>
				</div>
			</form>
		</BookingPageWrapper>
	);
}

export default VehicleExtrasTemplate;
