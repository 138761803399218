import React from 'react';
import PropTypes from 'prop-types';
import * as RadioGroupPrimitive from '@radix-ui/react-radio-group';

import { cn } from '@/lib/utils';
import { Icon } from '@components/common';

/**
 * @typedef {Object} RadioGroupItemProps
 * @property {string} className - Additional CSS class for the component.
 */

/**
 * @name RadioGroupItem
 * @description A radio group item component.
 * @param {RadioGroupItemProps} props - The component's props.
 * @param {React.ForwardedRef} ref - The component's ref.
 * @returns {React.JSX.Element} - The rendered RadioGroupItem.
 */

const RadioGroupItem = React.forwardRef((props, ref) => {
	const { className, iconClassName, ...rest } = props;
	return (
		<RadioGroupPrimitive.Item
			ref={ref}
			className={cn(
				'aspect-square h-8 w-8 min-w-8 rounded-full border border-gray-400 disabled:cursor-not-allowed disabled:opacity-50',
				className
			)}
			{...rest}
		>
			<RadioGroupPrimitive.Indicator className="flex items-center justify-center text-current">
				<Icon
					name="check"
					className={cn('w-4 h-4 text-current', iconClassName)}
				/>
			</RadioGroupPrimitive.Indicator>
		</RadioGroupPrimitive.Item>
	);
});

RadioGroupItem.propTypes = {
	className: PropTypes.string,
};

RadioGroupItem.displayName = 'RadioGroupItem';

export default RadioGroupItem;
