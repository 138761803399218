import PropTypes from 'prop-types';

import { cn } from '@/lib/utils';
import { Button, Text } from '@/components/common';
import pluralize from 'pluralize';

function MapListCard({
	noOfCars = 0,
	pickup,
	dropoff,
	logo,
	unlimitedMileage,
	onSelect,
	isSelected,
	showOnMap,
}) {
	const displayDepot = showOnMap === 'pickup' ? pickup : dropoff;
	const otherDepot = showOnMap === 'pickup' ? dropoff : pickup;

	const formatDepotName = (depot) => [depot?.name, depot?.locationName]
		.filter((name) => name)
		.join(', ');

	return (
		<div
			className={cn(
				'w-full h-auto bg-white flex justify-start items-start py-5 gap-5'
			)}
		>
			<div className="w-[50px] h-[50px] border border-lighter-grey justify-center items-center flex">
				{logo && <img className="w-full h-full object-contain" src={logo} />}
			</div>
			<div className="w-full self-stretch grid grid-cols-1 gap-6 md:grid-cols-2 lg:gap-12">
				<div className="col-span-1 flex gap-2 items-start">
					<div className="flex-col justify-start items-start inline-flex">
						<Text variant="bold" className="align-left grow shrink">
							{displayDepot.name}
						</Text>
						{displayDepot?.locationName && (
							<Text variant="muted">{displayDepot.locationName}</Text>
						)}
						{displayDepot?.code !== otherDepot?.code && (
							<Text variant="muted" className="mt-2">
								{showOnMap === 'pickup' ? 'Drop off' : 'Pick up'}
								{otherDepot?.name ? `: ${formatDepotName(otherDepot)}` : ' at different a depot.'}
							</Text>
						)}
					</div>
				</div>
				<div className="col-span-1 flex flex-col items-start justify-end gap-4 w-full md:items-end xl:flex-row xl:justify-between xl:items-center">
					<div className="gap-1 flex items-start md:flex-col">
						{unlimitedMileage && <Text variant="muted">Unlimited Mileage</Text>}
						<Text variant="muted">
							{noOfCars} car {pluralize('type', noOfCars)}
						</Text>
					</div>

					<div className="justify-start items-center gap-4 flex ">
						<Button
							hideIcon
							variant="outline"
							onClick={onSelect}
							labelClassName="truncate"
							className="border-core-blue"
							label={isSelected ? 'Cancel' : 'Select Car'}
						/>
					</div>
				</div>
			</div>
		</div>
	);
}

MapListCard.propTypes = {
	noOfCars: PropTypes.number.isRequired,
	showOnMap: PropTypes.oneOf(['pickup', 'dropoff']).isRequired,
	pickup: PropTypes.object.isRequired,
	dropoff: PropTypes.object.isRequired,
	logo: PropTypes.string,
	unlimitedMileage: PropTypes.bool.isRequired,
	onSelectCar: PropTypes.func,
};

MapListCard.defaultProps = {
	noOfCars: 3,
	showOnMap: 'pickup',
	pickup: {},
	dropoff: {},
	logo: null,
	unlimitedMileage: true,
	onSelectCar: () => {},
};

export default MapListCard;
