import { useEffect, useMemo, useState, useCallback } from 'react';
import PropTypes from 'prop-types';
import { Controller } from 'react-hook-form';
import { BookingCalendar, DatePlaceHolder, Text } from '@/components/common';
import FormField from '@/components/common/molecules/formfield';
import {
	Required,
	ONE_TO_NINE,
	ZERO_TO_NINE,
	CAR_HIRE_MAX_DATE,
} from '@/lib/utils';
import { useDisclosure } from '@/hooks';
import { addDays, format, isDate, set } from 'date-fns';

function BookingForm({ watch, errors, params, control, setValue }) {
	const { from, to } = watch('when');
	const selectedPickup = watch('pickup');
	const selectedDropoff = watch('dropoff');

	const { isOpen, onToggle } = useDisclosure(() => {
		if (from && to) return true;
		if (!from && !to) return true;
		return false;
	});

	const validateTotalPeople = useCallback((value, formValues) => {
		// only allow up to 9 people per booking
		const adults = formValues.adults?.value
			? parseInt(formValues.adults.value)
			: 0;
		const children = formValues.children?.value
			? parseInt(formValues.children.value)
			: 0;
		const infants = formValues.infants?.value
			? parseInt(formValues.infants.value)
			: 0;

		// show custom message to prevent 3 fields with the same message
		const isValid = adults + children + infants <= 9;
		setShowMaxPeopleError(!isValid);

		return isValid;
	});

	const calendarCode = useMemo(() => {
		if (!(selectedPickup?.value && selectedDropoff?.value && params?.code)) {
			return null;
		}

		return [selectedPickup.value, selectedDropoff.value, params.code].join('_');
	}, [selectedPickup?.value, selectedDropoff?.value, params?.code]);

	return (
		<div className="flex flex-col gap-4">
			{errors?.code ? (
				<Text as="p" className="my-2 text-red-500">
					{errors?.code?.message}
				</Text>
			) : null}

			<FormField
				name="pickup"
				label="Pick Up"
				control={control}
				errors={errors}
				as="select"
				endpoint="motorhome-rental/locations"
				queryKey="motorhome-rental-locations"
				isAsync
				filterAsyncOptions={(items) => {
					// show all options by default
					if (!items?.length) return items;
					if (!params?.motorhomeCodeOptions?.length) return items;

					// restrict to locations we have product codes for
					const productCodeLocations = params.motorhomeCodeOptions.map(
						(option) => option?.location
					);
					return items.filter((item) =>
						productCodeLocations.includes(item?.value)
					);
				}}
			/>

			<FormField
				name="dropoff"
				label="Drop off"
				control={control}
				errors={errors}
				as="select"
				disabled={typeof selectedPickup?.value !== 'string'}
				endpoint="motorhome-rental/locations"
				queryKey="motorhome-rental-locations"
				filterAsyncOptions={(items) => {
					if (typeof selectedPickup?.value !== 'string') return [];

					// allow any dropoff for motorhome rental
					return items;
				}}
				isAsync
			/>

			<div className="flex flex-col items-center justify-center gap-3">
				<Text as="label" className="font-bold text-gray-700">
					Select a departure date
				</Text>
				<Controller
					name="when"
					control={control}
					render={({ field }) => {
						return isOpen ? (
							<DatePlaceHolder
								hideTime
								showIcon
								type="inputdate"
								handleClick={onToggle}
								className="cursor-pointer"
								selected={{ from: new Date(from), to: new Date(to) }}
							/>
						) : (
							<BookingCalendar
								className="w-full"
								code={calendarCode}
								{...field}
								onChange={(range) => {
									field.onChange(range);

									if (range.to && range.from)
										setTimeout(() => {
											onToggle();
										}, 500);
								}}
								minDate={addDays(new Date(), 3)}
								maxDate={new Date(CAR_HIRE_MAX_DATE)}
							/>
						);
					}}
				/>
			</div>

			<div
				key={`booking-motorhome-rooms-0`}
				className="grid w-full grid-cols-3 gap-3"
			>
				<FormField
					name={`rooms.0.adults`}
					label="Adults"
					as="select"
					options={ONE_TO_NINE}
					control={control}
					errors={errors}
					validation={[Required, validateTotalPeople]}
					wrapperClassName="w-full lg:min-w-[124px]"
					placeholder="Adults"
					footer="Age 18+"
				/>

				<FormField
					name={`rooms.0.children`}
					label="Children"
					as="select"
					options={ZERO_TO_NINE}
					control={control}
					errors={errors}
					validation={[validateTotalPeople]}
					wrapperClassName="w-full lg:min-w-[124px]"
					placeholder="Children"
					footer="Age 2-17"
				/>

				<FormField
					name={`rooms.0.infants`}
					label="Infants"
					as="select"
					options={ZERO_TO_NINE}
					control={control}
					errors={errors}
					validation={[validateTotalPeople]}
					wrapperClassName="w-full lg:min-w-[124px]"
					placeholder="Infants"
					footer="Age < 2"
				/>
			</div>
		</div>
	);
}

BookingForm.propTypes = {
	params: PropTypes.shape({
		adults: PropTypes.oneOfType([PropTypes.string, PropTypes.number]),
		children: PropTypes.oneOfType([PropTypes.string, PropTypes.number]),
		infants: PropTypes.oneOfType([PropTypes.string, PropTypes.number]),
		startDate: PropTypes.string,
		endDate: PropTypes.string,
	}),
};

BookingForm.defaultProps = {
	params: {},
	hideClearBtn: false,
};

export default BookingForm;
