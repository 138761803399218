/**
 * Reusable helper functions for building and defining filters.
 */

import pluralize from "pluralize";
import { titleCase } from "title-case";

/**
 * Month names in calendar month order, used for sorting date facet filters.
 */
const MONTH_NAMES = [
    'january',
    'february',
    'march',
    'april',
    'may',
    'june',
    'july',
    'august',
    'september',
    'october',
    'november',
    'december',
]

/**
 * Sort facet options by alphabetical, or custom order.
 *
 * @param {string} a
 * @param {string} b
 * @returns number
 */
export const sortFacetOptions = (fieldName, a, b) => {
    const strA = a.toString();
    const strB = b.toString();

    switch (fieldName) {
        case 'dates':
            // sort dates by calendar order
            const monthAIdx = MONTH_NAMES.findIndex((month) => strA.toLowerCase() === month);
            const monthBIdx = MONTH_NAMES.findIndex((month) => strB.toLowerCase() === month);
            if (monthAIdx === monthBIdx) return 0;

            return monthAIdx > monthBIdx ? 1 : -1;
        default:
            // sort options alphabetically
            return strA.localeCompare(strB);
    }
}

/**
 * Format the display name of facet filter titles.
 */
export const formatFacetTitle = (field) => {
    switch (field) {
        case 'types':
            return 'Holiday Type';

        case 'themes':
            return 'Holiday Themes';

        case 'assetTypes':
            return 'File Type';

        case 'videoLengths':
            return 'Promotions';

        case 'locations':
            return 'Location';

        case 'experiences':
            return 'Experiences & Activities';

        case 'unique':
            return 'Unique Collections';

        case 'includesUs':
            return 'Canada & USA';

        case 'categories':
            return 'Category';

        case 'orientations':
            return 'Orientation';

        case 'products':
            return 'Product';

        case 'arrivalName':
            return 'Destination Airport';

        case 'departureName':
            return 'Departure Airport';

        case 'departureDayOfWeek':
            return 'Departure Day Of Week';

        case 'departureTimePeriod':
            return 'Departure Time';

        case 'stopsFilter':
            return 'Stops';

        case 'berths':
            return 'Sleeps';

        default:
            return titleCase(field);
    }
}

/**
 * @typedef {Object} Icon
 * @property {string} optionName
 * @property {string} iconUrl
 * */

/**
 * @typedef {Object} Field
 * @property {string} type
 * @property {string} name
 * @property {string} title
 * */

/**
 * @typedef {Object} BuildFilterParams
 * @property {{fieldName: Array<string>}} distribution
 * @property {{fieldName: Icon}} icons
 * @property {{fieldName: Field}} customFilters
 * @property {Array<string>} facetsToIgnore Names of facets to ignore in the distribution
 * @property {Array<string>} customSortOrder Ordered array of field names
 * */

/**
 * Build facet filters from facet distribution.
 *
 * @param {BuildFilterParams} params
 *
 * @return {Array<{type: string, name: string, title: string}>}
 */
export const buildFacetFilters = ({ distribution = [], icons = {}, customFilters = {}, facetsToIgnore = [], customSortOrder = [] }) => {
    if (typeof distribution !== 'object') return [];

    const customFieldNames = Object.keys(customFilters);
    const customFields = Object.values(customFilters);

    const filters = customFields?.length > 0 ? customFields : [];

    // build checkbox filters based off facet distribution
    const excludedFacets = [...customFieldNames, ...facetsToIgnore];
    Object.keys(distribution).forEach((field) => {
        const values = distribution[field];
        if (typeof values !== 'object') return;

        // don't add checkboxes for any custom filters, or ignored facets
        if (excludedFacets.includes(field)) return;

        const options = Object.keys(values)
            .sort((a, b) => sortFacetOptions(field, a, b))
            .map((key) => ({
                value: key,
                label: formatFacetLabel(field, key, values[key]),
                iconImage: icons && icons[field] && icons[field][key] ? icons[field][key] : null,
            }));
        if (options.length)
            filters.push({
                name: field,
                type: 'facet',
                title: formatFacetTitle(field),
                options: options,
            });
    });

    const hasCustomSortOrder = customSortOrder.length > 0;
    return filters
        .filter(filter => filter?.title)
        .sort((a, b) => {
            // sort by name order defined in customSortOrder array
            if (hasCustomSortOrder) {
                let posA = customSortOrder.indexOf(a?.name);
                if (posA === -1) posA = 999; // place non-present filters last

                let posB = customSortOrder.indexOf(b?.name);
                if (posB === -1) posB = 999; // place non-present filters last

                return posA > posB ? 1 : -1;
            }

            // default to sorting filters alphabetically
            return a?.title?.localeCompare(b?.title)
        });
}

export const formatFacetLabel = (field, value, quantity) => {
    let label = value;

    if (field === 'rating' && value !== 'unrated') label += ' Star';

    if (quantity) label += ` (${quantity})`;

    return titleCase(label);
}

export const formatActiveDurationLabel = (min, max) => {
    let label = 'Any';
    if (min || max) {
        switch (true) {
            case !max: // no minimum chosen
                label = `At least ${min} ${pluralize('night', min)}`;
                break;
            case !min: // no maximum chosen
                label = `No more than ${max} ${pluralize('night', max)}`;
                break;
            case min === max: // ranges are equal
                label = `${max} ${pluralize('Night', max)}`;
                break;
            case min !== max: // ranges chosen, but are different
                label = `${min} - ${max} Nights`;
                break;
        }
    }

    return label;
}
