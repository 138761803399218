import PropTypes from 'prop-types';
import { useEffect, useMemo } from 'react';
import {
	BookingSummaryPanel,
	Heading,
	Icon,
	Navbar,
	PageWrapper,
	RteStyledContent,
	Text,
} from '@/components/common';
import { cn } from '@/lib/utils';
import { useFetch, useUrlParams } from '@/hooks';
import { useBookingStore } from '@/store';
import { useLocation, useNavigate, useParams } from 'react-router-dom';
import HotelBookingTravelDetails from '@/components/hotels/organisms/booking-travel-details';
import FlightBookingTravelDetails from '@/components/flights/organisms/booking-travel-details';
import HolidayBookingTravelDetails from '@/components/holidays/organisms/booking-travel-details';

/**
 * @name BookingConfirmationPage Generic confirmation page component with booking retrieval functionality.
 * @returns {React.JSX.Element} BookingConfirmationPage
 */
function BookingConfirmationPage({
	title,
	summaryTitle,
	loadingText,
	noticeText,
	retrieveQuotes,
	hideTickOnDesktop,
	hideTravelInfo,
	footerClassName,
	DetailsComponent,
	FooterComponent,
}) {
	const navigate = useNavigate();
	const { resetState } = useBookingStore();
	const { params, parsedInitialParams } = useUrlParams();
	const routeParams = useParams();
	const { pathname } = useLocation();
	const category = routeParams?.category
		? routeParams.category
		: pathname && pathname.split('/')[2];

	useEffect(() => {
		// reset booking state when landing on the confirmation page
		resetState();
	}, []);

	const searchParams = useMemo(() => {
		return {
			type: retrieveQuotes ? 'QUOTE' : 'BOOKING',
		};
	}, [retrieveQuotes]);

	const { data, error, isLoading } = useFetch({
		key: 'retrieve-booking',
		formatEndpoint: (defaultEndpoint) =>
			`${defaultEndpoint}/${params?.default?.id}`,
		params: searchParams,
		config: {
			enabled: typeof params?.default?.id === 'string' && !!searchParams,
			retryOnWindowFocus: false,
			retry: false,
		},
	});
	const triggerTagManager = () => {
		if (data && data.marketing) {
			let action = console.log;
			if (window.dataLayer && window.dataLayer.push) {
				action = window.dataLayer.push;
			}

			action({ ecommerce: null }); // Clear the previous ecommerce object
			action(data.marketing);
		}
	};

	const bookingTypeName = useMemo(() => {
		// console.log('test', data);
		triggerTagManager();

		if (!data?.breakdown?.length || data?.breakdown?.length > 1)
			return 'Holiday';

		// if exactly one vehicle/hotel, show the type of booking
		let name = '';
		const itemType = data.breakdown[0]?.type;
		switch (itemType) {
			case 'carhire':
				name = 'Car Hire';
				break;
			case 'motorhomehire':
				name = 'Motorhome Hire';
				break;
			case 'accommodation':
				name = 'Hotel Stay';
				break;
		}

		return name;
	}, [data?.breakdown]);

	const errata = useMemo(() => {
		if (!data?.breakdown?.length) return [];

		return data.breakdown.reduce((errs, item) => {
			// product errata
			if (item?.errata?.length > 0) {
				errs = [...errs, ...item.errata];
			}

			// motorhome item extra errata
			if (item?.type === 'motorhomehire' && item?.itemExtras?.length) {
				const itemErrata = item.itemExtras
					.filter((extra) => extra?.errata?.length > 0)
					.reduce((itemErrs, extra) => [...itemErrs, ...extra.errata], []);
				if (itemErrata?.length) {
					errs = [...errs, ...itemErrata];
				}
			}

			return errs;
		}, []);
	}, [data?.breakdown]);

	const TravelDetailsComponent = useMemo(() => {
		switch (category) {
			case 'hotels':
				return HotelBookingTravelDetails;
			case 'flights':
				return FlightBookingTravelDetails;
			case 'holidays':
				return HolidayBookingTravelDetails;
		}

		return null;
	}, [category]);

	// redirect to homepage if landing on page without a booking id
	useEffect(() => {
		// wait until params have parsed
		if (!parsedInitialParams) return;

		if (typeof params?.default?.id !== 'string') {
			navigate('/');
		}
	}, [params, parsedInitialParams]);

	return (
		<>
			<Navbar />
			<PageWrapper
				error={error}
				className="min-h-screen md:min-h-[calc(100vh-4.5rem)] bg-light-grey relative"
				loading={isLoading}
				loadingText={loadingText}
				loaderClassName="h-[calc(100vh-4.5rem)]"
				hideSearchAgainOnError
			>
				<section className="flex flex-col w-full max-w-4xl pt-6 mx-auto md:pt-12 lg:px-0">
					<div className="flex flex-col gap-5 md:gap-8">
						<div className="flex flex-col gap-4 px-5 md:items-center md:justify-center lg:px-0">
							<Text
								as="span"
								className={cn(
									'w-7.5 md:w-10 h-7.5 md:h-10 p-2 rounded-full bg-check-green',
									hideTickOnDesktop && 'md:hidden'
								)}
							>
								<Icon name="check" className="w-full h-full text-white" />
							</Text>
							<Heading className="text-4xl md:text-6xl lg:text-10xl">
								{title}
							</Heading>
						</div>
						<div className="flex flex-col gap-2 px-5 md:gap-4 lg:px-0">
							{typeof DetailsComponent === 'function' && (
								<div>
									<DetailsComponent booking={data} />
								</div>
							)}
							{noticeText && (
								<Text className="md:text-lg">Note: {noticeText}</Text>
							)}
						</div>

						<div className="flex flex-col gap-4 md:gap-8">
							{summaryTitle && (
								<Heading
									as="h2"
									className="px-5 text-4xl font-bold md:text-5xl lg:px-0"
								>
									{summaryTitle}
								</Heading>
							)}

							{data?.breakdown?.length > 0 && (
								<div className="md:px-5 lg:px-0">
									<BookingSummaryPanel items={data?.breakdown || []} />
								</div>
							)}
						</div>

						{/* Travel details and errata */}
						{!hideTravelInfo && (
							typeof TravelDetailsComponent === 'function' || errata?.length > 0
						) ? (
							<div className="flex flex-col gap-4 px-5 lg:px-0">
								<Heading
									as="h2"
									className="text-3xl font-bold leading-6 font-body"
								>
									Important Information
								</Heading>

								{typeof TravelDetailsComponent === 'function' ? (
									<TravelDetailsComponent hasErrata={errata?.length > 0} />
								) : null}

								{errata?.length > 0 && (
									<div>
										<Heading
											as="h3"
											className="text-base font-bold uppercase w-fit font-body"
										>
											Other Information
										</Heading>
										<div className="flex flex-col gap-4 pt-2 text-dark-grey/65">
											{errata.map((err, idx) => (
												<RteStyledContent
													key={idx}
													variant="atcom-rte"
													dangerouslySetInnerHTML={{
														__html: err?.safeHtml,
													}}
												/>
											))}
										</div>
									</div>
								)}
							</div>
						) : null}
					</div>

					{typeof FooterComponent === 'function' && (
						<div className={cn('md:px-5 lg:px-0 md:mt-8', footerClassName)}>
							<FooterComponent
								booking={data}
								bookingTypeName={bookingTypeName}
							/>
						</div>
					)}
				</section>
			</PageWrapper>
		</>
	);
}

BookingConfirmationPage.propTypes = {
	title: PropTypes.string,
	summaryTitle: PropTypes.string,
	loadingText: PropTypes.string,
	noticeText: PropTypes.string,
	retrieveQuotes: PropTypes.bool,
	hideTickOnDesktop: PropTypes.bool,
	hideTravelInfo: PropTypes.bool,
	footerClassName: PropTypes.string,
	DetailsComponent: PropTypes.func,
};

export default BookingConfirmationPage;
