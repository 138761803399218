import React from 'react';

import Text from '@/components/common/atoms/text';
import RteStyledContent from '@/components/common/atoms/rte-styled-content';
import FormField from '@/components/common/molecules/formfield';
import { currencyFormatter } from '@/lib/utils/currency';
import cn from '@/lib/utils/tailwind';

function ExtraProductCard({
	item,
	radioOptComponent,
	control,
	options,
	isFeatured,
	isDisabled,
	messages,
}) {
	let field = null;
	switch (item?.type) {
		case 'radio':
			field = radioOptComponent;
			break;
		case 'checkbox':
			field = (
				<FormField
					as="checkbox"
					control={control}
					id={item?.code}
					name={item?.code}
					className="border-core-blue"
					labelClassName="leading-snug text-core-blue"
					wrapperClassName="w-44"
					showCheckboxAsButton={true}
					renderCheckboxButtonText={(checked) => (
						<Text
							className="text-base font-normal leading-snug tracking-tighter cursor-pointer"
							as="label"
							htmlFor={item?.code}
						>
							{checked ? 'Selected' : 'Add to basket'}
						</Text>
					)}
					isDisabled={isDisabled}
				/>
			);
			break;
		case 'dropdown':
			field = (
				<FormField
					as="select"
					name={item?.code}
					control={control}
					options={options || []}
					customControlClassName="w-44 h-10.75 lg:h-10.75"
					isDisabled={isDisabled}
				/>
			);
			break;
	}

	return (
		<div
			className={cn(
				'flex flex-col items-start justify-between gap-4 p-6 bg-white lg:flex-row lg:items-center',
				isFeatured ? 'bg-supporting-yellow' : 'bg-white',
				isDisabled && 'cursor-not-allowed opacity-60'
			)}
		>
			<div className="flex flex-col gap-1 grow">
				<Text className="text-lg font-bold leading-snug tracking-tighter text-black">
					{item?.displayName || item?.name}
				</Text>

				{!!item?.description && (
					<RteStyledContent
						variant="dark"
						dangerouslySetInnerHTML={{ __html: item?.description }}
					/>
				)}

				{messages?.length > 0 && (
					<div className="flex flex-col gap-1">
						{messages?.map((message, idx) => (
							<Text
								key={idx}
								className="leading-snug tracking-tighter text-core-red"
							>
								{message.content}
							</Text>
						))}
					</div>
				)}
			</div>
			<div className="flex flex-col items-start justify-center gap-6 lg:flex-row lg:items-center">
				{!isFeatured && (
					<div className="flex flex-col items-start gap-1 lg:items-end">
						<Text className="font-bold leading-snug tracking-tighter text-core-blue">
							Price
						</Text>
						<Text className="font-medium tracking-less-snug leading-less-snug text-core-blue">
							{currencyFormatter({
								amount: item?.price || 0,
							})}
						</Text>
					</div>
				)}

				{item?.code ? field : null}
			</div>
		</div>
	);
}

export default ExtraProductCard;
