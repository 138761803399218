import PropTypes from 'prop-types';
import { SearchResultsList } from '@/components/common';
import { MotorHomeCard } from '@/components/motorhomes';

function MotorHomeList({ pages }) {
	return (
		<SearchResultsList
			pages={pages}
			ResultCardComponent={MotorHomeCard}
			className="md:grid-cols-2 lg:grid-cols-1 lg:gap-5"
		/>
	);
}

MotorHomeList.propTypes = {
	pages: PropTypes.array.isRequired,
};

export default MotorHomeList;
