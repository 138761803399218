import PropTypes from 'prop-types';
import pluralize from 'pluralize';
import { useMemo } from 'react';

import { BookingDetailCard } from '@/components/common';
import { formatLocalDate, formatLocalTime, getInsuranceType } from '@/lib/utils';

function BookingDetailsPanel({ booking }) {
	const { leadPax, otherPax } = useMemo(() => {
		let lead = null;
		let others = [];
		if (booking?.pax?.length) {
			lead = booking.pax.find((pax) => pax?.isLead === true);
			others = booking.pax.filter((pax) => !pax?.isLead);
		}

		return {
			leadPax: lead,
			otherPax: others,
		}
	}, [booking?.pax]);

	const vehicleRental = useMemo(
		() =>
			booking?.breakdown?.length
				? booking.breakdown.find(
					(item) => item?.type === 'carhire' || item?.type === 'motorhomehire'
				)
				: null,
		[booking?.breakdown]
	);

	const formatDepotInfo = (name, date, time) => {
		const formattedDate = formatLocalDate(date, 'dd/MM/yyyy');
		const formattedTime = typeof time === 'string' ?
			formatLocalTime(`T${time}`) :
			null;

		const dateTimeInfo = [
			formattedDate,
			time && typeof time === 'string' ? `at ${formattedTime}` : null
		]
			.filter((part) => part)
			.join(' ');

		return [name, dateTimeInfo].filter((part) => part).join(' - ');
	};

	const { defaultText } = vehicleRental?.type === 'carhire' ?
		getInsuranceType(
			vehicleRental?.code,
			!vehicleRental?.packageExtra
		) :
		{};

	return (
		<>
			<BookingDetailCard title="Booking Reference" content={booking?.id} />
			<BookingDetailCard title="Status" content={booking?.status} />

			{/* Lead passenger */}
			<BookingDetailCard
				title={`Lead ${typeof vehicleRental === 'object' ? 'Driver' : 'Passenger'}`}
				content={leadPax?.fullName}
			/>

			{/* Bookings with a car or motorhome */}
			{typeof vehicleRental === 'object' && (
				<>
					<BookingDetailCard
						title="Vehicle Type"
						content={vehicleRental?.displayName || vehicleRental?.name}
					/>
					{vehicleRental?.type === 'carhire' && (
						<BookingDetailCard
							title="Insurance Type"
							content={
								vehicleRental?.packageExtra?.displayName ||
								vehicleRental?.packageExtra?.name ||
								defaultText
							}
						/>
					)}
					<BookingDetailCard
						title="Pick Up"
						content={formatDepotInfo(
							vehicleRental?.pickupName,
							vehicleRental?.startDate,
							vehicleRental?.pickupTime,
						)}
					/>
					<BookingDetailCard
						title="Drop Off"
						content={formatDepotInfo(
							vehicleRental?.dropoffName,
							vehicleRental?.endDate,
							vehicleRental?.dropoffTime,
						)}
					/>
					<BookingDetailCard
						title="Duration"
						content={
							vehicleRental?.duration
								? `${vehicleRental.duration} ${pluralize(
									'day',
									vehicleRental.duration
								)}`
								: ''
						}
					/>
				</>
			)}

			{/* Other passengers */}
			{otherPax?.length > 0 && (
				<BookingDetailCard
					title={`Other ${pluralize('Passenger', otherPax.length)}`}
					content={otherPax.map((pax) => pax.fullName)}
				/>
			)}
		</>
	);
}

BookingDetailsPanel.propTypes = {
	booking: PropTypes.object,
}

export default BookingDetailsPanel;
