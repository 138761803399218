import { Heading, Icon, Text } from '@/components/common';
import { cn, currencyFormatter } from '@/lib/utils';

function FilterCard({ car, className }) {
	return (
		<div className={cn('flex flex-col items-center', className)}>
			{car.image && (
				<img
					src={car.image}
					alt={car.label}
					className="w-25 h-25 object-contain"
				/>
			)}
			<div className="flex flex-col items-start lg:items-center">
				<Heading as="h3" className="text-2xl text-center font-body">
					{car.type}
				</Heading>
				{(car.pax || car.largeBaggage) && (
					<div className="flex gap-3 items-center justify-center my-2">
						{car.pax && (
							<span className="flex items-center gap-0.5">
								<Icon name="person" className="w-4 h-4" />
								{car.pax}
							</span>
						)}
						{car.largeBaggage && (
							<span className="flex items-center gap-2">
								<Icon name="luggage" className="w-4 h-4" />
								{car.largeBaggage}
							</span>
						)}
					</div>
				)}
				{car.price && (
					<div
						className={cn(
							'flex flex-col items-start gap-1 lg:items-center',
							!(car.pax || car.largeBaggage) ? 'mt-2' : ''
						)}
					>
						<Text as="p" className="font-bold text-xs">
							from
						</Text>
						<Text as="span" className="font-bold text-2xl">
							{currencyFormatter({
								amount: car.price,
							})}
						</Text>
					</div>
				)}
			</div>
		</div>
	);
}
export default FilterCard;
