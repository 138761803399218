import axios from 'axios'
// axios.defaults.withCredentials = true;

/**
 * @typedef {Object} InvokeOptions
 * @property {"GET" | "POST" | "PATCH" | "PUT" | "DELETE"} method 
 * @property {string} endpoint - The endpoint to call
 * @property {string} [customUrl] - If you want to override the default url
 * @property {any} [data]
 * @property {any} [params]
 * @property {AbortSignal} [signal]
 * @property {any} [rest]
 * */

/**
 * @typedef {Object} ErrorResponse
 * @property {string} message - The error message
 * @property {Error} [originalError] - The original error object
*/

/**
 * @name invoke
 * @description A wrapper around axios to make API calls
 * @param {InvokeOptions} options
 * @returns {Promise<{ res: unknown | null, status: number, error: ErrorResponse }>}
 * @example
 * const { res, status, error } = await invoke({ method: 'GET', endpoint: 'users'})
 * */

async function invoke({ method, endpoint, customUrl, headers, data = null, params = null, signal = null, ...rest }) {
    let config = {
        headers: {
            'Content-Type': 'application/json',
            SameSite: 'Secure',
        },
    }
    // @ts-ignore
    const isProduction = import.meta.env.PROD

    if (!endpoint && !customUrl) {
        return {
            res: null,
            status: 400,
            error: {
                message: "Endpoint is required"
            }
        }
    }

    const baseUrl = isProduction ? "https://api.canadianaffair.adido.dev" : "https://localapi.canadianaffair.adido.dev";
    // const baseUrl = "http://api.canadianaffair.com.test";
    // const baseUrl = "https://localapi.canadianaffair.adido.dev";
    const requestURL = `${baseUrl}/api/v1/${endpoint}`

    try {
        const { data: res, status } = await axios({
            data,
            method,
            params,
            signal,
            withCredentials: true,
            headers: headers ? headers : config.headers,
            url: customUrl ? customUrl : requestURL,
        })


        return { res, status, error: null }
    } catch (error) {
        if (error.response) {
            return { res: null, status: error.response.status, error: error.response.data }
        } else if (error.request) {
            return {
                res: null,
                status: 503, // Service Unavailable
                error: {
                    message: "No response received from the request",
                    originalError: error
                }
            }
        } else {
            return {
                res: null, status: 500, error: {
                    message: error.message,
                    originalError: error
                }
            }
        }
    }
}


export default invoke
