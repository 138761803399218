import {
	format,
	add,
	isBefore,
	startOfMonth,
	startOfToday,
	isDate,
	isAfter,
	startOfDay,
} from 'date-fns';
import React, { useCallback, useEffect, useMemo, useState } from 'react';

import { useFetch } from '@/hooks';
import { currencyFormatter } from '@/lib/utils';
import { PriceCalendar, CalendarNav, Text } from '@components/common';

/**
 * @typedef {Object} Range
 * @property {InstanceType<typeof Date>} from
 * @property {InstanceType<typeof Date>} to
 */

/**
 * @typedef {Object} BookingCalendarProps
 * @property {Range} value
 * @property {(value: Range)=>void} onChange
 * @property {String} code
 * @property {Date} minDate
 * @property {Date} maxDate
 * @property {InstanceType<typeof Date>} openToDate
 */

/**
 * @name BookingCalendar
 * @description A calendar to book a hotels, holidays, itineraries, etc
 * @param {BookingCalendarProps} props
 * @param {React.Ref<HTMLDivElement>} ref
 * @returns {React.JSX.Element}
 * @example
 * <BookingCalendar value={value} onChange={onChange} code={code} />
 */

function BookingCalendar({
	value,
	onChange,
	code,
	openToDate,
	minDate,
	maxDate,
	priceEndpoint = 'hotel-prices',
	pricingParams = (a) => a
}, ref) {
	const today = startOfToday();
	const currentMonth = startOfMonth(today);
	const [selectedMonth, setSelectedMonth] = useState(() => {
		if (isDate(openToDate)) {
			return new Date(openToDate);
		}

		if (value?.from) {
			return new Date(value?.from);
		}

		return new Date();
	});

	const setSelectedMonthOnLoad = useCallback(() => {
		if (!value?.from) return;
		const newSelectedMonth = new Date(value?.from);
		setSelectedMonth(newSelectedMonth);
	}, [value]);

	useEffect(() => {
		setSelectedMonthOnLoad();
	}, [setSelectedMonthOnLoad]);

	const params = useMemo(() => {
		if (!code) {
			// console.error('Hotel code not found, please try again');
			return {};
		}
		const baseParams = {
			code: code,
			includeMonths: 2,
			startDate: format(add(selectedMonth, { days: -7 }), 'yyyy-MM-dd'),
			endDate: format(add(selectedMonth, { months: 1, days: 7 }), 'yyyy-MM-dd'),
		}

		return pricingParams(baseParams);

	}, [selectedMonth, code, pricingParams]);

	const { data } = useFetch({
		key: priceEndpoint,
		params,
		config: {
			enabled: Object.keys(params).length > 0,
		},
	});

	return (
		<div ref={ref} className="w-full flex flex-col gap-4 z-0">
			<CalendarNav
				activeMonth={selectedMonth}
				maxDate={maxDate}
				onChange={setSelectedMonth}
				renderPrice={(month) => {
					if (isBefore(month, currentMonth)) return;
					if (minDate && isBefore(month, startOfMonth(minDate))) return;
					if (maxDate && isAfter(month, startOfMonth(maxDate))) return;

					const key = format(month, 'yyyy-MM-dd');
					const price = data?.months[key];
					return (
						<Text as="span" className="text-xl font-bold">
							{!price
								? 'TBC'
								: currencyFormatter({
									amount: price,
									params: {
										hideDecimal: true,
									},
								})}
						</Text>
					);
				}}
			/>
			<PriceCalendar
				selected={value}
				month={selectedMonth}
				onChange={onChange}
				setMonth={setSelectedMonth}
				minDate={minDate}
				maxDate={maxDate}
				renderPrice={(day) => {
					if (isBefore(day.date, today)) return;
					if (minDate && isBefore(day.date, startOfDay(minDate))) return;
					if (maxDate && isAfter(day.date, startOfDay(maxDate))) return;

					const key = format(day.date, 'yyyy-MM-dd');
					const price = data?.data[key];
					return (
						<Text as="span" className="text-xs tracking-normal">
							{price
								? currencyFormatter({
									amount: price,
									params: {
										hideDecimal: true,
									},
								})
								: 'TBC'}
						</Text>
					);
				}}
			/>
		</div>
	);
}
export default React.forwardRef(BookingCalendar);
