import {
    isDate,
    differenceInDays,
    startOfDay,
    addDays,
    format
} from 'date-fns';
import qs from 'qs';

import { default as MotorhomeBookingForm } from '@/components/motorhomes/forms/bookingForm';
import { motorHomeBookingSchema } from '@/validationSchemas';

import { CAR_HIRE_MAX_DATE } from '@/lib/utils';
import { WHO_OPTIONS } from '@/lib/utils/select';
import invoke from '@/lib/invoke';
import { ENDPOINTS } from '@/lib/api';

export default class BookingType {

    static htmlAttribute = 'data-motorhome'

    static nextPage = '/booking/motorhome-hire/extras'

    static stateName = 'motorhome-hire'

    static bookingType = 'motorhomehire'

    static validationSchema = motorHomeBookingSchema

    static getFormComponent() {
        return MotorhomeBookingForm;
    }

    static getBookingCode(motorhomeCodeOptions, pickup) {
        // motorhomes have a different product code per pickup location
        if (!motorhomeCodeOptions?.length) return null;
        if (!pickup?.value) return null;

        return motorhomeCodeOptions
            .find((option) => option?.location === pickup?.value)
            ?.ref;
    }

    static async getPreviewParams(baseParams, rooms) {
        if (!rooms) return null;

        const { startDate, endDate, code, pickup, dropoff } = baseParams;
        if (!pickup || !dropoff) {
            return null;
        }

        const bookingInfo = await BookingType.getMotorhomeFromApi(
            startDate,
            endDate,
            code,
            pickup.value,
            dropoff.value,
            rooms
        );

        if (bookingInfo) {
            // motorhomes are always booked with one passenger (the lead driver)
            const people = WHO_OPTIONS.reduce((acc, key) => {
                acc[key.value] = key.value === 'adults' ? 1 : 0;
                return acc;
            }, {});

            return {
                category: 'motorhome-hire',
                items: bookingInfo,
                rooms: [people],
            }
        }

        return null;
    }

    static async getMotorhomeFromApi(
        startDate,
        endDate,
        code,
        pickupLocation,
        dropoffLocation,
        rooms
    ) {
        if (!(
            startDate &&
            endDate &&
            code &&
            pickupLocation &&
            dropoffLocation
        )) {
            return null;
        }

        const totalUsingMotorhome = rooms.reduce((total, room) => {
            if (!room?.adults?.value) return total;

            // default to 1 adult on first room
            total.adults += room?.adults?.value ? parseInt(room.adults.value) : 1;
            total.children += room?.children?.value ? parseInt(room.children.value) : 0;
            total.infants += room?.infants?.value ? parseInt(room.infants.value) : 0;

            return total;
        }, { adults: 0, children: 0, infants: 0 });

        const { endpoint } = ENDPOINTS.find((e) => e.key === 'motorhome-search');
        const body = {
            code,
            startDate,
            endDate,
            pickupLocation,
            dropoffLocation,
            includeBookingItems: 1,
            adults: totalUsingMotorhome?.adults || 1,
            children: totalUsingMotorhome?.children || 0,
            infants: totalUsingMotorhome?.infants || 0,
        };

        const { res, error } = await invoke({
            method: 'GET',
            params: body,
            endpoint: endpoint
        });

        if (error) {
            console.error('error', error);
            return null;
        }

        if (res.data.hits) {
            return res.data.hits[0]?.bookingItems || null;
        }

        return null;
    }

    static getDefaultValues(urlParams) {
        const pickup = urlParams?.pickup ? urlParams.pickup.split(':') : [];
        const dropoff = urlParams?.dropoff ? urlParams.dropoff.split(':') : [];
        const startDate = urlParams?.startDate
            ? new Date(urlParams.startDate)
            : null;
        const endDate = urlParams?.endDate ? new Date(urlParams.endDate) : null;

        // only pre-fill if at least 3 days from today & before max date
        const motorHomeFromDate =
            isDate(startDate) &&
                differenceInDays(startOfDay(startDate), startOfDay(new Date())) >=
                3 &&
                differenceInDays(
                    startOfDay(startDate),
                    startOfDay(new Date(CAR_HIRE_MAX_DATE))
                ) <= 0 ? startDate
                : addDays(new Date(), 3);

        // only pre-fill if after the from date & before max date
        const defaultEndDate =
            differenceInDays(
                addDays(motorHomeFromDate, 8),
                startOfDay(new Date(CAR_HIRE_MAX_DATE))
            ) <= 0
                ? addDays(motorHomeFromDate, 8)
                : new Date(CAR_HIRE_MAX_DATE);

        const motorHomeToDate =
            isDate(endDate) &&
                differenceInDays(
                    startOfDay(endDate),
                    startOfDay(motorHomeFromDate)
                ) >= 0 &&
                differenceInDays(
                    startOfDay(endDate),
                    startOfDay(new Date(CAR_HIRE_MAX_DATE))
                ) <= 0
                ? endDate
                : defaultEndDate;

        const roomdefaultParams = urlParams?.rooms?.length
            ? urlParams.rooms
            : [{ adults: 1, children: 0, infants: 0 }];

        const rooms = roomdefaultParams.map((room) => {
            const acc = {};

            Object.keys(room).map((type) => {
                const value = room[type];
                acc[type] = {
                    value,
                    label: value.toString(),
                };
            }, {});

            return acc;
        });

        return {
            pickup: pickup[0]
                ? {
                    value: pickup[0],
                    label: pickup[1] || '',
                }
                : null,
            dropoff: dropoff[0]
                ? {
                    value: dropoff[0],
                    label: dropoff[1] || '',
                }
                : null,
            when: {
                from: motorHomeFromDate,
                to: motorHomeToDate,
            },
            rooms: rooms,
        };
    }

    static createQueryStringFromBookingWidget(values) {
        const { pickup, dropoff, when } = values;

        const queryString = qs.stringify({
            pickup: `${pickup.value}:${pickup.label}`,
            dropoff: `${dropoff.value}:${dropoff.label}`,
            startDate: format(when?.from, 'yyyy-MM-dd'),
            endDate: format(when?.to, 'yyyy-MM-dd'),
            category: 'motorhome-hire',
        });

        return queryString;
    }
}
