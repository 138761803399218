import { format } from 'date-fns';
import { Fragment, useState, useMemo } from 'react';
import { cn } from '@/lib/utils';
import WheelSlider from '../wheel-slider';
import { useBreakpoint, useDisclosure } from '@/hooks';
import { Button, TimePicker } from '@components/common';

export function useTimeValues(selected, useCurrentTime = true, increment = 15) {
	const minutes = useMemo(() => {
		if (increment === 0)
			return Array.from({ length: 60 }, (_, i) =>
				i.toString().padStart(2, '0')
			);
		if (increment === 15)
			return Array.from({ length: 4 }, (_, i) =>
				(i * 15).toString().padStart(2, '0')
			);
	}, [increment]);

	const hours = useMemo(() => {
		return Array.from({ length: 24 }, (_, i) => i.toString().padStart(2, '0'));
	}, []);

	const value = useMemo(() => {
		if (!selected) {
			return useCurrentTime
				? {
						hours: format(new Date(), 'HH'),
						minutes: format(new Date(), 'mm'),
				  }
				: {};
		}

		const { hours, minutes } = selected;
		return { hours, minutes };
	}, [selected, increment]);

	return { hours, minutes, value };
}

function DateRangeTime({
	handleTimeChange,
	selectedTime,
	hideTime,
	useCurrentTime,
	vertical = false,
}) {
	const [currentType, setCurrentType] = useState(null);
	const { onClose, onOpen, isOpen } = useDisclosure(false);
	const smallDevice = useBreakpoint('md');

	const openHandler = (type) => {
		if (smallDevice) setCurrentType(type);
		onOpen();
	};

	const closeHandler = () => {
		if (smallDevice) setCurrentType(null);
		onClose();
	};

	if (hideTime) return null;

	return (
		<div
			data-vaul-no-drag
			className={cn(
				'flex items-center sm:items-start justify-center sm:justify-start gap-2.5 sm:gap-5 self-stretch',
				vertical && 'flex-col'
			)}
		>
			{smallDevice && isOpen ? (
				<MobilePicker
					onClose={onClose}
					selected={selectedTime?.[currentType]}
					onChange={(time) => handleTimeChange(time, currentType)}
				/>
			) : (
				<Fragment>
					<div
						className={cn(
							'flex flex-col items-start justify-start w-full gap-2 sm:w-auto shrink grow basis-0',
							vertical && 'sm:w-full'
						)}
					>
						<TimePicker
							isOpen={isOpen}
							onOpen={() => openHandler('from')}
							onClose={closeHandler}
							label="Select start time"
							onChange={
								smallDevice ? null : (time) => handleTimeChange(time, 'from')
							}
							selected={selectedTime?.['from']}
							useCurrentTime={useCurrentTime}
						/>
					</div>

					<div
						className={cn(
							'flex flex-col items-start justify-start w-full gap-2 sm:w-auto shrink grow basis-0',
							vertical && 'sm:w-full'
						)}
					>
						<TimePicker
							isOpen={isOpen}
							onOpen={() => openHandler('to')}
							onClose={closeHandler}
							label="Select end time"
							onChange={
								smallDevice ? null : (time) => handleTimeChange(time, 'to')
							}
							selected={selectedTime?.['to']}
							useCurrentTime={useCurrentTime}
						/>
					</div>
				</Fragment>
			)}
		</div>
	);
}

function MobilePicker({ onClose, onChange, selected }) {
	const [selectedTime, setSelectedTime] = useState(
		selected || { hours: '00', minutes: '00' }
	);

	const { value, hours, minutes } = useTimeValues(selected);

	const handleConfirm = () => {
		if (JSON.stringify(selected) === JSON.stringify(selectedTime)) {
			onClose();
			return;
		}
		onChange(selectedTime);
		setSelectedTime({ hours: '00', minutes: '00' });
		onClose();
	};

	const hourIdx = hours.findIndex((h) => h === value.hours) || 0;
	const minuteIdx = minutes.findIndex((m) => m === value.minutes) || 0;

	return (
		<div className="flex flex-col items-center w-full max-w-sm gap-2 py-2 mx-auto overflow-hidden border rounded-lg border-border-color">
			<div className="flex items-center justify-between w-full my-2 border-b border-border-color h-30">
				<WheelSlider
					width={23}
					label="Hour"
					slides={hours}
					initIdx={hourIdx}
					onChange={(val) => setSelectedTime({ ...selectedTime, hours: val })}
				/>
				<WheelSlider
					width={23}
					label="Minute"
					slides={minutes}
					initIdx={minuteIdx}
					onChange={(val) => setSelectedTime({ ...selectedTime, minutes: val })}
					perspective="left"
				/>
			</div>
			<Button
				variant="supporting-yellow"
				onClick={handleConfirm}
				className="self-center mx-4 mb-2 font-bold tracking-wide"
			>
				Confirm
			</Button>
		</div>
	);
}

export default DateRangeTime;
