import {
    isDate,
    isBefore,
    differenceInDays,
    startOfDay,
    isValid,
} from 'date-fns';

import { HotelBookingForm } from "@/components/hotels";
import { hotelBookingSchema } from '@/validationSchemas';

export default class BookingType {

    static htmlAttribute = 'data-hotel'

    static nextPage = '/booking/hotels/passenger-details'

    static stateName = 'hotels'

    static bookingType = 'accommodation'

    static validationSchema = hotelBookingSchema

    static getFormComponent() {
        return HotelBookingForm;
    }

    static async getPreviewParams(baseParams, rooms) {
        if (!(rooms && rooms[0]?.room)) return null;

        let hotelName = null;
        let hotelState = 'INTERNAL';

        const roomParams = rooms.reduce((totalRooms, room) => {
            if (!room?.room?.value) return totalRooms;
            if (!room?.adults?.value) return totalRooms;

            if (!hotelName) hotelName = room?.room?.hotelName;

            // default to 2 adults on first room
            const defaultAdults = totalRooms.length === 0 ? 2 : 0;

            hotelState = room?.room?.type === 'external' ? 'EXTERNAL' : 'INTERNAL';
            totalRooms.push({
                name: room?.room?.name,
                roomCode: room.room.value,
                adults: room?.adults?.value
                    ? parseInt(room.adults.value)
                    : defaultAdults,
                children: room?.children?.value ? parseInt(room.children.value) : 0,
                infants: room?.infants?.value ? parseInt(room.infants.value) : 0,
            });
            return totalRooms;
        }, []);

        return {
            items: [{
                ...baseParams,
                rooms: roomParams,
                name: hotelName,
                state: hotelState,
            }]
        };
    }

    static getDefaultValues(urlParams, hotelOnlyMaxDate) {
        const from = urlParams?.from ? urlParams.from.split(':') : [];
        const adults = urlParams?.adults ? urlParams.adults.split(':') : [];
        const children = urlParams?.children ? urlParams.children.split(':') : [];
        const infants = urlParams?.infants ? urlParams.infants.split(':') : [];
        const startDate = urlParams?.startDate
            ? new Date(urlParams.startDate)
            : null;
        const endDate = urlParams?.endDate ? new Date(urlParams.endDate) : null;

        const isValidEndDate =
            isDate(startDate) &&
            isDate(endDate) &&
            !isBefore(startOfDay(endDate), startOfDay(startDate));

        const hasHotelMaxDate = hotelOnlyMaxDate && isValid(new Date(hotelOnlyMaxDate));

        // only pre-fill if at least 3 days from today & before max date
        const isValidHotelFromDate =
            isDate(startDate) &&
            differenceInDays(startOfDay(startDate), startOfDay(new Date())) >=
            3 &&
            (!hasHotelMaxDate || differenceInDays(
                startOfDay(startDate),
                startOfDay(new Date(hotelOnlyMaxDate))
            ) <= 0);

        // only pre-fill if after the from date & before max date
        const isValidHotelToDate =
            isValidHotelFromDate &&
            isValidEndDate &&
            (!hasHotelMaxDate || differenceInDays(
                startOfDay(endDate),
                startOfDay(new Date(hotelOnlyMaxDate))
            ) <= 0);

        const roomdefaultParams = urlParams?.rooms?.length
            ? urlParams.rooms
            : [{ adults: 2, children: 0, infants: 0 }];

        const rooms = roomdefaultParams.map((room) => {
            const acc = { room: null };

            Object.keys(room).map((type) => {
                const value = room[type];
                acc[type] = {
                    value,
                    label: value.toString(),
                };
            }, {});

            return acc;
        });

        return {
            when: {
                from: isValidHotelFromDate ? startDate : undefined,
                to: isValidHotelToDate ? endDate : undefined,
            },
            rooms: rooms, //formatRooms(adults, children, infants),
        };
    }
}
