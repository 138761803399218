import React from 'react';
import PropTypes from 'prop-types';

import useDisclosure from '@/hooks/useDisclosure';
import cn from '@/lib/utils/tailwind';
import { formatLocalDate, dateRangeFormatter } from '@/lib/utils/date';
import Icon from '@/components/common/atoms/icon';
import Text from '@/components/common/atoms/text';
import Heading from '@/components/common/atoms/heading';
import Button from '@/components/common/atoms/button';
import Route from '@/components/common/molecules/route';

/**
 * @name VehicleSummaryCard
 * @description A summary of the vehicle booking details
 * @returns {React.JSX.Element}
 */

function VehicleSummaryCard({
    vehicle,
    className,
    iconName,
    additionalInfoContent,
    extrasTitle,
    children,
}) {
    const { onToggle, isOpen } = useDisclosure(false);

    const formattedPickupDate = formatLocalDate(vehicle?.startDate, 'dd MMMM yyyy');
    const formattedDropoffDate = formatLocalDate(vehicle?.endDate, 'dd MMMM yyyy');

    const vehicleName = vehicle?.displayName || vehicle?.name;

    return (
        <div
            className={cn(
                'bg-white border border-lighter-grey w-full p-5 grid grid-cols-1 gap-10',
                vehicle?.image && 'md:grid-cols-3',
                className
            )}
        >
            <div className="col-span-1 md:col-span-2">
                {/* Mobile image */}
                {vehicle?.image && (
                    <div className="w-full md:hidden flex items-center mb-2">
                        <img
                            className="self-stretch h-full w-full object-contain"
                            src={vehicle?.image}
                            alt={vehicleName}
                        />
                    </div>
                )}
                <Heading
                    as="h3"
                    className="text-core-blue font-bold font-body text-2xl"
                >
                    {vehicleName}
                </Heading>
                {(
                    vehicle?.features?.length > 0 ||
                    typeof additionalInfoContent == 'function'
                ) ? (
                    <>
                        <Button
                            variant="unstyled"
                            onClick={onToggle}
                            className="my-2 gap-1 hover:opacity-75"
                        >
                            <Text as="span" className="leading-tight text-sm">
                                Info
                            </Text>
                            <Icon
                                className="w-3 h-3"
                                name={isOpen ? 'chevron-up' : 'chevron-down'}
                            />
                        </Button>
                        {isOpen && (
                            <div className="flex flex-col gap-1 p-3">
                                {
                                    typeof additionalInfoContent === 'function' ?
                                        additionalInfoContent() :
                                        null
                                }

                                {vehicle?.features?.length > 0 ? (
                                    <ul>
                                        {vehicle?.features?.map((feature) => (
                                            <li key={feature} className="list-none">
                                                <Text variant="muted" className="flex gap-2 items-center">
                                                    <Icon name="maple-leaf" />
                                                    <Text as="span">{feature}</Text>
                                                </Text>
                                            </li>
                                        ))}
                                    </ul>
                                ) : null}
                            </div>
                        )}
                    </>
                ) : null}
                {(formattedPickupDate || formattedDropoffDate) ? (
                    <Route
                        className="my-3"
                        iconName={iconName}
                        renderFrom={() => (
                            <Text as="span" className="flex flex-col">
                                <Text as="span">Pick up</Text>
                                <Text as="span" className="font-bold">
                                    {formattedPickupDate
                                        ? formattedPickupDate
                                        : '-'}
                                </Text>
                            </Text>
                        )}
                        renderTo={() => (
                            <Text as="span" className="flex flex-col">
                                <Text as="span">Drop off</Text>
                                <Text as="span" className="font-bold">
                                    {formattedDropoffDate
                                        ? formattedDropoffDate
                                        : '-'}
                                </Text>
                            </Text>
                        )}
                    />
                ) : null}

                <div>
                    {children}

                    {vehicle?.itemExtras?.length > 0 && (
                        <>
                            {extrasTitle && (
                                <Text>{extrasTitle}</Text>
                            )}
                            <ul className="list-disc ml-4">
                                {vehicle.itemExtras.map((extra) => (
                                    <li key={extra?.code}>
                                        <Text as="span" className="font-bold">
                                            {extra.quantity > 1 && <span>{extra.quantity}x </span>}

                                            {extra?.displayName || extra?.name}
                                        </Text>

                                        {/* Show extra rental period if different from pickup */}
                                        {!(
                                            extra?.startDate === vehicle?.startDate &&
                                            extra?.endDate === vehicle?.endDate
                                        ) && (
                                                <Text as="span">
                                                    {' '}
                                                    {dateRangeFormatter({
                                                        start: extra?.startDate,
                                                        end: extra?.endDate,
                                                    })}
                                                </Text>
                                            )}
                                    </li>
                                ))}
                            </ul>
                        </>
                    )}
                </div>
            </div>
            {/* Desktop image */}
            {vehicle?.image && (
                <div className="hidden col-span-1 w-full md:block">
                    <img
                        className="self-stretch h-full w-full object-contain"
                        src={vehicle?.image}
                        alt={vehicleName}
                    />
                </div>
            )}
        </div>
    );
}

VehicleSummaryCard.propTypes = {
    vehicle: PropTypes.object,
    className: PropTypes.string,
    iconName: PropTypes.string,
    extrasTitle: PropTypes.string,
    additionalInfoContent: PropTypes.func,
    children: PropTypes.node,
};

VehicleSummaryCard.defaultProps = {
    vehicle: null,
    className: '',
};

export default VehicleSummaryCard;
