import PropTypes from 'prop-types';

import { cn } from '@/lib/utils';
import { Button, Dialog, Heading, Icon, Text } from '@components/common';

function SearchFormButton({
	className,
	label,
	hasYouth = false,
	hasErrors = false,
	onCancel,
	hideCancel,
	handleSubmit,
	submitButtonClassName,
	cancelTextOnly,
}) {
	return (
		<div
			className={cn(
				'w-full md:w-auto flex flex-col gap-2.5 items-center justify-center md:flex-row md:gap-5 lg:mt-6',
				className
			)}
		>
			{hasYouth && !hasErrors ? (
				<Dialog
					as="modal"
					hideCloseBtn
					contentClassName="p-0"
					renderTrigger={({ DialogTrigger, onOpen }) => (
						<DialogTrigger asChild>
							<Button
								hideIcon
								type="button"
								onClick={onOpen}
								variant="supporting-yellow"
								className={cn(
									'w-full md:w-[212px]',
									submitButtonClassName && submitButtonClassName
								)}
							>
								{label}
							</Button>
						</DialogTrigger>
					)}
				>
					{({ CloseButton, onClose }) => (
						<div className="relative min-h-[200px] p-10 py-16">
							<CloseButton
								variant="square"
								className="top-0 right-0 w-12 h-12 text-white bg-core-blue"
							/>
							<div className="flex flex-col gap-5">
								<Heading as="h1" className="text-center font-body lg:text-8xl">
									Youth passengers
								</Heading>

								<Text className="mt-2 text-center">
									Air Passenger Duty (APD) no longer applies to passengers
									between ages of 12 and under 16, departing from a UK airport,
									travelling in Economy Class from 1st March 2016. We are in the
									process of updating our reservation system to remove this fee,
									however for now, an amount equivalent to APD will still be
									charged for youth fares at the time of booking. Clients
									booking directly with Canadian Affair website will be
									contacted by email and reimbursed within 14 days of booking
									confirmation.
								</Text>
								<Button
									type="submit"
									disabled={hasErrors}
									onClick={() => {
										handleSubmit();
										onClose();
									}}
									variant="supporting-yellow"
									className="w-full"
								>
									Continue
								</Button>
							</div>
						</div>
					)}
				</Dialog>
			) : (
				<Button
					type="submit"
					variant="supporting-yellow"
					disabled={hasErrors}
					className={cn(
						'w-full md:w-[212px]',
						submitButtonClassName && submitButtonClassName
					)}
				>
					{label}
				</Button>
			)}

			{!hideCancel &&
				(cancelTextOnly ? (
					<Button
						type="button"
						variant="unstyled"
						className="inline text-base font-normal hover:opacity-75"
						onClick={onCancel}
					>
						<Text
							as="span"
							className="font-normal leading-snug tracking-tighter underline text-light-black/85 underline-offset-4"
						>
							Cancel
						</Text>
					</Button>
				) : (
					<button
						type="button"
						onClick={onCancel}
						className="flex md:flex-col justify-center item-center md:h-13.25 md:w-5 "
					>
						<Icon name="x" className="hidden md:block" />
						<span className="text-sm leading-snug underline opacity-75 md:hidden font-body tracking-less-tight text-light-black">
							Cancel
						</span>
					</button>
				))}
		</div>
	);
}

SearchFormButton.propTypes = {
	className: PropTypes.string,
	label: PropTypes.string.isRequired,
	onCancel: PropTypes.func.isRequired,
	hideCancel: PropTypes.bool,
	hasYouth: PropTypes.bool,
	handleSubmit: PropTypes.func,
};

export default SearchFormButton;
