import React from 'react';
import PropTypes from 'prop-types';

import { getInsuranceType } from '@/lib/utils/getInsuranceType';
import Icon from '@/components/common/atoms/icon';
import Text from '@/components/common/atoms/text';
import VehicleSummaryCard from '@/components/common/molecules/vehicle-summary-card';

/**
 * @typedef {Object} CarSummaryCardProps
 * @property {Object | null} car
 * @property {string} className
 */

/**
 * @name CarSummaryCard
 * @description A summary of the car details
 * @param {CarSummaryCardProps} props
 * @returns {React.JSX.Element}
 * @example
 * <CarSummaryCard car={car} />
 */

function CarSummaryCard({ car, className }) {
	const { defaultText } = getInsuranceType(car?.code, !car?.packageExtra);

	return (
		<VehicleSummaryCard
			vehicle={car}
			className={className}
			iconName="car-right"
			extrasTitle="Extras:"
			additionalInfoContent={
				car?.pax || car?.largeBaggage || car?.smallBaggage ?
					() => (
						<div className="flex flex-row gap-4 items-center justify-start md:justify-start">
							{car?.pax && (
								<Text className="flex gap-1 items-center">
									<Icon name="person" />
									{car.pax}
								</Text>
							)}
							{car?.largeBaggage && (
								<Text className="flex gap-1 items-center">
									<Icon name="luggage" />
									{car.largeBaggage}
								</Text>
							)}
							{car?.smallBaggage && (
								<Text className="flex gap-1 items-center">
									<Icon name="small-luggage" />
									{car.smallBaggage}
								</Text>
							)}
						</div>
					) :
					null
			}
		>
			<Text>
				Package:{' '}
				<span className="font-bold">
					{car?.packageExtra?.displayName ||
						car?.packageExtra?.name ||
						defaultText}
				</span>
			</Text>
		</VehicleSummaryCard>
	);
}

CarSummaryCard.propTypes = {
	car: PropTypes.object,
	className: PropTypes.string,
};

CarSummaryCard.defaultProps = {
	className: '',
	car: null,
};

export default CarSummaryCard;
